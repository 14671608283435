import {
  DeepSignal,
  IMultiDropdownSettings,
  MultiDropdownSettings,
  PropExtend,
  UTILS,
} from '@aksia/infrastructure';
import { CommonModule } from '@angular/common';
import {
  Component,
  computed,
  ElementRef,
  input,
  isSignal,
  viewChild,
  WritableSignal,
  Signal,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiPopupDirective } from '../../../directives/ui/ui-popup.directive';
import { IconComponent } from '../../presentation/icon/icon.component';
import { LabelComponent } from '../../presentation/label/label.component';
import { TextComponent } from '../text/text.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { ChipComponent } from '../../presentation/chip/chip.component';

@Component({
  selector: 'multi-dropdown',
  imports: [
    CommonModule,
    LabelComponent,
    IconComponent,
    ChipComponent,
    UiPopupDirective,
    TextComponent,
    FormsModule,
  ],
  templateUrl: './multi-dropdown.component.html',
})
export class MultiDropdownComponent extends DropdownComponent {
  //#region Inputs/Outputs

  override settings = input(new MultiDropdownSettings(), {
    transform: (settings: IMultiDropdownSettings) =>
      this.initSettings(settings),
  });

  //#endregion

  //#region View Children

  uiQueryRef = viewChild('uiQueryRef', { read: ElementRef });
  uiMoreOptionsPopupRef = viewChild('uiMoreOptionsToolRef', {
    read: ElementRef,
  });
  uiMoreOptionsPopupDir = viewChild('uiMoreOptionsToolRef', {
    read: UiPopupDirective,
  });

  //#endregion

  //#region Properties

  protected isTyping = signal<boolean>(false);

  public override selectedOption = computed(() =>
    this.plainOptions().filter((option: PropExtend<unknown>) =>
      this.valueIncludesOption(option?.[this.settings().optionValue]),
    ),
  );

  private plainValues = computed(() =>
    (this.value() as Array<unknown>)?.map((v) =>
      UTILS.SIGNALS.isDeepSignal(v)
        ? (
            v as WritableSignal<
              DeepSignal<unknown & { _plain: Signal<unknown> }>
            >
          )()?._plain()
        : isSignal(v)
          ? v()
          : v,
    ),
  );

  protected selectedValues = computed(() =>
    this.selectedOption().map(
      (option: PropExtend<unknown>) => option[this.settings().optionValue],
    ),
  );

  protected allValuesAreSelected = computed(
    () => this.selectedOption().length === this.plainOptions().length,
  );

  protected showMoreSelectedOptions = false;

  //#endregion

  //#region Functions

  protected override initSettings(settings: IMultiDropdownSettings) {
    let dropdownSettings = UTILS.OBJECT.assign(
      new MultiDropdownSettings(),
      settings,
    );

    super.initSettings(dropdownSettings);
    this.popupSettings.set(dropdownSettings.popup);
    this.popupSettings.update((popupSettings) => {
      popupSettings.uid = dropdownSettings.controlId;
      return popupSettings;
    });
    return dropdownSettings;
  }

  protected override select(option: PropExtend<unknown>) {
    if (this.settings().allowMultiple === false) {
      // For single selection, set the value to just the selected option
      this.updateValue([this.resolveValue(option)]);
    } else {
      if (this.valueIncludesOption(option?.[this.settings()?.optionValue])) {
        this.valueRemoveOption(option?.[this.settings()?.optionValue]);
      } else {
        this.valueAddOption(option);
      }
    }

    if (this.selectedOption().length <= this.settings().shrinkCount) {
      this.showMoreSelectedOptions = false;
    }

    if (option) {
      this.OptionIsSelected.emit(this.selectedOption());
    }
  }

  protected override toggleOptionsPopup(byTyping: boolean = false) {
    super.toggleOptionsPopup();
    if (byTyping) {
      this.isTyping.set(true);
      setTimeout(() => this.uiQueryRef()?.nativeElement.focus(), 100);
    }
  }

  protected selectAll() {
    if (this.settings().allowMultiple === false) {
      // In single-selection mode, selectAll selects the first option
      if (this.plainOptions().length > 0) {
        this.updateValue([this.resolveValue(this.plainOptions()[0])]);
      }
    } else {
      const areSelected =
        this.selectedOption().length === this.plainOptions().length;
      if (!areSelected) {
        this.plainOptions().forEach((option: PropExtend<unknown>) => {
          if (
            !this.valueIncludesOption(option?.[this.settings().optionValue])
          ) {
            this.valueAddOption(option);
          }
        });

        this.OptionIsSelected.emit(this.plainOptions() as Array<unknown>);
      } else {
        this.clearValue();
        this.OptionIsSelected.emit(undefined);
      }
    }
  }

  protected toggleMoreOptionsPopup() {
    if (!this.settings().disabled) {
      if (this.uiPopupDir()?.isVisible) {
        this.uiPopupDir()?.hidePopup();
      }
      this.uiMoreOptionsPopupDir()?.togglePopup(this.hostElRef.nativeElement);
    }
  }

  private valueIncludesOption(optionValue: unknown) {
    return this.plainValues()?.some((plainValue: PropExtend<unknown>) =>
      typeof plainValue === 'object'
        ? plainValue[this.settings().optionValue] === optionValue
        : plainValue === optionValue,
    );
  }

  private valueAddOption(option: PropExtend<unknown>) {
    if (this.settings().allowMultiple === false) {
      this.updateValue([this.resolveValue(option)]);
    } else {
      this.updateValue([
        ...((this.value() as Array<unknown>) ?? []),
        this.resolveValue(option),
      ]);
    }
  }

  private valueRemoveOption(optionValue: unknown) {
    let index = this.plainValues().findIndex(
      (plainValue: PropExtend<unknown>) =>
        this.settings().storeOnlyValue
          ? plainValue === optionValue
          : plainValue[this.settings().optionValue] === optionValue,
    );

    this.updateValue(
      (this.value() as Array<unknown>)?.filter((v, i) => i !== index),
    );
  }

  //#endregion
}
