import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../static-content.directive';
import {
  ButtonComponent,
  DateComponent,
  DropdownComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Carried Interest"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    NumberComponent,
    TextComponent,
    ButtonComponent,
  ],
  templateUrl: './carried-interest.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarriedInterestContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Lp Commitment"]',
  imports: [DropdownComponent, NumberComponent],
  templateUrl: './lp-commitment.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LpCommitmentContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Management Fee"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    DateComponent,
    NumberComponent,
    TextComponent,
    ButtonComponent,
  ],
  templateUrl: './management-fee.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManagementFeeContent extends StaticContentDirective {}

export const ShareclassClosedEndContents = [
  CarriedInterestContent,
  LpCommitmentContent,
  ManagementFeeContent,
];
