import {
  SimpleAnswerEnum,
  CurrencyEnum,
  MgmtFeeFrequencyEnum,
  DataSourceEnum,
  PassThroughManagementFeeTypeEnum,
  SimpleAnswerPassEnum,
} from '../../enums/entity.enum';
import {
  CalendarUnitEnum,
  ActiveTimePeriodEnum,
  MgmtFeeRateTypeEnum,
  IncFeeRateTypeEnum,
  HighWaterMarkEnum,
} from '../../enums/openEnd.enum';
import {
  SidePocket,
  Lockup,
  Gate,
  MgmtFeeRate,
  OpenMgmtFeeDiscount,
  IncFeeRate,
  IncentiveFeeDiscount,
} from '../entities/entities.model';
import { IShareClass } from '../../interfaces/shareclass/shareclass.interface';
import { StoreModel } from '@aksia/infrastructure';

export class OpenShareClass extends StoreModel implements IShareClass {
  classId?: number = undefined;
  fundId?: number = undefined;
  className?: string = undefined;
  isDefault?: boolean = undefined;
  isDeleted?: boolean = undefined;
  noLongerOffered?: boolean = undefined;

  //Delete those 2?
  source?: DataSourceEnum = undefined;
  asOfDate?: Date = undefined;

  //#region <<< Section - Side Pockets >>>

  hasSidePocket?: SimpleAnswerEnum = undefined;
  sidePockets?: Array<SidePocket> = undefined;
  sidePocketOpt?: SimpleAnswerEnum = undefined;

  //#endregion

  //#region <<< Section - Subscriptions >>>

  minimumInitialInvestment?: number = undefined;
  minimumAdditionalInvestment?: number = undefined;
  minimumInvestmentCurrency?: CurrencyEnum = undefined;
  hasSubscriptionFee?: SimpleAnswerEnum = undefined;
  subscriptionFee?: number = undefined;

  //#endregion

  //#region <<< Section - Redemptions >>>

  hasLockup?: SimpleAnswerEnum = undefined;
  lockupEndAllow?: boolean = undefined;
  lockups?: Array<Lockup> = undefined;
  redemptionTermsFrequencyAmount?: number = undefined;
  redemptionTermsFrequency?: CalendarUnitEnum = undefined;
  redemptionCustomDates?: string = undefined;
  redemptionTermsFrequencyDay?: string = undefined;
  redemptionTermsNotice?: number = undefined;
  redemptionTermsNoticePeriod?: ActiveTimePeriodEnum = undefined;
  redemptionFee?: number = undefined;
  hasGate?: SimpleAnswerEnum = undefined;
  gates?: Array<Gate> = undefined;
  holdback?: number = undefined;

  //#endregion

  //#region <<< Section - Fees >>>

  hasManagementFee?: SimpleAnswerPassEnum = undefined;
  managementFeePassThrough?: PassThroughManagementFeeTypeEnum = undefined;
  mgmtFeeRateType?: MgmtFeeRateTypeEnum = undefined;
  mgmtFeeRates?: Array<MgmtFeeRate> = undefined;
  payable?: MgmtFeeFrequencyEnum = undefined;
  payableDescription?: string = undefined;
  hasManagementFeeDiscounts?: SimpleAnswerEnum = undefined;
  managementFeeDiscounts?: Array<OpenMgmtFeeDiscount> = undefined;
  hasIncentiveFee?: SimpleAnswerEnum = undefined;
  incFeeRateType?: IncFeeRateTypeEnum = undefined;
  incFeeRates?: Array<IncFeeRate> = undefined;
  crystalization?: number = undefined;
  crystalizationPeriod?: CalendarUnitEnum = undefined;
  highwaterMark?: HighWaterMarkEnum = undefined;
  hasIncentiveFeeDiscounts?: SimpleAnswerEnum = undefined;
  incentiveFeeDiscounts?: Array<IncentiveFeeDiscount> = undefined;

  //#endregion

  constructor() {
    super();
    this.className = 'Default';
    this.isDefault = true;
  }
}
