import { effect, inject, Injectable, signal, untracked } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { InvestmentVehicleStore } from '../stores/investment-vehicle.store';
import { LayoutService, LocalStorageService } from '@aksia/services';
import { ROLES, STORAGE_KEYS } from '@aksia/infrastructure';
import { AuthenticationService } from '../services/authentication.service';
import { ASSET_TEAM_VIEWS } from '@aksia/models';
import { AssetTypeEnum } from '@aksia/enums';

@Injectable({ providedIn: 'root' })
export class InvestmentVehicleResolver implements Resolve<number> {
  private readonly local = inject(LocalStorageService);
  private readonly layout = inject(LayoutService);
  private readonly auth = inject(AuthenticationService);
  private readonly store = inject(InvestmentVehicleStore);

  entityId = signal<number | undefined>(undefined);

  constructor() {
    effect(() => {
      if (this.entityId() && this.layout.availableViewItems()?.length! > 0) {
        untracked(() => {
          this.store.setEntityId(this.entityId()!);
          this.store.loadAllMethods();
        });
      }
    });
    let defaultViewFx = effect(() => {
      if (this.store.allMethodsAreFinalized?.()) {
        //Set Default View Layout

        let defaultView = this.auth.user()?.getDefaultView();
        if (
          this.auth.user()?.isInRole(ROLES.ADMIN) &&
          !this.auth.user()?.impersonate
        ) {
          defaultView =
            this.local.get(STORAGE_KEYS.LAYOUT.DEFAULT_VIEW) ?? defaultView;
        } else if (
          this.auth.user()?.isInRole(ROLES.DATA) ||
          this.auth.user()?.isInRole(ROLES.INVESTMENT_RESEARCH)
        ) {
          if (this.store.investmentVehicle().type === AssetTypeEnum.CoInvest) {
            defaultView = ASSET_TEAM_VIEWS.CO_INVESTMENT;
          } else if (
            this.store.investmentVehicle().type === AssetTypeEnum.Secondary
          ) {
            defaultView = ASSET_TEAM_VIEWS.SECONDARY;
          }
        }

        this.layout.setDefaultView(defaultView, false);
        this.layout.selectView(this.layout.defaultView());
        defaultViewFx.destroy();
      }
    });
    effect(() => {
      if (this.store.allMethodsAreFinalized?.()) {
        this.layout.layoutIsReady.set(true);
      }
    });
  }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<number> | Promise<number> | number {
    this.layout.layoutIsReady.set(false);

    //Load data
    const entityId = +route.params['entityId'];
    if (Number.isSafeInteger(entityId)) {
      this.entityId.set(entityId);
    }

    return entityId;
  }
}
