import { inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  CacheItem,
  ENVIRONMENT_TOKEN,
  StorageTypeEnum,
} from '@aksia/infrastructure';
import { LocalStorageService } from '../browser/local-storage.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private readonly env = inject(ENVIRONMENT_TOKEN);
  private readonly localStorageService = inject(LocalStorageService);
  public static cacheStatusHeader: string = 'X-Aksia-ResponseCache-Unchanged';
  public static cacheInfoHeader: string = 'X-Aksia-ResponseCache-Info';
  private cacheKey: string | null = null;
  private cacheItem?: CacheItem;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (this.env.storage_type !== StorageTypeEnum.Database) {
      this.cacheKey = req.params.get('Route');
      if (this.cacheKey === null) return next.handle(req);

      this.cacheItem = this.localStorageService.get(this.cacheKey);
      if (this.cacheItem?.cacheInfo) {
        req = req.clone({
          headers: req.headers.set(
            CacheInterceptor.cacheInfoHeader,
            this.cacheItem.cacheInfo,
          ),
        });
      }
    }
    return next.handle(req).pipe(
      map((evt) => {
        if (evt instanceof HttpResponse) {
          const cacheUnchanged = evt.headers.get(
            CacheInterceptor.cacheStatusHeader,
          );
          if (cacheUnchanged === 'False') {
            this.setCache(evt);
          } else if (cacheUnchanged === 'True') {
            this.cacheItem =
              this.localStorageService.get(this.cacheKey!) ??
              this.setCache(evt);
            evt = evt.clone({ body: this.cacheItem!.data });
          }
        }
        return evt;
      }),
    );
  }

  setCache(evt: HttpResponse<any>) {
    this.cacheItem = {
      cacheInfo: evt.headers.get(CacheInterceptor.cacheInfoHeader),
      data: evt.body,
    };
    this.localStorageService.set(this.cacheKey!, this.cacheItem);
    return this.cacheItem;
  }
}
