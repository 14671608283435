@if (permDir.uipermission() !== PERMISSIONS.NONE || permDir.editPermissions()) {
  <button
    type="button"
    [class]="inputCssClass()"
    (click)="(null)"
    (keydown.enter)="$event.preventDefault()"
  >
    @if (icon()) {
      <icon
        [iconDefinition]="icon()"
        [iconSize]="iconSize()!"
        [iconColor]="iconColor()"
      ></icon>
    }

    {{ label() && !iconOnly() ? label() : '' }}
  </button>
}
