import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  WritableSignal,
  input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../presentation/icon/icon.component';
import { LabelComponent } from '../../presentation/label/label.component';
import { UiInputDirective } from '../../../directives/ui/ui-input.directive';
import {
  FILE_UPLOAD_UI_TYPE,
  FileUploadSettings,
  FileUploadUiType,
  IFileUploadSettings,
  UTILS,
  PERMISSIONS,
} from '@aksia/infrastructure';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'file-upload',
  standalone: true,
  imports: [CommonModule, IconComponent, LabelComponent, ButtonComponent],
  templateUrl: './file-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent extends UiInputDirective {
  // #region Inputs / Outputs

  override settings = input<FileUploadSettings, IFileUploadSettings>(
    new FileUploadSettings(),
    {
      transform: (settings: IFileUploadSettings) => this.initSettings(settings),
    },
  );

  showLoader = input(false);

  @Output() fileUploaded: EventEmitter<File | FileList> = new EventEmitter();
  @Output() singleFileUploaded: EventEmitter<File> = new EventEmitter();

  // #endregion

  // #region Signals / Properties

  uiType: WritableSignal<FileUploadUiType> = signal(FILE_UPLOAD_UI_TYPE.Button);

  allowMultipleFiles: WritableSignal<boolean> = signal(false);

  allowedFileTypes: WritableSignal<string> = signal(
    FileUploadSettings.defaultAllowedFileTypes,
  );

  selectedFiles = signal<FileList | null>(null);

  // #endregion

  // #region Lifecycle / Initialization

  protected override initSettings(settings: IFileUploadSettings) {
    const fileUploadSettings = UTILS.OBJECT.assign(
      new FileUploadSettings(),
      settings,
    );

    super.initSettings(fileUploadSettings);
    this.uiType.set(fileUploadSettings.uiType!);
    this.allowMultipleFiles.set(fileUploadSettings.allowMultipleFiles!);
    this.allowedFileTypes.set(fileUploadSettings.allowedFileTypes!);
    this.defaultIcon.set(fileUploadSettings.defaultIcon);

    return fileUploadSettings;
  }

  // #endregion

  // #region Event Handlers

  protected onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input?.files?.length) {
      return;
    }

    const files = input.files;
    this.selectedFiles.set(files);

    this.value.set(
      Array.from(files)
        .map((file) => file.name)
        .join(','),
    );

    if (this.allowMultipleFiles()) {
      this.fileUploaded.emit(files);
    } else {
      this.singleFileUploaded.emit(files.item(0)!);
    }
  }

  protected clearFileSelection(input: HTMLInputElement): void {
    input.value = '';
    this.selectedFiles.set(null);
    this.value.set('');
  }
  // #endregion

  // #region Utility / Accessors

  get canEdit(): boolean {
    return this.permDir && this.permDir.uipermission() !== PERMISSIONS.VIEW;
  }

  // #endregion
}
