import { Directive, effect, ElementRef, inject, input } from '@angular/core';
import { LAYOUT_SERVICE_TOKEN } from '@aksia/infrastructure';

@Directive({
  selector: '[uianchor]',
  standalone: true,
})
export class UiAnchorDirective {
  //#region Injections

  protected readonly layout = inject(LAYOUT_SERVICE_TOKEN);
  private readonly elementRef = inject(ElementRef);

  //#endregion

  //#region Inputs

  uianchor = input<string | undefined>(undefined);

  //#endregion

  constructor() {
    effect(() => {
      if (this.uianchor()) {
        this.layout.addAnchor(this.uianchor()!, this.elementRef);
      }
    });
  }
}
