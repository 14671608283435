@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.closedEndStructure.coInvestPolicy"
    [settings]="{
      label: 'Co-invest Policy',
      options: ENUMS.CoInvestPolicyEnum.toOptions,
      optionsFiltering: false,
    }"
  ></dropdown>
  <dropdown
    class="c2s1"
    [(value)]="iv.closedEndDetails.closedEndGPInformation!.thirdPartyManagement"
    [settings]="{
      label: '3rd Party Management Company Interest',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      optionsFiltering: false,
      tag: 'Third Party Management',
    }"
  ></dropdown>
  <dropdown
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.agmTiming"
    [settings]="{
      label: 'AGM Timing',
      options: ENUMS.MonthEnum.toOptions,
      optionsSorting: false,
      optionsFiltering: false,
    }"
  ></dropdown>
  @if (iv.vEntityType === ENUMS.VEntityTypeEnum.CommingledFund) {
    <dropdown
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPInformation!.commingledFundByManager
      "
      [settings]="{
        label: 'Commingled Fund by Manager',
        options: ENUMS.RankEnum.toOptions([ENUMS.RankEnum._4th]),
        optionsFiltering: false,
        labelTip:
          'Specify if the fund is the 1st, 2nd or 3rd fund launched to external capital by the Manager. Does not include new products launched by established managers.',
      }"
    ></dropdown>
    <dropdown
      class="c2s1"
      [(value)]="
        iv.closedEndDetails.closedEndGPInformation!.commingledFundInSeries
      "
      [settings]="{
        label: 'Commingled Fund in Series',
        options: ENUMS.RankEnum.toOptions([ENUMS.RankEnum._4th]),
        optionsFiltering: false,
        labelTip:
          'Specify if the fund is the 1st, 2nd or 3rd fund in the Series this fund belongs to',
      }"
    ></dropdown>
  }
  <check
    class="c1s1"
    [(value)]="iv.excludeForwardCalendar"
    [settings]="{
      label: 'Exclude from Forward Calendar',
      labelTip:
        'Marking this field will result in this fund NOT showing up on the Forward Calendar tool in MAX, regardless of status or any other rules in the Forward Calendar tool',
    }"
  ></check>
}
