@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <text
    class="c1s6"
    [(value)]="iv.fundOverview"
    [settings]="{
      label: 'Overview',
      multiline: true,
      showCharCount: true,
      labelTip:
        'Summary written by Aksia individual(s), based on at least preliminary review of the manager. Typically provided during creation of entity.',
      tag: 'Fund Overview',
    }"
  ></text>
}
