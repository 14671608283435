@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <dropdown
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.status"
    [settings]="{
      label: 'Aksia Status',
      options: ENUMS.AksiaStatusEnum.toOptions,
      optionsFiltering: false,
    }"
  ></dropdown>
  <text
    class="c2s7"
    [(value)]="iv.coInvestmentDetails.sourcingAnalystName"
    [settings]="{
      label: 'Sourcing Analyst',
      labelTip: 'Select who sourced this co-investment opportunity',
    }"
  ></text>
  <date
    class="r2s1"
    [(value)]="iv.coInvestmentDetails.initialLogDate"
    [settings]="{
      label: 'Initial Log Date',
      placeholder: 'mm/dd/yyyy',
    }"
  ></date>
  <date
    class="r2s1"
    [(value)]="iv.coInvestmentDetails.dateApprovedFurtherReview"
    [settings]="{
      label: 'Further Review',
      placeholder: 'mm/dd/yyyy',
      tag: 'Date Approved Further Review',
    }"
  ></date>
  <date
    class="r2s1"
    [(value)]="iv.coInvestmentDetails.dateApprovedDD"
    [settings]="{
      label: 'Due Diligence',
      placeholder: 'mm/dd/yyyy',
      tag: 'Date Approved DD',
    }"
  ></date>
  <date
    class="r2s1"
    [(value)]="iv.coInvestmentDetails.dateApproved"
    [settings]="{
      label: 'Approved',
      placeholder: 'mm/dd/yyyy',
      tag: 'Date Approved',
    }"
  ></date>
  <dropdown
    class="r2s1"
    [(value)]="iv.secondaryDetails.bidsOutstanding"
    [settings]="{
      label: 'Bids Outstanding',
      options: ENUMS.SimpleAnswerEnum.toOptions,
    }"
  ></dropdown>
  <date
    class="r2s1"
    [(value)]="iv.coInvestmentDetails.dateNotApproved"
    [settings]="{
      label: 'Not Approved',
      placeholder: 'mm/dd/yyyy',
      tag: 'Date Not Approved',
    }"
  ></date>
  <date
    class="r2s1"
    [(value)]="iv.coInvestmentDetails.dateHalted"
    [settings]="{
      label: 'Halted',
      placeholder: 'mm/dd/yyyy',
      tag: 'Date Halted',
    }"
  ></date>
  <date
    class="r2s1"
    [(value)]="iv.secondaryDetails.targetICDate"
    [settings]="{
      label: 'Target IC Date',
      placeholder: 'mm/dd/yyyy',
    }"
  ></date>
}
