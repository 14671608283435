<div
  class="grid grid-cols-[20px_1fr_1fr_1fr_1fr_60px] gap-1 mb-[200px] w-[1100px]"
>
  @if (
    !layout.editingPermission() ||
    layout.editingPermission()?.claims?.length === 0
  ) {
    <btn
      uiunpermissioned
      class="place-self-stretch"
      [settings]="{
        label: 'Add Claim',
        icon: ICONS.add,
        iconSize: 'normal',
        tag: 'Add Claim',
      }"
      (click)="layout.addClaim()"
    ></btn>
  } @else {
    <check
      class="c2s5 place-self-end"
      uiunpermissioned
      [settings]="{
        label: 'Is Collection',
      }"
      [(value)]="layout.editingPermission()!.applyToCollection"
      (valueChange)="layout.applyClaimToCollection()"
    ></check>
    <label class="c1s1">#</label>
    <label class="c2s1">Roles</label>
    <label class="c3s1">Views</label>
    <label class="c4s1">Categories</label>
    <label class="c5s1">Rights</label>
    @for (
      claim of layout.editingPermission()?.claims;
      track i;
      let i = $index;
      let c = $count
    ) {
      <label class="c1s1 place-self-center">{{ i + 1 }}</label>
      <multi-dropdown
        class="c2s1"
        uiunpermissioned
        [settings]="{
          placeholder: 'Select Roles',
          optionLabel: 'value',
          optionValue: 'value',
          options: ROLES.toKeyValue(),
          optionsFiltering: false,
          valueOutput: 'plain',
          storeOnlyValue: true,
        }"
        [popupCssClass]="'!max-h-[200px]'"
        [(value)]="claim.roles"
      ></multi-dropdown>
      <multi-dropdown
        class="c3s1"
        uiunpermissioned
        [settings]="{
          placeholder: 'Select Views',
          optionLabel: 'value',
          optionValue: 'value',
          options: VIEWS.toKeyValue(),
          optionsFiltering: false,
          valueOutput: 'plain',
          storeOnlyValue: true,
        }"
        [popupCssClass]="'!max-h-[200px]'"
        [(value)]="claim.views"
      ></multi-dropdown>
      <multi-dropdown
        class="c4s1"
        uiunpermissioned
        [settings]="{
          placeholder: 'Select Categories',
          optionLabel: 'value',
          optionValue: 'value',
          options: ASSET_TYPES.toOptions,
          optionsFiltering: false,
          valueOutput: 'plain',
          storeOnlyValue: true,
        }"
        [popupCssClass]="'!max-h-[200px]'"
        [(value)]="claim.categories"
      ></multi-dropdown>
      <dropdown
        class="c5s1"
        uiunpermissioned
        [settings]="{
          placeholder: 'Select Rights',
          optionLabel: 'value',
          optionValue: 'value',
          options: PERMISSIONS.toKeyValue(),
          optionsFiltering: false,
        }"
        [popupCssClass]="'!max-h-[200px]'"
        [(value)]="claim.rights"
      ></dropdown>
      <div class="flex c6s1 place-self-stretch">
        <btn
          uiunpermissioned
          class="place-self-stretch"
          [settings]="{
            icon: ICONS.remove,
            iconOnly: true,
            iconSize: 'normal',
            tag: 'Remove Claim #' + (i + 1),
          }"
          (click)="layout.removeClaim(i)"
        ></btn>

        @if (i === c - 1) {
          <btn
            uiunpermissioned
            class="place-self-stretch"
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Claim',
            }"
            (click)="layout.addClaim()"
          ></btn>
        }
      </div>
    }
  }
</div>
