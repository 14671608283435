import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';

import {
  ClassicEditor,
  EditorConfig,
  Alignment,
	Autoformat,
	Base64UploadAdapter,
	BlockQuote,
	Bold,
	Essentials,
	Heading,
	Image,
	ImageCaption,
	ImageResize,
	ImageStyle,
	ImageToolbar,
	ImageUpload,
	Indent,
	Italic,
	Link,
	List,
	MediaEmbed,
	Paragraph,
	PasteFromOffice,
	RemoveFormat,
	SourceEditing,
	Strikethrough,
	Table,
	TableCellProperties,
	TableProperties,
	TableToolbar,
	TextTransformation,
	Underline,
	WordCount,
  FontSize,
  FontColor,
  FontBackgroundColor,
  ShowBlocks,
  SpecialCharacters,
  SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
  HorizontalLine,
  Highlight
} from 'ckeditor5';

import RephrasePlugin from './plugins/rephrase/rephrase-plugin';
import { RephraseModalComponent } from './plugins/rephrase/rephrase-modal.component';
import { RephraseModel } from './plugins/rephrase/rephrase.model';

interface CustomEditorConfig extends EditorConfig {
  angularContext?: any;
}

@Component({
  standalone: true,
  selector: 'rich-text',
  imports: [
    CommonModule, 
    CKEditorModule,
    FormsModule,
    RephraseModalComponent
  ],
  templateUrl: './rich-text.component.html',    
  styleUrl: './rich-text.component.css',
  encapsulation: ViewEncapsulation.None
})
export class RichTextComponent implements AfterViewInit {  
   
  //#region Inputs/Outputs
  
  @Input() model!: string | undefined;
  @Input() envConfig!: any;
  @Output() modelChange = new EventEmitter<string>();
  
  //#endregion  
  
  //#region Properties
  
  protected Editor: typeof ClassicEditor = ClassicEditor;
  protected config: CustomEditorConfig = {};
  protected isChanged: boolean = false;
  protected isReady = signal(false);

  protected isRephraseModalOpen = signal(false);
  protected rephraseCallback = signal<(event: { updatedText: string; attributes: any[] }) => void>(() => {});
  
  @ViewChild('rephraseModalRef') rephraseModalRef!: RephraseModalComponent;
  
  //#endregion

  constructor() {}
  
  //#region Functions
  
  ngAfterViewInit() {
    this._setupEditor();
  }

  protected focusout() {
    if (!this.isChanged)
      return;

    this.modelChange.emit(this.model);
    this.isChanged = false;
  }

  openRephraseModal(rephraseObj: RephraseModel, onRephraseCallback: (event: { updatedText: string; attributes: any[] }) => void) {
    this.isRephraseModalOpen.set(true);
    this.rephraseCallback.set(onRephraseCallback);
    this.rephraseModalRef?.open(rephraseObj);
  }

  closeRephraseModal() {
    this.isRephraseModalOpen.set(false);
    this.rephraseModalRef?.close();
  }

  onRephrase(event: { updatedText: string; attributes: any[] }) {
    if (this.rephraseCallback) {
      this.rephraseCallback()(event); // Call the provided callback
      this.closeRephraseModal();
    } else {
      console.warn('Rephrase callback is not defined.');
    }
  }

  private _setupEditor () {
    this.config = {        
      toolbar: {
        items: [ 
          'undo', 'redo', 
          '|', 
          'bold', 'italic','underline', 'strikethrough', 'removeFormat',
          '|',
          'heading',
          '|',
          'fontSize', 'fontColor', 'fontBackgroundColor',
          '|', 
          'specialCharacters', 'horizontalLine', 'blockQuote', 'imageUpload', 'link', 'insertTable', 'highlight',
          '|',
          'alignment',            
          '|',
          'bulletedList', 'numberedList', 'outdent', 'indent',
          '|',
          'rephrase',
          '|',
          'showBlocks', 'sourceEditing' 
        ],
        shouldNotGroupWhenFull: true
      },
      plugins: [
        Alignment,
        Autoformat,
        Base64UploadAdapter,
        BlockQuote,
        Bold,
        Essentials,
        FontSize,
        FontColor,
        FontBackgroundColor,
        Heading,
        Highlight,
        HorizontalLine,
        Image,
        ImageCaption,
        ImageResize,
        ImageStyle,
        ImageToolbar,
        ImageUpload,
        Indent,
        Italic,
        Link,
        List,
        MediaEmbed,
        Paragraph,
        PasteFromOffice,
        RemoveFormat,
        ShowBlocks,
        SourceEditing,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Strikethrough,
        Table,
        TableCellProperties,
        TableProperties,
        TableToolbar,
        TextTransformation,
        Underline,
        WordCount,
        RephrasePlugin
      ],
      fontFamily: {
        supportAllValues: true
      },
      fontSize: {
        options: [10, 12, 14, 'default', 18, 20, 22],
        supportAllValues: true
      },
      heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Paragraph',
            class: 'ck-heading_paragraph'
          },
          {
            model: 'heading1',
            view: 'h1',
            title: 'Heading 1',
            class: 'ck-heading_heading1'
          },
          {
            model: 'heading2',
            view: 'h2',
            title: 'Heading 2',
            class: 'ck-heading_heading2'
          },
          {
            model: 'heading3',
            view: 'h3',
            title: 'Heading 3',
            class: 'ck-heading_heading3'
          },
          {
            model: 'heading4',
            view: 'h4',
            title: 'Heading 4',
            class: 'ck-heading_heading4'
          },
          {
            model: 'heading5',
            view: 'h5',
            title: 'Heading 5',
            class: 'ck-heading_heading5'
          },
          {
            model: 'heading6',
            view: 'h6',
            title: 'Heading 6',
            class: 'ck-heading_heading6'
          }
        ]
      },
      htmlSupport: {
        allow: [
          {
            name: /^.*$/,
            styles: true,
            attributes: true,
            classes: true
          }
        ]
      },
      image: {
        toolbar: [
          'toggleImageCaption',
          'imageTextAlternative',
          '|',
          'imageStyle:inline',
          'imageStyle:wrapText',
          'imageStyle:breakText',
          '|',
          'resizeImage'
        ]
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: {
          toggleDownloadable: {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'file'
            }
          }
        }
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true
        }
      },
      mention: {
        feeds: [
          {
            marker: '@',
            feed: [
              /* See: https://ckeditor.com/docs/ckeditor5/latest/features/mentions.html */
            ]
          }
        ]
      },
      placeholder: 'Type or paste your content here!',
      style: {
        definitions: [
          {
            name: 'Article category',
            element: 'h3',
            classes: ['category']
          },
          {
            name: 'Title',
            element: 'h2',
            classes: ['document-title']
          },
          {
            name: 'Subtitle',
            element: 'h3',
            classes: ['document-subtitle']
          },
          {
            name: 'Info box',
            element: 'p',
            classes: ['info-box']
          },
          {
            name: 'Side quote',
            element: 'blockquote',
            classes: ['side-quote']
          },
          {
            name: 'Marker',
            element: 'span',
            classes: ['marker']
          },
          {
            name: 'Spoiler',
            element: 'span',
            classes: ['spoiler']
          },
          {
            name: 'Code (dark)',
            element: 'pre',
            classes: ['fancy-code', 'fancy-code-dark']
          },
          {
            name: 'Code (bright)',
            element: 'pre',
            classes: ['fancy-code', 'fancy-code-bright']
          }
        ]
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
      },
      angularContext: { envConfig: this.envConfig, openRephraseModal: this.openRephraseModal.bind(this) }
    };

    this.isReady.set(true);
  }

  //#endregion
}
