@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.organizationalExpenseCapType"
    [settings]="{
      label: 'Organizational Expense Cap',
      options: ENUMS.MinGPCommitmentEnum.toOptions,
      tag: 'Organizational Expense Cap Type',
    }"
    (valueChange)="ivStore.updateOrganizationalExpenseCapType($event)"
  ></dropdown>

  @if (
    iv.closedEndDetails.organizationalExpenseCapType &&
    iv.closedEndDetails.organizationalExpenseCapType !==
      ENUMS.MinGPCommitmentEnum.Amount
  ) {
    <number
      class="c2s1"
      [(value)]="iv.closedEndDetails.organizationalExpenseCapRate"
      [settings]="{
        label: '',
        placeholder: '% of commitments',
        suffix: ' % of commitments',
        tag: 'Organizational Expense Cap Rate',
      }"
      [validators]="ivStore.organizationalExpenseCapRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
  }
  @if (
    iv.closedEndDetails.organizationalExpenseCapType &&
    iv.closedEndDetails.organizationalExpenseCapType !==
      ENUMS.MinGPCommitmentEnum.Percentage
  ) {
    <number
      class="c3s2"
      [(value)]="iv.closedEndDetails.organizationalExpenseCapAmount"
      [settings]="{
        label: '',
        placeholder: 'in million/billion',
        abbr: true,
        tag: 'Organizational Expense Cap Amount',
      }"
      [validators]="ivStore.organizationalExpenseCapAmount_validators()"
      [validation.id]="iv.$uid"
    ></number>
  }
}
