@if (permDir.uipermission() !== PERMISSIONS.NONE || permDir.editPermissions()) {
  <div class="flex items-center gap-3 mb-2">
    <input
      #uiValueRef
      type="file"
      (change)="canEdit ? onFileSelected($event) : false"
      [multiple]="allowMultipleFiles()"
      [hidden]="uiType() === 'button'"
      [accept]="allowedFileTypes()"
      [ngClass]="{
        '  px-2 py-1 text-sm cursor-pointer focus:outline-none focus:border-gray-500 focus:ring-2 focus:ring-gray-200': true,
        'border-green-500': value(),
      }"
    />
    @if (label() && settings().uiType === 'field') {
      <label
        [attr.uilabelup]="labelUp()"
        [label]="labelFormatted()"
        [labelTip]="labelTip()"
        class="font-semibold text-gray-700 cursor-pointer"
        (click)="canEdit ? uiValueRef.click() : false"
      >
      </label>
    }
    @if (uiType() === 'button' && canEdit) {
      <btn
        class="px-3 py-1 text-sm secondary w-full bg-gray-100 border border-gray-300 rounded hover:bg-gray-200 transition-colors"
        (click)="uiValueRef.click()"
        [settings]="{
          label: 'Upload',
          icon: 'bootstrapPaperclip',
          iconSize: 'normal',
        }"
      >
        Choose File
      </btn>
    }
    @if (value() && showLoader()) {
      <span class="text-gray-700 text-xs">
        {{ value() }}
      </span>
    } @else {
      <span class="text-gray-700 text-nowrap text-sm"> No file chosen. </span>
    }

    <div class="flex items-center gap-2">
      <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
      <ng-template #uiToolsCodeRef></ng-template>

      <ng-container #uiToolsDefaultRef>
        @if (defaultIcon()) {
          <icon
            class="text-gray-600 hover:text-gray-900 cursor-pointer transition-colors"
            [iconSize]="'normal'"
            [iconDefinition]="defaultIcon()"
            (click)="canEdit ? uiValueRef.click() : false"
          ></icon>
        }
      </ng-container>

      @if (showLoader()) {
        <icon
          class="animate-spin"
          [iconSize]="'large'"
          [iconDefinition]="'faSolidSpinner'"
        ></icon>
      }
    </div>
  </div>
}
