<section class="c2s1 r2s1" [settings]="{ headerIsHidden: true }">
  <div login-toolbar class="c1s3">
    <div area="logo">
      <img src="assets/aksia_logo.svg" />
      <span area="version" class="r2s1 version">v{{ version }}</span>
    </div>
    <div area="separator"></div>
    <div area="title">{{ title() }}</div>
  </div>

  @if (
    [
      authStepEnum.NotAuthenticated,
      authStepEnum.VerifyingCredentials,
      authStepEnum.RejectedAuthentication,
    ].includes(auth.authenticationStep())
  ) {
    <text
      class="c2s1 r2s1"
      [settings]="{
        placeholder: 'username',
        placeholderPersists: true,
        tag: 'username',
      }"
      [value]="credentials().maxusername"
      (valueChange)="updateUsername($event)"
    >
      <icon
        uitool
        uidefaulttool
        [iconSize]="'normal'"
        [iconDefinition]="ICONS.user"
      ></icon>
    </text>
    <text
      #passCtrl
      class="c2s1 r3s1"
      [settings]="{
        placeholder: 'password',
        placeholderPersists: true,
        type: passwordVisibility() ? 'text' : 'password',
        tag: 'password',
      }"
      [value]="credentials().maxpassword"
      (valueChange)="updatePassword($event)"
      (keydown.enter)="passCtrl.focusout(); auth.signIn(this.credentials())"
    >
      <icon
        uitool
        [iconSize]="'normal'"
        [iconDefinition]="ICONS.eye"
        (click)="passwordVisibility.set(!passwordVisibility())"
      ></icon>
      <icon
        uitool
        uidefaulttool
        [iconSize]="'normal'"
        [iconDefinition]="ICONS.password"
      ></icon>
    </text>
  }
  @if (
    [authStepEnum.Requested2FA, authStepEnum.Verifying2FA].includes(
      auth.authenticationStep()
    )
  ) {
    <number
      class="c2s1 r3s1"
      [settings]="{
        onlyWholeNumbers: true,
        permitZero: true,
        thousandsSeparator: '',
      }"
      [(value)]="credentials().IPVerificationCode"
    >
      <icon
        uitool
        uidefaulttool
        [iconSize]="'normal'"
        [iconDefinition]="ICONS.key"
      ></icon>
    </number>
  }
  <btn
    class="c2s1 r4s1 tertiary shadow-sm place-self-center"
    [settings]="
      signInIsDisabled()
        ? { label: btnTitle(), tag: btnTitle(), disabled: true }
        : { label: btnTitle(), tag: btnTitle() }
    "
    (click)="
      auth.authenticationStep() === authStepEnum.Requested2FA
        ? auth.verify2FA(this.credentials())
        : auth.signIn(this.credentials())
    "
  >
  </btn>
  <p
    class="c2s1 r5s1"
    [innerHTML]="auth.statusMessage()"
    [class.r4s1]="
      [
        authStepEnum.NotAuthenticated,
        authStepEnum.VerifyingCredentials,
        authStepEnum.RejectedAuthentication,
      ].includes(auth.authenticationStep())
    "
    [class.r3s1]="
      [authStepEnum.Requested2FA, authStepEnum.Verifying2FA].includes(
        auth.authenticationStep()
      )
    "
  ></p>
</section>
