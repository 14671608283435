import {
  ChangeDetectionStrategy,
  Component,
  effect,
  HostBinding,
  inject,
  input,
  reflectComponentType,
  Type,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SectionComponent,
  ButtonComponent,
  UiPermissionDirective,
  UiAnchorDirective,
  UiAnchorhostDirective,
} from '@aksia/ui';
import { IPageSettings, PageSettings } from '@aksia/infrastructure';
import { AuthenticationService } from '../../services/authentication.service';
import { InvestmentVehicleStore } from '../../stores/investment-vehicle.store';
import { CommonContents } from '../../contents/common/common.content';
import { InvestmentVehicleCommonContents } from '../../contents/investment-vehicle/common/investment-vehicle-common.content';
import { StaticContentDirective } from '../../contents/static-content.directive';
import { InvestmentVehicleClosedEndContents } from '../../contents/investment-vehicle/closed-end/investment-vehicle-closed-end.content';
import { ShareclassClosedEndContents } from '../../contents/shareclass/closed-end/shareclass-closed-end.content';
import { InvestmentVehicleOpenEndContents } from '../../contents/investment-vehicle/open-end/investment-vehicle-open-end.content';
import { ShareclassOpenEndContents } from '../../contents/shareclass/open-end/shareclass-open-end.content';
import { TaxonomyContents } from '../../contents/taxonomy/taxonomy.content';
import { InvestmentVehicleCoInvestmentContents } from '../../contents/investment-vehicle/co-investment/investment-vehicle-co-investment.content';
import { InvestmentVehicleSecondaryContents } from '../../contents/investment-vehicle/secondary/investment-vehicle-secondary.content';

@Component({
  selector: 'page-content[uipagecontent="investment-vehicle"]',
  imports: [
    CommonModule,
    SectionComponent,
    ButtonComponent,
    TaxonomyContents,
    CommonContents,
    InvestmentVehicleCommonContents,
    InvestmentVehicleOpenEndContents,
    InvestmentVehicleClosedEndContents,
    InvestmentVehicleCoInvestmentContents,
    InvestmentVehicleSecondaryContents,
    ShareclassOpenEndContents,
    ShareclassClosedEndContents,
    UiAnchorDirective,
  ],
  template: `
    <section
      class="unstyled"
      [settings]="{ tag: 'View Actions', headerIsHidden: true }"
      [contentCssClass]="'flex place-content-end py-2'"
    >
      <btn
        class="md secondary"
        [settings]="{
          label: 'Download',
          icon: ICONS.download,
          iconSize: 'large',
          tag: 'Downlad One Pager',
        }"
      ></btn>
      <btn
        class="md secondary"
        [settings]="{
          label: 'Upload',
          icon: ICONS.upload,
          iconSize: 'large',
          tag: 'Upload One Pager',
        }"
      ></btn>
    </section>
    @if (layout.layoutIsReady()) {
      @for (item of layout.selectedViewItems(); track getSectionId($index)) {
        <section
          [uianchor]="item.settings.tag"
          [settings]="item.settings"
          [class]="item.class"
          [contentCssClass]="item.contentCssClass"
          [sectionContentComponent]="
            sectionContentMap.get(item.settings.tag) ?? null
          "
        ></section>
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: UiPermissionDirective,
      inputs: ['uipermission'],
    },
    {
      directive: UiAnchorhostDirective,
    },
  ],
})
export class InvestmentVehiclePageContent extends StaticContentDirective {
  //#region Injections

  protected readonly auth = inject(AuthenticationService);
  protected readonly store = inject(InvestmentVehicleStore);
  protected readonly permDir = inject(UiPermissionDirective);

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uitag') get uitag() {
    return this.permDir.tag();
  }

  //#endregion

  //#region Properties

  settings = input<PageSettings, IPageSettings>(new PageSettings(), {
    transform: (settings: IPageSettings) => this.initSettings(settings),
  });

  sectionContentMap: Map<string, Type<any> | null> = new Map();

  //#endregion

  protected initSettings(settings: IPageSettings) {
    return Object.assign(new PageSettings(), settings);
  }

  constructor() {
    super();
    [
      ...TaxonomyContents,
      ...CommonContents,
      ...InvestmentVehicleCommonContents,
      ...InvestmentVehicleOpenEndContents,
      ...InvestmentVehicleClosedEndContents,
      ...InvestmentVehicleCoInvestmentContents,
      ...InvestmentVehicleSecondaryContents,
      ...ShareclassOpenEndContents,
      ...ShareclassClosedEndContents,
    ].forEach((sectionContent) => {
      const metadata = reflectComponentType(sectionContent as Type<any>);
      const selector = this.normalizeSelector(metadata?.selector);
      this.sectionContentMap.set(selector!, sectionContent);
    });

    effect(() => this.permDir.tag.set(this.layout.selectedView() as string));
  }

  getSectionId(index: number) {
    return this.ivStore.entityId()! + index;
  }

  private normalizeSelector(selector?: string) {
    if (!selector) return null;
    selector = selector
      .toLowerCase()
      .replace('[uisectioncontent=', '')
      .replace(']', '')
      .replaceAll('"', '')
      .replace(/([^a-z])([a-z])(?=[a-z]{2})|^([a-z])/g, (_, g1, g2, g3) =>
        typeof g1 === 'undefined' ? g3.toUpperCase() : g1 + g2.toUpperCase(),
      );
    return selector;
  }
}
