import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IPageSettings, PageSettings } from '@aksia/infrastructure';
import { ButtonComponent, SectionComponent } from '@aksia/ui';
import { AuthenticationService } from '../../services/authentication.service';
import { CompanyStore } from '../../stores/company.store';
import { StaticContentDirective } from '../../contents/static-content.directive';
import { CompanyContents } from '../../contents/company/company.content';
import { CommonContents } from '../../contents/common/common.content';

@Component({
  selector: '[page-content-company]',
  imports: [
    CommonModule,
    SectionComponent,
    ButtonComponent,
    CommonContents,
    CompanyContents,
  ],
  template: `
    <section
      class="unstyled"
      [settings]="{ headerIsHidden: true }"
      [contentCssClass]="'flex place-content-end py-2'"
    >
      <btn
        class="md secondary"
        [settings]="{
          label: 'Download View',
          icon: ICONS.download,
          iconSize: 'large',
          tag: 'Downlad View',
        }"
        (click)="layout.downloadView()"
      ></btn>
      <btn
        class="md secondary"
        [settings]="{
          label: 'Upload',
          icon: ICONS.upload,
          iconSize: 'large',
          tag: 'Upload One Pager',
        }"
      ></btn>
    </section>
    @if (layout.selectedView()) {
      @for (item of layout.selectedViewItems(); track $index) {
        <section
          [settings]="item.settings"
          [class]="item.class"
          [contentCssClass]="item.contentCssClass"
        >
          <!-- <ng-container
            *ngTemplateOutlet="item.template; context: { $implicit: store }"
          ></ng-container> -->
        </section>
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPageContent extends StaticContentDirective {
  //#region Injections

  protected readonly auth = inject(AuthenticationService);
  protected readonly store = inject(CompanyStore);

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uipagecontent')
  uipagecontent = '';

  //#endregion

  //#region Properties

  settings = input<PageSettings, IPageSettings>(new PageSettings(), {
    transform: (settings: IPageSettings) => this.initSettings(settings),
  });

  protected initSettings(settings: IPageSettings) {
    return Object.assign(new PageSettings(), settings);
  }
}
