import { INavigationService, ENVIRONMENT_TOKEN } from '@aksia/infrastructure';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements INavigationService {
  private readonly router = inject(Router);
  private readonly env = inject(ENVIRONMENT_TOKEN);

  //#endregion

  //#region Functions

  constructor() {
    this.initLoad();
  }

  initLoad() {}

  public goToPage(url: string, newTab: boolean = false) {
    if (!newTab) {
      this.router.navigate([`${url}`]);
    } else {
      window.open(`${this.env.app_url}/${url}`, '_blank');
    }
  }

  //#endregion
}
