import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  inject,
  input,
  model,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  IPageLayoutSettings,
  PAGE_LAYOUT,
  PageLayoutSettings,
  PageLayoutType,
  PropExtend,
  UTILS,
} from '@aksia/infrastructure';
import { UiControlDirective } from '../../../directives/ui/ui-control.directive';

@Component({
  selector: 'page-layout',
  imports: [CommonModule, RouterModule],
  templateUrl: './page-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLayoutComponent extends UiControlDirective {
  //#region Injections

  private host = inject(ElementRef);

  //#endregion

  //#region Inputs

  override settings = input<PageLayoutSettings, PageLayoutSettings>(
    new PageLayoutSettings(),
    {
      transform: (settings: IPageLayoutSettings) => this.initSettings(settings),
    },
  );

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uipagelayout') uipagelayout = '';

  @HostBinding('class')
  get class_LayoutType() {
    return this.layoutType();
  }
  layoutType = input<PageLayoutType>(PAGE_LAYOUT.Center);

  @HostBinding('style.--center-area-height')
  get style_centerheight() {
    return this.centerheight();
  }
  centerheight = model<string | undefined>('auto');

  @HostBinding('style.--center-area-width')
  get style_centerwidth() {
    return this.centerwidth();
  }
  centerwidth = model<string | undefined>('auto');

  @HostBinding('style.--top-area-height')
  get style_topheight() {
    return this.topheight();
  }
  topheight = model<string | undefined>('auto');

  @HostBinding('style.--top-area-width')
  get style_topwidth() {
    return this.topwidth();
  }
  topwidth = model<string | undefined>('auto');

  @HostBinding('style.--bottom-area-height')
  get style_bottomheight() {
    return this.bottomheight();
  }
  bottomheight = model<string | undefined>('auto');

  @HostBinding('style.--bottom-area-width')
  get style_bottomwidth() {
    return this.bottomwidth();
  }
  bottomwidth = model<string | undefined>('auto');

  @HostBinding('style.--left-area-height')
  get style_leftheight() {
    return this.leftheight();
  }
  leftheight = model<string | undefined>('auto');

  @HostBinding('style.--left-area-width')
  get style_leftwidth() {
    return this.leftwidth();
  }
  leftwidth = model<string | undefined>('auto');

  @HostBinding('style.--right-area-height')
  get style_rightheight() {
    return this.rightheight();
  }
  rightheight = model<string | undefined>('auto');

  @HostBinding('style.--right-area-width')
  get style_rightwidth() {
    return this.rightwidth();
  }
  rightwidth = model<string | undefined>('auto');

  freeze = model<Array<'top' | 'bottom' | 'left' | 'right'>>([]);

  //#endregion

  protected readonly layouts: PropExtend<PageLayoutType> = PAGE_LAYOUT;
  protected expandedAreas: Array<'top' | 'bottom' | 'left' | 'right'> = [];
  protected cssVariableResize!: string;
  protected propertyResize!: string;

  //#region Functions

  protected override initSettings(
    settings: IPageLayoutSettings,
  ): PageLayoutSettings {
    let pageLayoutSettings = UTILS.OBJECT.assign(
      new PageLayoutSettings(),
      settings,
    ) as PageLayoutSettings;

    this.topheight.set(this.resolveLength(pageLayoutSettings.topArea?.height));
    this.topwidth.set(this.resolveLength(pageLayoutSettings.topArea?.width));
    this.bottomheight.set(
      this.resolveLength(pageLayoutSettings.bottomArea?.height),
    );
    this.bottomwidth.set(
      this.resolveLength(pageLayoutSettings.bottomArea?.width),
    );
    this.leftheight.set(
      this.resolveLength(pageLayoutSettings.leftArea?.height),
    );
    this.leftwidth.set(this.resolveLength(pageLayoutSettings.leftArea?.width));
    this.rightheight.set(
      this.resolveLength(pageLayoutSettings.rightArea?.height),
    );
    this.rightwidth.set(
      this.resolveLength(pageLayoutSettings.rightArea?.width),
    );
    this.centerheight.set(
      this.resolveLength(pageLayoutSettings.centerArea?.height),
    );

    return pageLayoutSettings;
  }

  private resolveLength(length?: number | string) {
    return !length
      ? 'auto'
      : typeof length === 'string'
        ? length?.toString()
        : `${length}px`;
  }

  protected startDrag(e: DragEvent, cssVariable: string) {
    this.cssVariableResize = cssVariable;
    this.propertyResize = cssVariable.replace(/-/g, '');
    /* const proxyEl = (e.target as HTMLElement)?.cloneNode(true) as HTMLElement;
      e.dataTransfer!.setDragImage(proxyEl, 0, 0); */
  }

  protected drag(e: DragEvent) {
    /* this.zone.runOutsideAngular(() => {
      (this as any)[this.propertyResize] = this.calculatePosition(e);
    }); */
    e.preventDefault();
  }

  protected endDrag(e: DragEvent) {
    (this as any)[this.propertyResize] = this.calculatePosition(e);
    e.preventDefault();
  }

  protected toggle(area: 'top' | 'bottom' | 'left' | 'right') {
    if (this.expandedAreas.includes(area)) {
      this.expandedAreas = this.expandedAreas.filter((a) => a !== area);
      this[`${area}height`]?.set(
        this.resolveLength(this.settings()[`${area}Area`]?.height),
      );
      this[`${area}width`]?.set(
        this.resolveLength(this.settings()[`${area}Area`]?.width),
      );
    } else {
      this.expandedAreas.push(area);
      this[`${area}height`]?.set('fit-content(100%)');
      this[`${area}width`]?.set('fit-content(100%)');
    }
  }

  private calculatePosition(e: DragEvent) {
    const position: PropExtend<{}> = {
      topheight: `${e.clientY}px`,
      bottomheight: `${document.body.clientHeight - e.clientY}px`,
      leftwidth: `${e.clientX}px`,
      rightwidth: `${this.host.nativeElement.clientWidth - e.clientX}px`,
    };
    return position[this.propertyResize];
  }

  //#endregion
}
