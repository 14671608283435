@if (pageHeaderComponent()) {
  <div uipageheader *ngComponentOutlet="pageHeaderComponent()"></div>
} @else {
  <div uipageheader></div>
}
@if (pageContentComponent()) {
  <div
    uipagecontent
    *ngComponentOutlet="pageContentComponent(); inputs: pageContentInput()"
  ></div>
} @else {
  <div uipagecontent uianchorhost></div>
}
