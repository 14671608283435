@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <date
    class="c1s1"
    [(value)]="iv.secondaryDetails.firstRoundBidDate"
    [settings]="{
      label: 'First Round Bid Date',
      placeholder: 'mm/dd/yyyy',
    }"
  ></date>
  <date
    class="c2s1"
    [(value)]="iv.secondaryDetails.finalBidDate"
    [settings]="{
      label: 'Final Bid Date',
      placeholder: 'mm/dd/yyyy',
    }"
  ></date>
  <date
    class="c3s1"
    [(value)]="iv.secondaryDetails.expectedDealClose"
    [settings]="{
      label: 'Expected Deal Close',
      placeholder: 'mm/dd/yyyy',
    }"
  ></date>
  <dropdown
    class="c1s1"
    [(value)]="iv.commitmentCurrency"
    [settings]="{
      label: '',
      placeholder: 'Currency',
      options: ENUMS.CurrencyEnum.toOptions,
      tag: 'Commitment Currency',
    }"
    [validators]="ivStore.commitmentCurrency_validators()"
    [validation.id]="iv.$uid"
  ></dropdown>
  <number
    class="c2s1"
    [(value)]="iv.secondaryDetails.finalTransactionPrice"
    [settings]="{
      label: 'Final Transaction Price',
      placeholder: '%',
      suffix: ' %',
    }"
  ></number>
  <text
    class="c3s1"
    [(value)]="iv.secondaryDetails.aksiaProposedPriceRange"
    [settings]="{
      label: 'Aksia Proposed Price Range',
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.secondaryDetails.managementCompanyName"
    [settings]="{
      label: 'Management Company Name',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.secondaryDetails.fundsCompany"
    [settings]="{
      label: 'Fund(s)/Company',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.secondaryDetails.discountGuidance"
    [settings]="{
      label: 'Discount Guidance',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.secondaryDetails.actionItems"
    [settings]="{
      label: 'Action Items',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.coInvestmentDetails.overview"
    [settings]="{
      label: 'Overview',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.coInvestmentDetails.transactionSummary"
    [settings]="{
      label: 'Transaction Summary',
      multiline: true,
      showCharCount: true,
      labelTip: 'Up to 2000 characters.',
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.coInvestmentDetails.merits"
    [settings]="{
      label: 'Merits',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.coInvestmentDetails.issues"
    [settings]="{
      label: 'Issues',
      multiline: true,
    }"
  ></text>
}
