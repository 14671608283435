import {
  AUTHENTICATION_SERVICE_TOKEN,
  PermissionType,
  LAYOUT_SERVICE_TOKEN,
  ILayoutService,
  TViewItem,
  TViewMap,
  PERMISSIONS,
  MODAL_SERVICE_TOKEN,
  MODAL_PERMISSION_TOKEN,
} from '@aksia/infrastructure';
import {
  AfterViewInit,
  computed,
  Directive,
  effect,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  model,
  signal,
  viewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[uipermission]',
  standalone: true,
})
export class UiPermissionDirective implements AfterViewInit {
  //#region Injections

  private route = inject(ActivatedRoute);
  public hostElRef = inject(ElementRef);
  private vcRef = inject(ViewContainerRef);
  public auth = inject(AUTHENTICATION_SERVICE_TOKEN);
  private modal = inject(MODAL_SERVICE_TOKEN);
  private modalContent = inject(MODAL_PERMISSION_TOKEN);
  private layout?: ILayoutService<
    unknown,
    unknown,
    TViewItem<unknown>,
    TViewMap<unknown, unknown>
  >;

  //#endregion

  //#region Host Bindings

  @HostBinding('attr.uipermission') get uiPermission() {
    return this.editPermissions() ? 'Edit' : this.uipermission();
  }

  @HostListener('mousedown', ['$event']) onClick($event: Event) {
    if (this.editPermissions()) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      $event.stopPropagation();

      this.layout?.editPermissionFor(
        this.hostElRef.nativeElement?.tagName?.toLowerCase(),
        this.tag()!,
      );

      this.modal?.openModal({
        label: `Edit Permissions - ${this.tag()}`,
        hasBackrop: true,
        component: this.modalContent,
        vcRef: this.vcRef,
      });
    }
  }

  //#endregion

  //#region Inputs/Outputs

  uipermission = model<PermissionType>();

  //#endregion

  //#region Properties

  tag = signal<string | undefined>(undefined);

  editPermissions = computed<boolean>(() => {
    if (!this.layout?.editPermissionsForControls()) return false;
    switch (this.layout?.editPermissionsForControls()) {
      case 'PAGE-CONTENT':
        return (
          !(this.hostElRef.nativeElement as HTMLElement).hasAttribute(
            'uiunpermissioned',
          ) && this.hostElRef.nativeElement.nodeName === 'PAGE-CONTENT'
        );
      case 'SECTION':
        return (
          !(this.hostElRef.nativeElement as HTMLElement).hasAttribute(
            'uiunpermissioned',
          ) && this.hostElRef.nativeElement.nodeName === 'SECTION'
        );
      case 'SECTION-GROUP':
        return (
          !(this.hostElRef.nativeElement as HTMLElement).hasAttribute(
            'uiunpermissioned',
          ) && this.hostElRef.nativeElement.nodeName === 'SECTION-GROUP'
        );
      default:
        return (
          !(this.hostElRef.nativeElement as HTMLElement).hasAttribute(
            'uiunpermissioned',
          ) &&
          [
            'BTN',
            'CHECK',
            'DATE',
            'DROPDOWN',
            'FILE-UPLOAD',
            'LOCATION',
            'MULTI-DROPDOWN',
            'NUMBER',
            'RADIO-GROUP',
            'SPREADSHEET',
            'TEXT',
          ].includes(this.hostElRef.nativeElement.nodeName)
        );
    }
  });

  //#endregion

  constructor() {
    this.layout = inject(
      this.route.snapshot.data['layoutService'] ?? LAYOUT_SERVICE_TOKEN,
    );
    if (this.layout) {
      effect(() => {
        if (
          this.tag() &&
          this.layout?.selectedView() &&
          this.layout?.permissions() &&
          this.layout?.selectedViewItems()
        ) {
          let control = this.hostElRef.nativeElement?.tagName?.toLowerCase();
          let permission = this.layout?.getPermissionFor(
            control,
            this.tag()!,
          ) as PermissionType;

          this.uipermission.set(permission);
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.tag.set(this.hostElRef.nativeElement?.getAttribute('uitag'));
  }
}
