import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { StaticContentDirective } from '../static-content.directive';
import {
  ButtonComponent,
  CheckComponent,
  DropdownComponent,
  MultiDropdownComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';
import { TagPipe } from '../../pipes/tag.pipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: '[uisectioncontent="Categorization"]',
  imports: [DropdownComponent],
  templateUrl: './categorization.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorizationContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Cross Strategy Tags"]',
  imports: [CommonModule, MultiDropdownComponent, DropdownComponent, TagPipe],
  templateUrl: './cross-strategy-tags.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossStrategyContent extends StaticContentDirective {
  items = computed(() => [
    ...(this.layout
      .selectedViewItems()
      ?.find((item) => item.settings.tag === 'Cross Strategy Tags')?.content ??
      []),
  ]);
}

@Component({
  selector: '[uisectioncontent="Universal Tags"]',
  imports: [
    SectionGroupComponent,
    NumberComponent,
    ButtonComponent,
    TextComponent,
    CheckComponent,
    MultiDropdownComponent,
    DropdownComponent,
    TagPipe,
  ],
  templateUrl: './universal-tags.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversalTagsContent extends StaticContentDirective {
  sectionGroups = computed(() => [
    ...(this.layout
      .selectedViewItems()
      ?.find((item) => item.settings.tag === 'Universal Tags')?.content ?? []),
  ]);
}

export const TaxonomyContents = [
  CategorizationContent,
  CrossStrategyContent,
  UniversalTagsContent,
];
