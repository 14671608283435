<!-- TODO: Check isRemoved with the team if it can be substituted with permissions -->
@if (
  !isRemoved() &&
  (permDir.uipermission() !== PERMISSIONS.NONE || permDir.editPermissions())
) {
  <!-- Section Header -->
  @if (!settings().headerIsHidden) {
    <div
      [class]="headerCssClass()"
      [attr.uisectionheader]="settings().isMinimized ? 'collapsed' : 'expanded'"
      (click)="toggle()"
    >
      <span uisectiontitle [UiCopy]="settings().label!">
        {{ settings().label }}
      </span>
      <ng-content select="[UiIcon]"></ng-content>
      <span uispacer></span>
      <div uitoolstrip (click)="$event.stopPropagation()">
        <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
        <ng-template #uiToolsCodeRef></ng-template>
        @if (settings().isCollapsible) {
          <ng-container #uiToolsDefaultRef>
            <icon
              uitool
              [iconSize]="'normal'"
              [iconDefinition]="
                settings().isMinimized ? ICONS.chevronDown : ICONS.chevronUp
              "
              class="labelInfo copy"
              (click)="toggle()"
            ></icon>
          </ng-container>
        }
        <!-- Option Remove Component -->
        @if (settings().isRemovable) {
          <icon
            uitool
            [iconSize]="'x-large'"
            [iconDefinition]="ICONS.close"
            class="close-icon"
            (click)="close($event)"
          ></icon>
        }
      </div>
    </div>
  }

  <!-- Section Content -->
  @if (!settings().isMinimized) {
    @if (sectionContentComponent()) {
      <div
        uisectioncontent
        [class]="contentCssClass()"
        *ngComponentOutlet="
          sectionContentComponent();
          inputs: sectionContentInput()
        "
      ></div>
    } @else {
      <div uisectioncontent [class]="contentCssClass()">
        <ng-content #UiSectionMarkupContent></ng-content>
        <ng-template #UiSectionCodeContent></ng-template>
        @if (settings().noContentMsg) {
          <div UiSectionEmpty>
            {{ settings().noContentMsg }}
          </div>
        }
      </div>
    }
  }
}
