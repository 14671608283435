<div uipagenavheader>
  <div uipagenavitem>
    <span>Contents</span>
  </div>
</div>
<div uipagenavcontent>
  <div uipagenavitem title="Top" (click)="layout.jumpToAnchor()">
    <span>Top</span>
  </div>
  @if (layout.layoutIsReady()) {
    @for (anchor of layout.anchors(); track $index) {
      @if (anchor.ref.nativeElement.checkVisibility()) {
        <div
          uipagenavitem
          [attr.title]="anchor.tag"
          (click)="layout.jumpToAnchor(anchor.ref)"
        >
          <span>{{ anchor.tag }}</span>
        </div>
      }
    }
  }
</div>
