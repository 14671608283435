@if (permDir.uipermission() !== PERMISSIONS.NONE || permDir.editPermissions()) {
  <!-- Label -->
  @if (label()) {
    <label
      uipopuptrigger
      [attr.uilabelup]="labelUp()"
      [label]="labelFormatted()"
      [labelTip]="labelTip()"
      [state]="state()"
    >
    </label>
  }

  <!-- Input -->
  <div
    readonly
    [class]="inputCssClass()!"
    [ngClass]="{
      'flex-nowrap': settings().shrinkCount,
      'flex-wrap': !settings().shrinkCount,
    }"
    #uiValueRef
    uivalue
    (click)="toggleOptionsPopup(!!settings().optionsAsyncFn)"
  >
    @if (!selectedOption().length && !isTyping()) {
      <span uiplaceholder>{{ this.settings().placeholder }}</span>
    }
    @if (isTyping()) {
      <input
        #uiQueryRef
        type="text"
        [placeholder]="settings().placeholder"
        [class]="inputCssClass()"
        (focusout)="isTyping.set(false)"
        (focusin)="
          settings().clearInputOnFocus ? clearValue($event) : undefined
        "
        (input)="onSearchInputChanged(uiQueryRef.value)"
      />
    } @else {
      @for (
        option of selectedOption().slice(
          0,
          settings().shrinkCount > 0
            ? settings().shrinkCount
            : selectedOption().length
        );
        track $index
      ) {
        <chip
          class="sm"
          [settings]="{ label: option[this.settings().optionLabel] }"
        >
          <icon
            prefix
            [iconSize]="'normal'"
            [iconDefinition]="ICONS.faTimes"
            (click)="select(option); $event.stopImmediatePropagation()"
          ></icon>
        </chip>
      }
    }
  </div>

  <!-- Tools -->
  <div uitoolstrip>
    @if (
      settings().shrinkCount && selectedOption().length > settings().shrinkCount
    ) {
      <button uitool (click)="toggleMoreOptionsPopup()">
        +{{ selectedOption().length - settings().shrinkCount }}
      </button>
    }
    <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
    <ng-template #uiToolsCodeRef></ng-template>
    <ng-container #uiToolsDefaultRef>
      @if (settings().showClearButton && selectedValues().length > 0) {
        <icon
          uihovertool
          uicleartool
          [iconSize]="'normal'"
          [iconDefinition]="ICONS.faTimes"
          (click)="clearValue($event); focusout()"
        ></icon>
      }
      @if (!settings().hideDefaultIcon && !isSearching()) {
        <icon
          uidefaulttool
          [iconSize]="'normal'"
          [iconDefinition]="defaultIcon()"
          (click)="toggleOptionsPopup(); $event.stopPropagation()"
        ></icon>
      } @else if (isSearching()) {
        <icon
          class="animate-spin"
          [iconSize]="'large'"
          [iconDefinition]="settings().loadingIcon"
        ></icon>
      }
    </ng-container>
  </div>

  @if (permDir.uipermission() !== PERMISSIONS.VIEW) {
    <!-- Shrink Rest Popup-->
    <div #uiMoreOptionsToolRef uipopupmore [UiPopup]="popupSettings()">
      @for (
        option of selectedOption().slice(settings().shrinkCount);
        track $index
      ) {
        <chip
          class="sm"
          [settings]="{ label: option[this.settings().optionLabel] }"
        >
          <icon
            prefix
            [iconSize]="'normal'"
            [iconDefinition]="ICONS.faTimes"
            (click)="select(option); $event.stopImmediatePropagation()"
          ></icon>
        </chip>
      }
    </div>

    <!-- Options Popup -->
    <ul
      #uiPopupRef
      [class]="popupCssClass()"
      [UiPopup]="popupSettings()"
      (UiPopupVisibilityChanged)="popupChanged($event)"
    >
      <!-- Select all-->
      @if (settings().allowMultiple && plainOptions().length) {
        <li (click)="selectAll()" uipopupoption>
          <input type="checkbox" readonly [checked]="allValuesAreSelected()" />
          Select All
        </li>
      }

      <!-- Search -->
      @if (settings().optionsFiltering) {
        <text
          #uiSearchRef
          class="w-full"
          [(value)]="filterQuery"
          [settings]="{
            placeholder: 'Start typing to filter',
            placeholderPersists: true,
            tag: settings().label + ' Search',
          }"
          (InputChanged)="onFilterInputChanged($event)"
        >
          <icon
            uitool
            [iconSize]="'normal'"
            [iconDefinition]="ICONS.faSearch"
          ></icon>
        </text>
      }

      <!-- Options -->
      @for (option of visibleOptions(); track $index) {
        <li
          uipopupoption
          [attr.uipopupoptionselected]="
            selectedValues().includes(option?.[settings().optionValue])
          "
          (click)="select(option); $event.stopImmediatePropagation()"
        >
          <input
            class="multi-select-check"
            type="checkbox"
            readonly
            [checked]="
              selectedValues().includes(option?.[settings().optionValue])
            "
          />
          <ng-container
            *ngTemplateOutlet="
              UiOptionTemplateRef || uiOptionTemplateRef;
              context: { $implicit: option, index: $index, isFirst: $first }
            "
          ></ng-container>
        </li>
      } @empty {
        <li UiPopupNonOption>There is no available info to show</li>
      }
    </ul>
  }

  <!-- Option Templates -->
  <ng-template #uiOptionTemplateRef let-option>
    @if (option) {
      {{ option?.[settings().optionLabel] }}
    }
  </ng-template>

  <!-- State -->
  @switch (uiState) {
    @case ('error') {
      <span
        uifooter
        uistate="error"
        class="cursor-help"
        [title]="errors().join('\r\n')"
        >{{
          errors().length > 1
            ? errors().at(0) +
              ' (' +
              (errors().length - 1).toString() +
              ' more)'
            : errors().at(0)
        }}</span
      >
    }
    @case ('warning') {
      <span
        uifooter
        uistate="warning"
        class="cursor-help"
        [title]="warnings().join('\r\n')"
        >{{
          warnings().length > 1
            ? warnings().at(0) +
              ' (' +
              (warnings().length - 1).toString() +
              ' more)'
            : warnings().at(0)
        }}</span
      >
    }
  }

  <!-- Modal Template -->
  <ng-template #uiModalsCodeRef></ng-template>
}
