import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { StaticContentDirective } from '../../static-content.directive';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  IconComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';
import { ENVIRONMENT_TOKEN } from '@aksia/infrastructure';
import { SearchService } from '@aksia/services';

@Component({
  selector: '[uisectioncontent="Assets"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    DateComponent,
    NumberComponent,
    TextComponent,
    CheckComponent,
    ButtonComponent,
    IconComponent,
  ],
  templateUrl: './assets.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetsContent extends StaticContentDirective {
  private readonly env = inject(ENVIRONMENT_TOKEN);
  protected readonly search = inject(SearchService);

  protected visitAsset(id: number) {
    window.open(
      `${this.env.max_url}/Application/Fund/Fund.aspx?dataProviderEntityId=${id}`,
    );
  }
}

@Component({
  selector: '[uisectioncontent="Categorization Factors"]',
  imports: [DropdownComponent, TextComponent],
  templateUrl: './categorization-factors.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategprizationFactorsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Secondary Key Terms"]',
  imports: [DropdownComponent, DateComponent, NumberComponent, TextComponent],
  templateUrl: './key-terms.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyTermsContent extends StaticContentDirective {}

export const InvestmentVehicleSecondaryContents = [
  AssetsContent,
  CategprizationFactorsContent,
  KeyTermsContent,
];
