import {
  AUTHENTICATION_SERVICE_TOKEN,
  ILayoutService,
  IPageLayoutSettings,
  PAGE_LAYOUT,
  PERMISSIONS,
  PermissionType,
  RoleType,
  STORAGE_KEYS,
  TViewItem,
  TViewMap,
  VIEW_MAP_TOKEN,
} from '@aksia/infrastructure';
import {
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  Injectable,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { LocalStorageService } from '../browser/local-storage.service';
import { AssetService } from '../asset/asset.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, map, mergeMap } from 'rxjs';

const applyToCollectionRule = /[#][^d]/g;

type ViewBP<View, ViewCategory> = {
  view: View | '*';
  categories: Array<ViewCategory>;
  tags: Array<string>;
};

type PermissionBP = {
  tag: string;
  applyToCollection?: boolean;
  claims: Array<{
    views?: Array<string>;
    roles: Array<RoleType>;
    categories?: Array<string>;
    rights: PermissionType;
  }>;
};

@Injectable({
  providedIn: 'root',
})
export class LayoutService<
  ViewCategory,
  View,
  ViewItem extends TViewItem<unknown>,
  ViewMap extends TViewMap<ViewCategory, View>,
> implements ILayoutService<ViewCategory, View, ViewItem, ViewMap>
{
  private readonly destroyRef = inject(DestroyRef);
  private readonly router = inject(Router);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly asset = inject(AssetService);
  private readonly viewMapToken = inject(VIEW_MAP_TOKEN) as ViewMap;
  private readonly local = inject(LocalStorageService);
  private readonly auth = inject(AUTHENTICATION_SERVICE_TOKEN);

  //#region Properties

  //Layout
  readonly layoutIsReady = signal(false);
  readonly layoutType = signal(PAGE_LAYOUT.Blank);
  readonly layoutSettings = signal<IPageLayoutSettings>({});

  //Views
  readonly viewCategory = signal<ViewCategory | undefined>(undefined);
  readonly viewMap = signal<ViewMap | undefined>(undefined);
  private readonly availableViews = signal<
    Array<ViewBP<View, ViewCategory>> | undefined
  >(undefined);
  readonly filteredViews = computed<Array<View> | undefined>(() =>
    this.viewCategory()
      ? this.viewMapToken?.find(
          (viewMapItem) => viewMapItem.viewCategory === this.viewCategory(),
        )!.views
      : undefined,
  );
  readonly defaultView = signal<View | undefined>(undefined);
  readonly selectedView = signal<View | undefined>(undefined);
  readonly availableViewItems = signal<Array<ViewItem> | undefined>(undefined);
  readonly selectedViewItems = signal<Array<ViewItem> | undefined>(undefined);

  //Permissions
  readonly permissions = signal<Array<PermissionBP> | undefined>(undefined);
  readonly editPermissionsForControls = signal<string | undefined>(undefined);
  readonly editingPermissionTag = signal<string | undefined>(undefined);
  readonly editingPermission = signal<PermissionBP | undefined>(undefined);
  readonly editingClaims = signal<
    | Array<{
        isCollection?: boolean;
        views?: Array<string>;
        roles: Array<RoleType>;
        rights: PermissionType;
      }>
    | undefined
  >([]);

  //Anchors
  anchorhost!: ElementRef;
  anchors: WritableSignal<Array<{ tag: string; ref: ElementRef }>> = signal([]);

  //#endregion

  //#region Methods

  constructor() {
    this.requestLayoutType();

    effect(() => {
      if (this.asset?.collection()?.size! > 0) {
        this.initLoad();
      }
    });
    effect(() => {
      if (
        this.layoutType() &&
        this.viewCategory() &&
        this.selectedView() &&
        this.availableViews() &&
        this.availableViewItems() &&
        this.permissions()
      ) {
        this.updateItems();
      }
    });
    effect(() => {
      if (this.layoutIsReady() === false) {
        this.clearAnchors();
      }
    });
  }

  initLoad() {
    if (this.asset) {
      this.availableViews.set(
        this.asset.collection()?.get('views') as Array<
          ViewBP<View, ViewCategory>
        >,
      );
      this.availableViewItems.set(
        this.asset.collection()?.get('items') as Array<ViewItem>,
      );
      this.permissions.set(
        this.asset.collection()?.get('permissions') as Array<PermissionBP>,
      );
    }
  }

  //Layout
  private requestLayoutType() {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe(({ layoutType, layoutSettings }) => {
        this.layoutType.set(layoutType);
        this.layoutSettings.set(layoutSettings);
      });
  }

  //Views
  selectView(view: View) {
    this.clearAnchors();
    this.selectedView.set(view);
  }

  setDefaultView(view: View, store = true) {
    this.defaultView.set(view);
    if (store) {
      this.local.set(STORAGE_KEYS.LAYOUT.DEFAULT_VIEW, view);
    }
  }

  downloadView() {
    console.log(this.selectedView());
  }

  private updateItems() {
    if (this.selectedView()) {
      let viewTags = this.availableViews()?.find(
        (view) =>
          (view.view === this.selectedView() || view.view === '*') &&
          view.categories.includes(this.viewCategory()!),
      )?.tags;

      let viewItems: Array<ViewItem> = [];
      viewTags?.forEach((tag) => {
        let item = this.availableViewItems()?.find(
          (item) => item.settings.tag === tag,
        );
        if (item) {
          viewItems.push({ ...item });
        }
      });
      this.selectedViewItems.set(viewItems);
    } else {
      this.selectedViewItems.set([]);
    }
  }

  //Permissions
  getPermissionFor(control: string, tag: string) {
    let claims = this.permissions()?.find((permission) =>
      tag.includes('#') && permission.applyToCollection
        ? permission.tag ===
          `${control}|${tag.replace(applyToCollectionRule, '#')}`
        : permission.tag === `${control}|${tag}`,
    )?.claims;
    let rights = claims?.find(
      (claim) =>
        (claim.views?.includes(this.selectedView() as string) ||
          !claim.views ||
          claim.views?.length === 0) &&
        (claim.categories?.includes(this.viewCategory() as string) ||
          !claim.categories ||
          claim.categories?.length === 0) &&
        claim.roles.some((role) => this.auth.user()?.isInRole?.(role)),
    )?.rights;
    return rights;
  }

  editPermissionFor(control: string, tag: string) {
    this.editingPermissionTag.set(`${control}|${tag}`);
    let permission = this.permissions()?.find((permission) =>
      tag.includes('#') && permission.applyToCollection
        ? permission.tag ===
          this.editingPermissionTag()?.replace(applyToCollectionRule, '#')
        : permission.tag === this.editingPermissionTag(),
    );
    this.editingPermission.set(permission);
  }

  applyClaimToCollection() {
    this.editingPermission.update((permission) => {
      permission!.tag = !permission?.applyToCollection
        ? this.editingPermissionTag()!
        : this.editingPermissionTag()!.replace(applyToCollectionRule, '#');
      return permission;
    });
  }

  addClaim() {
    if (!this.editingPermission()) {
      this.editingPermission.set({
        tag: this.editingPermissionTag()!,
        applyToCollection: false,
        claims: [
          {
            views: [],
            roles: [],
            categories: [],
            rights: PERMISSIONS.WRITE,
          },
        ],
      });
      this.permissions.update((permissions) => {
        permissions!.push(this.editingPermission()!);
        return permissions;
      });
    } else {
      this.editingPermission.update((permission) => {
        permission!.claims = [
          ...(permission!.claims ?? []),
          { roles: [], views: [], rights: PERMISSIONS.WRITE },
        ];

        return permission;
      });
    }
  }

  removeClaim(index: number) {
    this.editingPermission.update((permission) => {
      permission?.claims.splice(index, 1);
      return permission;
    });

    if (this.editingPermission()?.claims.length === 0) {
      this.permissions.update((permissions) => {
        permissions!.splice(
          permissions!.findIndex(
            (permission) => permission.tag === this.editingPermissionTag(),
          ),
          1,
        );
        return permissions;
      });
      this.editingPermission.set(undefined);
    }
  }

  downloadPermissions() {
    const jsonString = JSON.stringify(this.permissions());
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'PERMISSIONS.json';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  //Anchors
  addAnchorhost(host: ElementRef) {
    this.anchorhost = host;
  }

  addAnchor(tag: string, anchor: ElementRef) {
    let anchors = this.anchors();
    anchors.push({ tag, ref: anchor });
    this.anchors.set(anchors);
  }

  clearAnchors() {
    this.anchors.set([]);
  }

  public jumpToAnchor(anchorEl?: ElementRef) {
    if (!anchorEl) {
      this.anchorhost?.nativeElement?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (anchorEl) {
      this.anchorhost?.nativeElement?.scrollTo({
        top:
          anchorEl.nativeElement.offsetTop -
          this.anchorhost?.nativeElement.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  //#endregion
}
