import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../static-content.directive';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  LocationComponent,
  NumberComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Decision Dates"]',
  imports: [DateComponent, DropdownComponent, TextComponent],
  templateUrl: './decision-dates.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DecisionDatesContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Financial Statistics"]',
  imports: [DateComponent, NumberComponent, TextComponent],
  templateUrl: './financial-statistics.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialStatisticsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Co Investment Key Terms"]',
  imports: [DropdownComponent, DateComponent, NumberComponent, TextComponent],
  templateUrl: './key-terms.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyTermsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Real Estate Details"]',
  imports: [DropdownComponent, NumberComponent],
  templateUrl: './real-estate-details.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RealEstateDetailsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Staff Involved"]',
  imports: [DropdownComponent, TextComponent],
  templateUrl: './staff-involved.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffInvolvedContent extends StaticContentDirective {}

export const InvestmentVehicleCoInvestmentContents = [
  DecisionDatesContent,
  FinancialStatisticsContent,
  KeyTermsContent,
  RealEstateDetailsContent,
  StaffInvolvedContent,
];
