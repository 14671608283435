import { SimpleAnswerEnum } from '../../enums/entity.enum';
import {
  CloseDateTypeEnum,
  ContractualDateTypeEnum,
  LiquidityTierEnum,
  MinGPCommitmentEnum,
  RankEnum,
} from '../../enums/closedEnd.enum';
import {
  ContractualFinalCloseExtension,
  ContractualInvestmentPeriodExtension,
  ContractualTermExtension,
  RecyclingTimeLimit,
  RecyclingProceedsLimit,
  LpClawbackPerLimit,
  LpClawbackTimeLimit,
  ClosedEndInterimDate,
} from '../entities/entities.model';
import { ClosedEndGPInformation } from './closed-end-gp-information';
import { ClosedEndGPTargetInvestmentStat } from './closed-end-gp-target-investment-stat';
import { ClosedEndKeyTerm } from './closed-end-key-term';
import { ClosedEndStructure } from './closed-end-structure';
import { ClosedEndTargetReturnProfile } from './closed-end-target-return-profile';
import { AccountingMethodEnum } from '@aksia/enums';

export class ClosedEndDetails {
  id?: number = undefined;
  fundId?: number = undefined;

  //#region <<< Section - Details Date Info >>>

  investmentPeriodExpirationDateType?: CloseDateTypeEnum = undefined;
  investmentPeriodExpirationDate?: Date = undefined;
  termExpirationDateType?: CloseDateTypeEnum = undefined;
  termExpirationDate?: Date = undefined;

  //#endregion

  //#region <<< Section - Fund Timing >>>

  contractualFinalCloseDateType?: ContractualDateTypeEnum = undefined;
  contractualFinalCloseDateDesc?: string = undefined;
  contractualFinalCloseDate?: Date = undefined;
  contractualFinalCloseMonths?: number = undefined;
  contractualFinalCloseUnlimitedExtensions?: boolean = undefined;
  contractualFinalCloseExtensions?: Array<ContractualFinalCloseExtension> =
    undefined;
  contractualInvestmentPeriodExtensions?: Array<ContractualInvestmentPeriodExtension> =
    undefined;
  contractualTermExtensions?: Array<ContractualTermExtension> = undefined;

  //#endregion

  //#region <<< Section - Capital Call Procedure >>>

  followOnInvestmentsPerLimit?: SimpleAnswerEnum = undefined;
  followOnInvestmentsPerLimitRate?: number = undefined;
  followOnInvestmentsTimeLimit?: SimpleAnswerEnum = undefined;
  followOnInvestmentsTimeLimitMonths?: number = undefined;
  investmentsInProgressTimeLimit?: SimpleAnswerEnum = undefined;
  investmentsInProgressTimeLimitMonths?: number = undefined;

  //#endregion

  //#region <<< Section - Organizational Expense Cap >>>

  organizationalExpenseCapType?: MinGPCommitmentEnum = undefined;
  organizationalExpenseCapRate?: number = undefined;
  organizationalExpenseCapAmount?: number = undefined;

  //#endregion

  //#region <<< Section - Recycling >>>

  recyclingTimeLimits?: Array<RecyclingTimeLimit> = undefined;
  recyclingProceedsLimits?: Array<RecyclingProceedsLimit> = undefined;

  //#endregion

  //#region <<< Section - LP Clawback >>>

  lpClawbackPerLimit?: SimpleAnswerEnum = undefined;
  lpClawbackPerLimits?: Array<LpClawbackPerLimit> = undefined;
  lpClawbackTimeLimit?: SimpleAnswerEnum = undefined;
  lpClawbackTimeLimits?: Array<LpClawbackTimeLimit> = undefined;

  //#endregion

  //#region <<< Section - Removal/Termination Rights >>>

  forFaultGpImRemoval?: SimpleAnswerEnum = undefined;
  forFaultGpImVoteThresholdRate?: number = undefined;
  carriedInterestReduction?: SimpleAnswerEnum = undefined;
  carriedInterestReductionRate?: number = undefined;
  forFaultInvPeriodTermination?: SimpleAnswerEnum = undefined;
  forFaultInvPeriodVoteThresholdRate?: number = undefined;
  forFaultFundTermination?: SimpleAnswerEnum = undefined;
  forFaultFundVoteThresholdRate?: number = undefined;
  noFaultGpImRemoval?: SimpleAnswerEnum = undefined;
  noFaultGpImVoteThresholdRate?: number = undefined;
  noFaultGpImTimeLimit?: SimpleAnswerEnum = undefined;
  noFaultGpImPenalty?: SimpleAnswerEnum = undefined;
  noFaultInvPeriodTermination?: SimpleAnswerEnum = undefined;
  noFaultInvPeriodVoteThresholdRate?: number = undefined;
  noFaultInvPeriodTimeLimit?: SimpleAnswerEnum = undefined;
  noFaultInvPeriodPenalty?: SimpleAnswerEnum = undefined;
  noFaultFundTermination?: SimpleAnswerEnum = undefined;
  noFaultFundVoteThresholdRate?: number = undefined;
  noFaultFundTimeLimit?: SimpleAnswerEnum = undefined;
  noFaultFundPenalty?: SimpleAnswerEnum = undefined;
  keyPersonProvision?: SimpleAnswerEnum = undefined;
  automaticSuspensionLift?: SimpleAnswerEnum = undefined;
  keyPersonProVoteThresholdRate?: number = undefined;

  //#endregion

  //#region <<< Section - Leverage >>>

  bridgeFinancingTerm?: number = undefined;
  subscriptionLines?: SimpleAnswerEnum = undefined;

  //#endregion

  //#region <<< Section - Reporting >>>

  accountingMethod?: AccountingMethodEnum;
  accountingMethodDescription?: string;
  quarterlyReportsDeadline?: number;
  annualReportsDeadline?: number;

  //#endregion

  //#region <<< Section - Successor Fund Provision >>>

  successorFundProvision?: SimpleAnswerEnum = undefined;
  successorFundProvisionThresholdRate?: number = undefined;

  //#endregion

  //#region <<< Section - Fund Terms >>>

  interimDates?: Array<ClosedEndInterimDate> = undefined;
  liquidityTier?: LiquidityTierEnum;

  //#endregion

  //#region <<< Section - Return Profile >>>

  medianQuartileRanking?: RankEnum = undefined;
  tvpiNotes?: string = undefined;

  //#endregion

  closedEndKeyTerm: ClosedEndKeyTerm = new ClosedEndKeyTerm();
  closedEndStructure: ClosedEndStructure = new ClosedEndStructure();
  closedEndGPInformation: ClosedEndGPInformation = new ClosedEndGPInformation();
  closedEndGPTargetInvestmentStat: ClosedEndGPTargetInvestmentStat =
    new ClosedEndGPTargetInvestmentStat();
  closedEndTargetReturnProfile: ClosedEndTargetReturnProfile =
    new ClosedEndTargetReturnProfile();
}
