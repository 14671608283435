@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.forFaultGpImRemoval"
    [settings]="{
      label: 'For-Fault GP/IM Removal',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      tag: 'For Fault GP/IM Removal',
    }"
    [validators]="ivStore.forFaultGpImRemoval_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.updateForFaultGpImRemoval($event)"
  ></dropdown>

  @if (iv.closedEndDetails.forFaultGpImRemoval === ENUMS.SimpleAnswerEnum.Yes) {
    <number
      class="c2s1"
      [(value)]="iv.closedEndDetails.forFaultGpImVoteThresholdRate"
      [settings]="{
        label: 'Vote Threshold',
        placeholder: '%',
        suffix: ' %',
        tag: 'For Fault GP/IM Vote Threshold Rate',
      }"
      [validators]="ivStore.forFaultGpImVoteThresholdRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
    <dropdown
      class="c3s1"
      [(value)]="iv.closedEndDetails.carriedInterestReduction"
      [settings]="{
        label: 'Carried Interest Reduction',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'Carried Interest Reduction',
      }"
      [validators]="ivStore.carriedInterestReduction_validators()"
      [validation.id]="iv.$uid"
      (valueChange)="ivStore.updateCarriedInterestReduction($event)"
    ></dropdown>
  }
  @if (
    iv.closedEndDetails.carriedInterestReduction === ENUMS.SimpleAnswerEnum.Yes
  ) {
    <number
      class="c4s1"
      [(value)]="iv.closedEndDetails.carriedInterestReductionRate"
      [settings]="{
        label: 'Reduction Rate',
        placeholder: '%',
        suffix: ' %',
        tag: 'Carried Interest Reduction Rate',
      }"
      [validators]="ivStore.carriedInterestReductionRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.forFaultInvPeriodTermination"
    [settings]="{
      label: 'For-Fault Investment Period Termination',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      tag: 'For Fault Investment Period Termination',
    }"
    [validators]="ivStore.forFaultInvPeriodTermination_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.updateForFaultInvPeriodTermination($event)"
  ></dropdown>

  @if (
    iv.closedEndDetails.forFaultInvPeriodTermination ===
    ENUMS.SimpleAnswerEnum.Yes
  ) {
    <number
      class="c2s1"
      [(value)]="iv.closedEndDetails.forFaultInvPeriodVoteThresholdRate"
      [settings]="{
        label: 'Vote Threshold',
        placeholder: '%',
        suffix: ' %',
        tag: 'For Fault Investment Period Vote Threshold Rate',
      }"
      [validators]="ivStore.forFaultInvPeriodVoteThresholdRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.forFaultFundTermination"
    [settings]="{
      label: 'For-Fault Fund Termination',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      tag: 'For Fault Fund Termination',
    }"
    [validators]="ivStore.forFaultFundTermination_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.updateForFaultFundTermination($event)"
  ></dropdown>

  @if (
    iv.closedEndDetails.forFaultFundTermination === ENUMS.SimpleAnswerEnum.Yes
  ) {
    <number
      class="c2s1"
      [(value)]="iv.closedEndDetails.forFaultFundVoteThresholdRate"
      [settings]="{
        label: 'Vote Threshold',
        placeholder: '%',
        suffix: ' %',
        tag: 'For Fault Fund Vote Threshold Rate',
      }"
      [validators]="ivStore.forFaultFundVoteThresholdRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.noFaultGpImRemoval"
    [settings]="{
      label: 'No-Fault GP/IM Removal',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      tag: 'No Fault GP/IM Removal',
    }"
    [validators]="ivStore.noFaultGpImRemoval_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.updateNoFaultGpImRemoval($event)"
  ></dropdown>

  @if (iv.closedEndDetails.noFaultGpImRemoval === ENUMS.SimpleAnswerEnum.Yes) {
    <number
      class="c2s1"
      [(value)]="iv.closedEndDetails.noFaultGpImVoteThresholdRate"
      [settings]="{
        label: 'Vote Threshold',
        placeholder: '%',
        suffix: ' %',
        tag: 'No Fault GP/IM Vote Threshold Rate',
      }"
      [validators]="ivStore.noFaultGpImVoteThresholdRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
  }
  @if (iv.closedEndDetails.noFaultGpImRemoval === ENUMS.SimpleAnswerEnum.Yes) {
    <dropdown
      class="c3s1"
      [(value)]="iv.closedEndDetails.noFaultGpImTimeLimit"
      [settings]="{
        label: 'Time Limit',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'No Fault GP/IM Time Limit',
      }"
    ></dropdown>
    <dropdown
      class="c4s1"
      [(value)]="iv.closedEndDetails.noFaultGpImPenalty"
      [settings]="{
        label: 'Penalty',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'No Fault GP/IM Penalty',
      }"
    ></dropdown>
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.noFaultInvPeriodTermination"
    [settings]="{
      label: 'No-Fault Investment Period Termination',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      tag: 'No Fault Investment Period Termination',
    }"
    [validators]="ivStore.noFaultInvPeriodTermination_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.updateNoFaultInvPeriodTermination($event)"
  ></dropdown>

  @if (
    iv.closedEndDetails.noFaultInvPeriodTermination ===
    ENUMS.SimpleAnswerEnum.Yes
  ) {
    <number
      class="c2s1"
      [(value)]="iv.closedEndDetails.noFaultInvPeriodVoteThresholdRate"
      [settings]="{
        label: 'Vote Threshold',
        placeholder: '%',
        suffix: ' %',
        tag: 'No Fault Investment Period Vote Threshold Rate',
      }"
      [validators]="ivStore.noFaultInvPeriodVoteThresholdRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
    <dropdown
      class="c3s1"
      [(value)]="iv.closedEndDetails.noFaultInvPeriodTimeLimit"
      [settings]="{
        label: 'Time Limit',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'No Fault Investment Period Time Limit',
      }"
    ></dropdown>
    <dropdown
      class="c4s1"
      [(value)]="iv.closedEndDetails.noFaultInvPeriodPenalty"
      [settings]="{
        label: 'Penalty',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'No Fault Investment Period Penalty',
      }"
    ></dropdown>
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.noFaultFundTermination"
    [settings]="{
      label: 'No-Fault Fund Termination',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      tag: 'No Fault Fund Termination',
    }"
    [validators]="ivStore.noFaultFundTermination_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.updateNoFaultFundTermination($event)"
  ></dropdown>

  @if (
    iv.closedEndDetails.noFaultFundTermination === ENUMS.SimpleAnswerEnum.Yes
  ) {
    <number
      class="c2s1"
      [(value)]="iv.closedEndDetails.noFaultFundVoteThresholdRate"
      [settings]="{
        label: 'Vote Threshold',
        placeholder: '%',
        suffix: ' %',
        tag: 'No Fault Fund Vote Threshold Rate',
      }"
      [validators]="ivStore.noFaultFundVoteThresholdRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
    <dropdown
      class="c3s1"
      [(value)]="iv.closedEndDetails.noFaultFundTimeLimit"
      [settings]="{
        label: 'Time Limit',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'No Fault Fund Time Limit',
      }"
    ></dropdown>
    <dropdown
      class="c4s1"
      [(value)]="iv.closedEndDetails.noFaultFundPenalty"
      [settings]="{
        label: 'Penalty',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'No Fault Fund Penalty',
      }"
    ></dropdown>
  }

  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.keyPersonProvision"
    [settings]="{
      label: 'Key Person Provision',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      tag: 'Key Person Provision',
    }"
    [validators]="ivStore.keyPersonProvision_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.updateKeyPersonProvision($event)"
  ></dropdown>

  @if (iv.closedEndDetails.keyPersonProvision === ENUMS.SimpleAnswerEnum.Yes) {
    <dropdown
      class="c2s2"
      [(value)]="iv.closedEndDetails.automaticSuspensionLift"
      [settings]="{
        label: 'Automatic Suspension Lift',
        labelTip:
          'Select yes if the suspension of the investment period will be lifted unless LPs vote for termination within a specific timeframe.',
        options: ENUMS.SimpleAnswerEnum.toOptions,
        tag: 'Automatic Suspension Lift',
      }"
    ></dropdown>
    <number
      class="c4s1"
      [(value)]="iv.closedEndDetails.keyPersonProVoteThresholdRate"
      [settings]="{
        label: 'Vote Threshold',
        placeholder: '%',
        suffix: ' %',
        labelTip:
          'Reflects the vote threshold required to lift the suspension.',
        tag: 'Key Person Provision Vote Threshold Rate',
      }"
      [validators]="ivStore.keyPersonProVoteThresholdRate_validators()"
      [validation.id]="iv.$uid"
    ></number>
  }
}
