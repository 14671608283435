import { UTILS } from '../functions/utils';

export enum Icons {
  chevronDown = 'faAngleDown',
  chevronUp = 'faAngleUp',
  eye = 'faEye',
  close = 'faTimes',
  note = 'faStickyNote',
  filter = 'faFilter',
  sort = 'faSort',
  sortAZ = 'faSortUp',
  sortZA = 'faSortDown',
  edit = 'faPen',
  add = 'faPlusCircle',
  remove = 'faMinusCircle',
  check = 'faCheck',
  search = 'faSearch',
  loading = 'faSpinner',
  company = 'faBuilding',
  fund = 'faPercentage',
  info = 'faInfoCircle',
  copy = 'faCopy',
  caretLeft = 'faCaretLeft',
  caretRight = 'faCaretRight',
  calendar = 'faCalendar',
  compress = 'faCompressAlt',
  expand = 'faExpandAlt',
  attach = 'faPaperclip',
  upload = 'faUpload',
  download = 'faDownload',
  location = 'faMapMarkerAlt',
  sliders = 'faSlidersH',
  maximize = 'faWindowMaximize',
  minimize = 'faWindowMinimize',
  user = 'faUser',
  password = 'faLock',
  key = 'faKey',
  tag = 'faTag',
  link = 'faExternalLinkAlt',
  sitemap = 'faSitemap',
  filecode = 'faCode',
  permissions = 'faUserLock',
  SECTION = 'faWindowMaximize',
  SECTIONGROUP = 'faObjectGroup',
  GRID = 'faTh',
  SWITCH = 'faProjectDiagram',
  REPEATER = 'faLayerGroup',
  CHECK = 'faToggleOn',
  DATE = 'faCalendar',
  DROPDOWN = 'faAngleDown',
  FILEUPLOAD = 'faUpload',
  LOCATION = 'faMapMarker',
  NUMBER = 'faHashtag',
  RICHTEXT = 'faList',
  SPREADSHEET = 'faTable',
  TEXT = 'faICursor',
  BTN = 'faSquare',
}

export namespace Icons {
  export function toKeyValue() {
    return UTILS.KEYVALUE.enumToKeyValue(Icons);
  }
}
