import {
  EnvironmentInjector,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public viewRef?: ViewContainerRef;
  public injector?: EnvironmentInjector;

  public async openModal(settings: {
    label?: string;
    text?: string;
    hasBackrop?: boolean;
    component?: Type<any>;
    componentInput?: Record<string, unknown>;
    vcRef?: ViewContainerRef;
  }) {
    let modal = (settings.vcRef ?? this.viewRef)?.createComponent(
      ModalComponent,
    );

    if (modal) {
      modal.instance.ModalIsClosed.subscribe(() => {
        modal?.destroy();
      });
      modal.setInput('component', settings.component);
      modal.setInput('componentInput', settings.componentInput);
      modal.instance.open(settings.label, settings.text, settings.hasBackrop);
    }
  }
}
