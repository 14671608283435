
import { 
  Plugin, 
  ContextualBalloon, 
  DropdownView, 
  Collection,
  ButtonView,
  createDropdown,
  addListToDropdown,
  Range,
  TextProxy,
  ViewModel
} from 'ckeditor5';
import { fetchTones, setApiUrl } from './rephrase-api';
import { RephraseModel } from './rephrase.model';

export default class RephrasePlugin extends Plugin {
  static get requires() {
    return [ContextualBalloon];
  }

  tones: any[] = [];
  projectId: number = 0;
  dropdownItems: Collection<Record<string, any>> = new Collection();
  angularContext: any;

  init() {
    const editor = this.editor;
    if(!editor)
      return;
    
    this.angularContext = editor?.config?.get('angularContext') as any;
    setApiUrl(this.angularContext?.envConfig?.url);
    this.projectId = this.angularContext?.envConfig?.projectId;

    // Add the button to the toolbar
    editor.ui.componentFactory.add('rephrase', locale => {
      const dropdownView = createDropdown(locale);
      dropdownView.buttonView.set({
        label: 'AI Tools',
        tooltip: 'Aksia BETA tools',
        withText: true,
      });

      this._addDropdownItems(dropdownView);
      return dropdownView;
    });
  }

  _addDropdownItems(dropdownView: DropdownView) {
    const dropdownItems = new Collection();    
    dropdownItems.add({
      type: 'button',
      model: new ViewModel({
        label: 'Rephrase:',
        withText: true,
        isEnabled: true,
        tooltip: 'Rephrase the text using a tone from the list',
      })
    });

    dropdownItems.add({ type: 'separator' });

    fetchTones().then((tones: any) => {
      this.tones = tones;
      tones.forEach((tone: any) => {
        dropdownItems.add({
          type: 'button',
          model: new ViewModel({
            label: tone.key,
            withText: true,
            tooltip: tone.value,
            originalTooltip: tone.value,
            isEnabled: true,
            class: 'rephrase-dropdown-button'
          })
        });
      });

      dropdownItems.add({ type: 'separator' });

      dropdownItems.add({
          type: 'button',
          model: {
            label: 'Summarize',
            withText: true,
            tooltip: 'Summarize the text',
          }
        });

      this.dropdownItems = dropdownItems;
      addListToDropdown(dropdownView, dropdownItems as any);
      
      this._addOnTextSelectionChange(dropdownView); 
      this._addItemActions(dropdownView);
    });
  }

  _addOnTextSelectionChange(dropdownView: DropdownView) {
    dropdownView.buttonView.on('execute', () => {
      const wordCount = this._getWordCount();
      this.dropdownItems?.forEach((item: any) => {      
        if(item.type !== 'button' || item.model.label === 'Rephrase:' || item.model.label === 'Summarize')
          return;
        
        item.model.isEnabled = wordCount > 300 || wordCount == 0 ? false : true;
        item.model.tooltip = wordCount > 300 ? 'Rephrasing is not supported for over 300 words text' : item.model.originalTooltip;
      });
    });  
  }  

  _addItemActions(dropdownView: DropdownView) {
    dropdownView.on('execute', (eventInfo) => {      
      const { label } = eventInfo.source as any;
      if (label === 'Rephrase:')
        return;

      this._execute(label);
    });
  }

  _getWordCount() {
    const { selectedText } = this._getSelectedText();
    if (!selectedText)
      return 0;

    return selectedText.split(/\s+/).filter(word => word).length;
  }

  _createButton(label: string, icon: string) {
    const button = new ButtonView();
    button.set({
      label: label,
      withText: true,
      icon: icon
    });
    return button;
  }

  _execute(selectedTone: string) {
    const { selectedText, attributes } = this._getSelectedText();

    if (!selectedText)
      return;

    const rephraseObj: RephraseModel = { 
      originalText: selectedText, 
      attributes, 
      tone: selectedTone, 
      toneOptions: this.tones, 
      projectId: this.projectId
    };

    this.angularContext.openRephraseModal(
      rephraseObj, 
      (event: { updatedText: string, attributes: any[] }) => {
        this._insertText(event.updatedText, event.attributes);
      }
    );
  }

  _getSelectedText() {    
    const selection = this.editor.model.document.selection;
    let selectedText = '';
    let attributes: any[] = [];
    const range = selection.getFirstRange();
    if (!range || range.isCollapsed) {
      return { selectedText, attributes };
    }
    
    for (const itm of range.getItems()) {
      const item = itm as TextProxy;
      selectedText += item.data;
      attributes.push(Array.from(item.getAttributes()));
    }
    
    return { selectedText, attributes };
  }

  _insertText(updatedText: string, attributes: any) {
    const editor = this.editor;
    editor.model.change(writer => {
      const range = editor.model.document.selection.getFirstRange() as Range;
      writer.remove(range);
      writer.insertText(updatedText, attributes[0], range.start);
    });
    editor.focus();
  }  
}