import { UTILS } from '../functions/utils';

export const ROLES = {
  AKSIA_EMPLOYEE: 'AksiaEmployee',
  ADMIN: 'O2-Admin',
  LEGAL_DOC: 'O2-LegalDocTeam',
  DATA: 'O2-DataTeam',
  INVESTMENT_RESEARCH: 'O2-InvestmentResearchTeam',
  RISK: 'O2-RiskTeam',
  CLIENT_OPS: 'O2-ClientOps',

  CLIENT_DELIVERABLES: 'Client Deliverables',
  DOCUMENT_MANAGEMENT_ADMIN: 'Document Management Admin',
  OPS_AND_ACCOUNTING: 'Ops & Accounting',
  DOCUMENT_MANAGEMENT_VIEW_ONLY: 'Document Management View Only',
  OPS_FEES: 'Ops Fees',
  OPS_LEGAL: 'Ops Legal',
  RISK_TEAM: 'Risk Team',
  ACTIVITY_PUBLISHER: 'Allow Publishing',
  ACTIVITY_ADMIN: 'Publish Admin',
  CEDRIC_ADMIN: 'IT',
  RESTRICTED_WORDS: 'Restricted Words Admin',

  toKeyValue: UTILS.KEYVALUE.toKeyValue,
  getKeyFromValue: UTILS.KEYVALUE.getKeyFromValue,
} as const;

export type RoleType = (typeof ROLES)[keyof typeof ROLES];

export const PERMISSIONS = {
  NONE: 'None',
  VIEW: 'View',
  WRITE: 'Write',

  toKeyValue: UTILS.KEYVALUE.toKeyValue,
} as const;

export type PermissionType = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];

export const PREFERENCES = {
  DEFAULT_THEME: 'defaultTheme',
  DEFAULT_VIEW: 'defaultView',
  DEFAULT_MINIMIZED_SECTIONS: 'defaultMinimizedSections',
} as const;

export type PreferenceType = (typeof PREFERENCES)[keyof typeof PREFERENCES];
