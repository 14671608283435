import { ENVIRONMENT_TOKEN } from '@aksia/infrastructure';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from '../browser/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  protected environment = inject(ENVIRONMENT_TOKEN);
  protected http = inject(HttpClient);
  protected localStorage = inject(LocalStorageService);
  public cache?: boolean;

  public get(
    route: string,
    additionalParamsMap?: Map<string, any>,
    useCache?: boolean,
  ): Observable<any> {
    let data = useCache ? this.localStorage.get(route) : undefined;
    if (!data) {
      if (useCache) {
        this.http
          .get(
            this.resolveRoute(route, this.environment.service_api_url),
            this.generateOptions(additionalParamsMap),
          )
          .subscribe((dto) => {
            data = dto;
            this.localStorage.set(route, data);
            return of(data);
          });
      } else {
        return this.http.get(
          this.resolveRoute(route, this.environment.service_api_url),
          this.generateOptions(additionalParamsMap),
        );
      }
    } else {
      return of(data);
    }
    return of(undefined);
    //return data;
  }

  public put(route: string, body: any = null): Observable<any> {
    return this.http.put(
      this.resolveRoute(route, this.environment.service_api_url),
      body,
      this.generateOptions(),
    );
  }

  public post(
    route: string,
    body: any = null,
    additionalParamsMap?: Map<string, any>,
  ): Observable<any> {
    return this.http.post(
      this.resolveRoute(route, this.environment.service_api_url),
      body,
      {
        ...this.generateOptions(additionalParamsMap),
      },
    );
  }

  public delete(route: string): Observable<any> {
    return this.http.delete(
      this.resolveRoute(route, this.environment.service_api_url),
      this.generateOptions(),
    );
  }

  private resolveRoute(route: string, envURI: string) {
    return `${envURI}/${route}`;
  }

  private generateOptions(additionalParamsMap?: Map<string, any>) {
    var options: {
      withCredentials?: boolean;
      params?: HttpParams;
      headers?: HttpHeaders;
    } = {};
    options.withCredentials = true;

    const defaultHeaders = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Response-Type': 'text',
    };
    const additionalHeaders = additionalParamsMap
      ? Object.fromEntries(additionalParamsMap)
      : {};

    const headers = { ...defaultHeaders, ...additionalHeaders };

    if (!additionalHeaders['IsFile'])
      options.headers = new HttpHeaders(headers);

    return options;
  }
}
