import { UTILS } from '@aksia/infrastructure';

export const DOMAIN_API_REQUESTS = {
  TAXONOMY: 'Taxonomy',
  TAXONOMY_MAIN: 'Taxonomy Main Hierarchy',
  TAXONOMY_CROSS_STRATEGY_TAGS: 'Taxonomy Cross Strategy Tags',
  TAXONOMY_CROSS_STRATEGY_RULES: 'Taxonomy Cross Strategy Rules',
  TAXONOMY_ASSET_CLASS_SUBSTRATEGY_TAGS:
    'Taxonomy Asset Class Substrategy Tags',
  /* TAXONOMY_ASSET_CLASS_SUBSTRATEGY_RULES:
    'Taxonomy Asset Class Substrategy Rules', */
  TAXONOMY_PRIMARY_REGIONS: 'Taxonomy Primary Regions',
  COMPANY: 'Company Info',
  INVESTMENT_PROGRAM: 'Investment Program Info',
  INVESTMENT_VEHICLE: 'Investment Vehicle Info',
  SHARE_CLASSES: 'Share Class Info',
  PERIODIC_STREAMS_AUM: 'Periodic Streams AUM Info',
  PERIODIC_STREAMS_PRIVATE_RETURNS: 'Periodic Streams Private Returns Info',
  NOTES: 'Notes Info',
  toKeyValue: UTILS.KEYVALUE.toKeyValue,
} as const;

export type DomainApiRequestType =
  (typeof DOMAIN_API_REQUESTS)[keyof typeof DOMAIN_API_REQUESTS];

export const DOMAINS = {
  COMPANY: {
    URI: 'basicdata/managementcompany/',
  },
  TAXONOMY_MAIN: {
    URI: 'taxonomy/sector/all',
  },
  TAXONOMY_CROSS_STRATEGY_TAGS: {
    URI: 'taxonomy/crossstrategytag/all',
  },
  TAXONOMY_CROSS_STRATEGY_RULES: {
    URI: 'basicdata/fund/crossstrategytagrule/all',
  },
  TAXONOMY_ASSET_CLASS_SUBSTRATEGY_TAGS: {
    URI: 'taxonomy/assetclasssubstrategy/all',
  },
  TAXONOMY_PRIMARY_REGIONS: {
    URI: 'taxonomy/primaryRegion/all',
  },
  INVESTMENT_VEHICLE: {
    URI: 'basicdata/fund/details/',
  },
  SHARECLASSES: {
    URI: 'shareclass/fund/',
  },
} as const;

export type DomainType = (typeof DOMAINS)[keyof typeof DOMAINS];
