import {
  bootstrapArrowRepeat,
  bootstrapChevronDown,
  bootstrapChevronUp,
  bootstrapChevronRight,
  bootstrapChevronLeft,
  bootstrapEye,
  bootstrapX,
  bootstrapSticky,
  bootstrapFilter,
  bootstrapArrowDownUp,
  bootstrapArrowDown,
  bootstrapArrowUp,
  bootstrapPencil,
  bootstrapPlusCircle,
  bootstrapPlusLg,
  bootstrapDashCircle,
  bootstrapDashLg,
  bootstrapCheck2,
  bootstrapSearch,
  bootstrapCloud,
  bootstrapPercent,
  bootstrapInfoCircle,
  bootstrapClipboardCheck,
  bootstrapCaretLeft,
  bootstrapCaretRight,
  bootstrapCalendar,
  bootstrapCalendarDay,
  bootstrapCalendarMonth,
  bootstrapCalendarDate,
  bootstrapPhone,
  bootstrapFileEarmarkText,
  bootstrapArrowsAngleContract,
  bootstrapArrowsAngleExpand,
  bootstrapPaperclip,
  bootstrapUpload,
  bootstrapDownload,
  bootstrapSliders2,
  bootstrapArrowsExpand,
  bootstrapArrowsCollapse,
  bootstrapPersonCircle,
  bootstrapLock,
  bootstrapKey,
  bootstrapTag,
  bootstrapLink45deg,
  bootstrapDiagram3,
  bootstrapCode,
  bootstrapStarFill,
  bootstrapStar,
  bootstrapLayers,
  bootstrapWindow,
  bootstrapToggleOn,
  bootstrapTextareaResize,
  bootstrapMenuButtonWideFill,
  bootstrapGrid1x2Fill,
  bootstrapGeoAltFill,
  bootstrapFileSpreadsheetFill,
  bootstrapBuildingsFill,
  bootstrapCardHeading,
  bootstrapHash,
  bootstrapSignpostSplit,
  bootstrapListNested,
  bootstrapSquareFill,
  bootstrapCaretDown,
  bootstrapList,
  bootstrapGear,
  bootstrapHouse,
} from '@ng-icons/bootstrap-icons';

import {
  faSolidUser,
  faSolidBars,
  faSolidXmark,
  faSolidCircleInfo,
  faSolidMagnifyingGlass,
  faSolidFilter,
  faSolidTableColumns,
  faSolidFileExcel,
  faSolidChevronDown,
  faSolidCirclePlus,
  faSolidPlus,
  faSolidCheck,
  faSolidCalendar,
  faSolidTriangleExclamation,
  faSolidCircleXmark,
  faSolidCircleCheck,
  faSolidCircleUser,
  faSolidChevronLeft,
  faSolidChevronRight,
  faSolidPen,
  faSolidRepeat,
  faSolidRotateRight,
  faSolidStarOfLife,
  faSolidTrashCan,
  faSolidFileCircleXmark,
  faSolidEllipsis,
  faSolidBezierCurve,
  faSolidDollarSign,
  faSolidChartLine,
  faSolidAddressCard,
  faSolidFolderOpen,
  faSolidTicket,
  faSolidEyeSlash,
  faSolidCircleQuestion,
  faSolidInfinity,
  faSolidArrowUp,
  faSolidGear,
  faSolidBell,
  faSolidSpinner,
  faSolidBuilding,
  faSolidShareFromSquare,
  faSolidLink,
  faSolidNoteSticky,
  faSolidCopy,
  faSolidArrowRightLong,
  faSolidEye,
  faSolidDownload,
  faSolidThumbsUp,
  faSolidThumbsDown,
  faSolidUserLock,
} from '@ng-icons/font-awesome/solid';

export const ICONS = {
  arrowRepeat: 'bootstrapArrowRepeat',
  chevronDown: 'bootstrapChevronDown',
  chevronUp: 'bootstrapChevronUp',
  chevronRight: 'bootstrapChevronRight',
  chevronLeft: 'bootstrapChevronLeft',
  eye: 'bootstrapEye',
  close: 'bootstrapX',
  note: 'bootstrapSticky',
  filter: 'bootstrapFilter',
  sort: 'bootstrapArrowDownUp',
  sortAZ: 'bootstrapArrowUp',
  sortZA: 'bootstrapArrowDown',
  edit: 'bootstrapPencil',
  add: 'bootstrapPlusCircle',
  addSimple: 'bootstrapPlusLg',
  remove: 'bootstrapDashCircle',
  removeSimple: 'bootstrapDashLg',
  check: 'bootstrapCheck2',
  search: 'bootstrapSearch',
  loading: 'bootstrapCloud',
  company: 'bootstrapBuildingsFill',
  fund: 'bootstrapPercent',
  info: 'bootstrapInfoCircle',
  copy: 'bootstrapClipboardCheck',
  caretDown: 'bootstrapCaretDown',
  caretLeft: 'bootstrapCaretLeft',
  caretRight: 'bootstrapCaretRight',
  calendar: 'bootstrapCalendar',
  calendarDay: 'bootstrapCalendarDay',
  calendarMonth: 'bootstrapCalendarMonth',
  calendarYear: 'bootstrapCalendarDate',
  phone: 'bootstrapPhone',
  fax: 'bootstrapFileEarmarkText',
  home: 'bootstrapHouse',
  compress: 'bootstrapArrowsAngleContract',
  expand: 'bootstrapArrowsAngleExpand',
  attach: 'bootstrapPaperclip',
  upload: 'bootstrapUpload',
  download: 'bootstrapDownload',
  location: 'bootstrapGeoAltFill',
  sliders: 'bootstrapSliders2',
  maximize: 'bootstrapArrowsExpand',
  minimize: 'bootstrapArrowsCollapse',
  user: 'bootstrapPersonCircle',
  password: 'bootstrapLock',
  key: 'bootstrapKey',
  tag: 'bootstrapTag',
  link: 'bootstrapLink45deg',
  sitemap: 'bootstrapDiagram3',
  filecode: 'bootstrapCode',
  defaultOn: 'bootstrapStarFill',
  defaultOff: 'bootstrapStar',
  permissions: 'faSolidUserLock',
  SECTION: 'bootstrapWindow',
  SECTIONGROUP: 'bootstrapCardHeading',
  GRID: 'bootstrapGrid1x2Fill',
  SWITCH: 'bootstrapSignpostSplit',
  REPEATER: 'bootstrapLayers',
  CHECK: 'bootstrapToggleOn',
  DATE: 'bootstrapCalendar',
  DROPDOWN: 'bootstrapMenuButtonWideFill',
  FILEUPLOAD: 'bootstrapUpload',
  LOCATION: 'bootstrapGeoAltFill',
  NUMBER: 'bootstrapHash',
  RICHTEXT: 'bootstrapListNested',
  SPREADSHEET: 'bootstrapFileSpreadsheetFill',
  TEXT: 'bootstrapTextareaResize',
  BTN: 'bootstrapSquareFill',
  LIST: 'bootstrapList',
  GEAR: 'bootstrapGear',
  faUser: 'faSolidUser',
  faBars: 'faSolidBars',
  faTimes: 'faSolidXmark',
  faCircleInfo: 'faSolidCircleInfo',
  faTriangleExclamation: 'faSolidTriangleExclamation',
  faCircleTimes: 'faSolidCircleXmark',
  faCircleCheck: 'faSolidCircleCheck',
  faSearch: 'faSolidMagnifyingGlass',
  faFilter: 'faSolidFilter',
  faTableColumns: 'faSolidTableColumns',
  faExcel: 'faSolidFileExcel',
  faChevronDown: 'faSolidChevronDown',
  faCirclePlus: 'faSolidCirclePlus',
  faPlus: 'faSolidPlus',
  faCheck: 'faSolidCheck',
  faCalendar: 'faSolidCalendar',
  faChevronLeft: 'faSolidChevronLeft',
  faChevronRight: 'faSolidChevronRight',
  faPen: 'faSolidPen',
  faTrashCan: 'faSolidTrashCan',
  faRepeat: 'faSolidRepeat',
  faRotateRight: 'faSolidRotateRight',
  faStarOfLife: 'faSolidStarOfLife',
  faSolidFileCircleXmark: 'faSolidFileCircleXmark',
  faSolidEllipsis: 'faSolidEllipsis',
  faSolidBezierCurve: 'faSolidBezierCurve',
  faSolidDollarSign: 'faSolidDollarSign',
  faSolidChartLine: 'faSolidChartLine',
  faSolidAddressCard: 'faSolidAddressCard',
  faSolidFolderOpen: 'faSolidFolderOpen',
  faSolidTicket: 'faSolidTicket',
  faSolidEyeSlash: 'faSolidEyeSlash',
  faSolidCircleQuestion: 'faSolidCircleQuestion',
  faSolidInfinity: 'faSolidInfinity',
  faSolidArrowUp: 'faSolidArrowUp',
  faSolidCircleUser: 'faSolidCircleUser',
  faSolidGear: 'faSolidGear',
  faSolidBell: 'faSolidBell',
  faSolidSpinner: 'faSolidSpinner',
  faSolidBuilding: 'faSolidBuilding',
  faSolidShareFromSquare: 'faSolidShareFromSquare',
  faSolidLink: 'faSolidLink',
  faSolidNoteSticky: 'faSolidNoteSticky',
  faCopy: 'faSolidCopy',
  faSolidArrowRightLong: 'faSolidArrowRightLong',
  faSolidEye: 'faSolidEye',
  faSolidDownload: 'faSolidDownload',
  faSolidThumbsUp: 'faSolidThumbsUp',
  faSolidThumbsDown: 'faSolidThumbsDown',
} as const;

export type IconType = (typeof ICONS)[keyof typeof ICONS];

export const PROVIDED_ICONS = {
  bootstrapArrowRepeat,
  bootstrapChevronDown,
  bootstrapChevronUp,
  bootstrapChevronRight,
  bootstrapChevronLeft,
  bootstrapEye,
  bootstrapX,
  bootstrapSticky,
  bootstrapFilter,
  bootstrapArrowDownUp,
  bootstrapArrowDown,
  bootstrapArrowUp,
  bootstrapPencil,
  bootstrapPlusCircle,
  bootstrapPlusLg,
  bootstrapDashCircle,
  bootstrapDashLg,
  bootstrapCheck2,
  bootstrapSearch,
  bootstrapCloud,
  bootstrapPercent,
  bootstrapInfoCircle,
  bootstrapClipboardCheck,
  bootstrapCaretDown,
  bootstrapCaretLeft,
  bootstrapCaretRight,
  bootstrapCalendar,
  bootstrapCalendarDay,
  bootstrapCalendarMonth,
  bootstrapCalendarDate,
  bootstrapPhone,
  bootstrapHouse,
  bootstrapFileEarmarkText,
  bootstrapArrowsAngleContract,
  bootstrapArrowsAngleExpand,
  bootstrapPaperclip,
  bootstrapUpload,
  bootstrapDownload,
  bootstrapSliders2,
  bootstrapArrowsExpand,
  bootstrapArrowsCollapse,
  bootstrapPersonCircle,
  bootstrapLock,
  bootstrapKey,
  bootstrapTag,
  bootstrapLink45deg,
  bootstrapDiagram3,
  bootstrapCode,
  bootstrapStarFill,
  bootstrapStar,
  bootstrapLayers,
  bootstrapWindow,
  bootstrapToggleOn,
  bootstrapTextareaResize,
  bootstrapMenuButtonWideFill,
  bootstrapGrid1x2Fill,
  bootstrapGeoAltFill,
  bootstrapFileSpreadsheetFill,
  bootstrapBuildingsFill,
  bootstrapCardHeading,
  bootstrapHash,
  bootstrapSignpostSplit,
  bootstrapListNested,
  bootstrapSquareFill,
  bootstrapList,
  bootstrapGear,
  // FONT AWESOME
  faSolidUser,
  faSolidBars,
  faSolidXmark,
  faSolidCircleInfo,
  faSolidTriangleExclamation,
  faSolidCircleXmark,
  faSolidCircleCheck,
  faSolidMagnifyingGlass,
  faSolidFilter,
  faSolidTableColumns,
  faSolidFileExcel,
  faSolidChevronDown,
  faSolidCirclePlus,
  faSolidCircleUser,
  faSolidPlus,
  faSolidCheck,
  faSolidCalendar,
  faSolidChevronRight,
  faSolidChevronLeft,
  faSolidPen,
  faSolidRepeat,
  faSolidRotateRight,
  faSolidStarOfLife,
  faSolidTrashCan,
  faSolidFileCircleXmark,
  faSolidEllipsis,
  faSolidBezierCurve,
  faSolidDollarSign,
  faSolidChartLine,
  faSolidAddressCard,
  faSolidFolderOpen,
  faSolidTicket,
  faSolidEyeSlash,
  faSolidCircleQuestion,
  faSolidInfinity,
  faSolidArrowUp,
  faSolidGear,
  faSolidBell,
  faSolidSpinner,
  faSolidBuilding,
  faSolidShareFromSquare,
  faSolidLink,
  faSolidNoteSticky,
  faSolidCopy,
  faSolidArrowRightLong,
  faSolidEye,
  faSolidDownload,
  faSolidThumbsUp,
  faSolidThumbsDown,
  faSolidUserLock,
} as const;
