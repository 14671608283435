import { Directive, effect, ElementRef, inject, input } from '@angular/core';
import { LAYOUT_SERVICE_TOKEN } from '@aksia/infrastructure';

@Directive({
  selector: '[uianchorhost]',
  standalone: true,
})
export class UiAnchorhostDirective {
  //#region Injections

  protected readonly layout = inject(LAYOUT_SERVICE_TOKEN);
  private readonly elementRef = inject(ElementRef);

  //#endregion

  constructor() {
    this.layout.addAnchorhost(this.elementRef);
  }
}
