import {
  ChangeDetectionStrategy,
  Component,
  signal,
  viewChild,
} from '@angular/core';
import { StaticContentDirective } from '../../static-content.directive';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  ModalComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Internal Notes"]',
  imports: [TextComponent],
  templateUrl: './internal-notes.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InternalNotesContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Overview"]',
  imports: [TextComponent],
  templateUrl: './overview.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Aksia Perspective"]',
  imports: [TextComponent, DateComponent],
  templateUrl: './aksia-perspective.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AksiaPerspectiveContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Shareclasses"]',
  imports: [
    DropdownComponent,
    TextComponent,
    ModalComponent,
    CheckComponent,
    ButtonComponent,
  ],
  templateUrl: './shareclasses.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareclassesContent extends StaticContentDirective {
  addShareClassModal = viewChild<ModalComponent>('addShareClassModal');
  renameShareClassModal = viewChild<ModalComponent>('renameShareClassModal');
  protected newShareClassName = signal<string | undefined>(undefined);

  protected addShareClass(event: MouseEvent) {
    this.newShareClassName.set(undefined);
    this.addShareClassModal()?.open();
    event.stopImmediatePropagation();
  }

  protected renameShareClass(event: MouseEvent) {
    this.newShareClassName.set(this.ivStore.selectedClass()?.className);
    this.renameShareClassModal()?.open();
    event.stopImmediatePropagation();
  }
}

@Component({
  selector: '[uisectioncontent="Leverage"]',
  imports: [
    DropdownComponent,
    NumberComponent,
    TextComponent,
    SectionGroupComponent,
    ButtonComponent,
  ],
  templateUrl: './leverage.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeverageContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Pacing"]',
  imports: [TextComponent, NumberComponent],
  templateUrl: './pacing.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PacingContent extends StaticContentDirective {}

export const InvestmentVehicleCommonContents = [
  InternalNotesContent,
  OverviewContent,
  AksiaPerspectiveContent,
  ShareclassesContent,
  LeverageContent,
  PacingContent,
];
