@if (permDir.uipermission() !== PERMISSIONS.NONE || permDir.editPermissions()) {
  @if (label()) {
    <label
      [attr.uilabelup]="labelUp()"
      [label]="labelFormatted()"
      [labelTip]="labelTip()"
      [state]="state()"
      (click)="focusin()"
    >
    </label>
  }

  @if (multiline()) {
    <textarea
      [attr.class]="inputCssClass()"
      [ngClass]="autoCollapse ? 'collapsed' : 'expanded'"
      #uiValueRef
      uivalue
      [value]="formattedValue()"
      [placeholder]="placeholder()"
      (focus)="focusin()"
      (blur)="focusout()"
      (keyup)="emitInput(uiValueRef.value)"
      (keyup.enter)="uiValueRef.blur()"
    ></textarea>
  } @else {
    <input
      [class]="inputCssClass()"
      #uiValueRef
      uivalue
      [value]="formattedValue()"
      [title]="type() !== 'password' ? formattedValue() : ''"
      [type]="type()"
      [attr.placeholder]="placeholder()"
      (focus)="focusin()"
      (blur)="focusout()"
      (keyup)="emitInput(uiValueRef.value)"
      (keyup.enter)="uiValueRef.blur()"
    />
  }

  @if (!settings().disabled) {
    <div uitoolstrip>
      <ng-container #uiToolsDefaultRef>
        @if (!isNil(value())) {
          <icon
            uihovertool
            uicleartool
            [iconSize]="'normal'"
            [iconDefinition]="ICONS.faTimes"
            (click)="clearValue($event)"
          ></icon>
        }
        @if (multiline()) {
          <icon
            uihovertool
            [iconSize]="'normal'"
            [iconDefinition]="!autoCollapse ? ICONS.compress : ICONS.expand"
            [attr.title]="
              autoCollapse ? 'Turn auto collapse off' : 'Turn auto collapse on'
            "
            (click)="toggleAutoCollapse()"
          ></icon>
        }
      </ng-container>
      <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
      <ng-template #uiToolsCodeRef></ng-template>
    </div>
  }

  <!-- State -->
  @switch (uiState) {
    @case ('error') {
      <span
        uifooter
        uistate="error"
        class="cursor-help"
        [title]="errors().join('\r\n')"
        >{{
          errors().length > 1
            ? errors().at(0) +
              ' (' +
              (errors().length - 1).toString() +
              ' more)'
            : errors().at(0)
        }}</span
      >
    }
    @case ('warning') {
      <span
        uifooter
        uistate="warning"
        class="cursor-help"
        [title]="warnings().join('\r\n')"
        >{{
          warnings().length > 1
            ? warnings().at(0) +
              ' (' +
              (warnings().length - 1).toString() +
              ' more)'
            : warnings().at(0)
        }}</span
      >
    }
    @default {
      @if (showCharCount() && !isNil(value())) {
        <span uifooter>characters: {{ valueLength() }}</span>
      }
    }
  }
}
