import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../static-content.directive';
import { DateComponent, DropdownComponent, TextComponent } from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Date Info"]',
  imports: [DropdownComponent, TextComponent, DateComponent],
  templateUrl: './date-info.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInfoContent extends StaticContentDirective {}

export const InvestmentVehicleOpenEndContents = [DateInfoContent];
