import {
  patchState,
  signalStoreFeature,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';

import { PropExtend } from '@aksia/infrastructure';
import { computed } from '@angular/core';

type AsyncState = {
  loadSequencially?: boolean;
  registeredArguments: Array<PropExtend<unknown>>;
  registeredMethods: Map<number, Function>;
  lastFinalizedMethod?: number;
};

export function withAsync<AsyncFeature>() {
  return signalStoreFeature(
    withState<AsyncState>({
      loadSequencially: true,
      registeredArguments: [],
      registeredMethods: new Map(),
      lastFinalizedMethod: undefined,
    }),
    withComputed((store) => ({
      allMethodsAreFinalized: computed(
        () =>
          store.lastFinalizedMethod?.() === store.registeredMethods().size - 1,
      ),
    })),
    withMethods((store) => {
      return {
        registerMethod(method: Function, priority?: number) {
          let realPriority = priority ?? store.registeredMethods().size;
          let registeredMethodsValue = store.registeredMethods();
          registeredMethodsValue.set(realPriority, method);

          patchState(store, { registeredMethods: registeredMethodsValue });
        },
        finalizeMethod(priority: number) {
          patchState(store, { lastFinalizedMethod: priority });
          let nextPriority = priority + 1;
          if (store.loadSequencially) {
            let method = store.registeredMethods().get(nextPriority);
            if (method) {
              method(store.registeredArguments()?.at(nextPriority));
            }
          }
        },
        loadAllMethods() {
          patchState(store, { lastFinalizedMethod: 0 });
          if (store.loadSequencially) {
            let firstMethod = store.registeredMethods().get(0);
            if (firstMethod) {
              firstMethod();
            }
          } else {
            store.registeredMethods().forEach((method) => {
              method();
            });
          }
        },
      };
    }),
  );
}
