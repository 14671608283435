import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../static-content.directive';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  LabelComponent,
  LocationComponent,
  MultiDropdownComponent,
  NumberComponent,
  SectionComponent,
  SpreadsheetComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Historical Info"]',
  imports: [TextComponent, CheckComponent, LocationComponent],
  templateUrl: './historical-info.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoricalInfoContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Returns"]',
  imports: [
    SpreadsheetComponent,
    ButtonComponent,
    DateComponent,
    DropdownComponent,
  ],
  templateUrl: './returns.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="AUM"]',
  imports: [
    SpreadsheetComponent,
    ButtonComponent,
    DropdownComponent,
    CheckComponent,
  ],
  templateUrl: './aum.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AumContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Audit"]',
  imports: [DropdownComponent, DateComponent, TextComponent],
  templateUrl: './audit.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Reporting"]',
  imports: [TextComponent, DropdownComponent, NumberComponent, CheckComponent],
  templateUrl: './reporting.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Identification"]',
  imports: [NumberComponent],
  templateUrl: './identification.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentificationContent extends StaticContentDirective {}

@Component({
  selector: '[uimodalcontent="Permissions"]',
  imports: [
    MultiDropdownComponent,
    DropdownComponent,
    CheckComponent,
    LabelComponent,
    ButtonComponent,
  ],
  templateUrl: './permissions.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsContent extends StaticContentDirective {}

export const CommonContents = [
  HistoricalInfoContent,
  ReturnsContent,
  AumContent,
  AuditContent,
  ReportingContent,
  IdentificationContent,
];
