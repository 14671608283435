@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  @if (iv.liquidityStructure === ENUMS.LiquidityStructureEnum.ClosedEnd) {
    <dropdown
      class="c1s1"
      [(value)]="iv.closedEndDetails.accountingMethod"
      [settings]="{
        label: 'Accounting Method',
        options: ENUMS.AccountingMethodEnum.toOptions,
        tag: 'Accounting Method',
      }"
      (valueChange)="ivStore.updateAccountingMethod($event)"
    ></dropdown>

    @if (
      iv.closedEndDetails.accountingMethod === ENUMS.AccountingMethodEnum.Other
    ) {
      <text
        class="c2s2"
        [(value)]="iv.closedEndDetails.accountingMethodDescription"
        [settings]="{
          label: 'Accounting Method Description',
          placeholder: 'Description',
          tag: 'Accounting Method Description',
        }"
      >
      </text>
    }

    <number
      class="c1s1"
      [(value)]="iv.closedEndDetails.quarterlyReportsDeadline"
      [settings]="{
        label: 'Deadline for Quarterly Reports',
        placeholder: '# days from quarter-end',
        suffix: ' days from quarter-end',
        tag: 'Quarterly Reports Deadline',
      }"
      [validators]="ivStore.quarterlyReportsDeadline_validators()"
      [validation.id]="iv.$uid"
    ></number>
    <number
      class="c1s1"
      [(value)]="iv.closedEndDetails.annualReportsDeadline"
      [settings]="{
        label: 'Deadline for Annual Reports',
        placeholder: '# days from quarter-end',
        suffix: ' days from quarter-end',
        tag: 'Annual Reports Deadline',
      }"
      [validators]="ivStore.annualReportsDeadline_validators()"
      [validation.id]="iv.$uid"
    ></number>
    <text
      class="c1s1"
      [(value)]="iv.logSource"
      [settings]="{
        label: 'Log Source',
        labelTip: 'Indicates whether the fund is sourced from Manager Portal.',
        tag: 'Log Source',
      }"
    ></text>
  } @else {
    <number
      class="c1s1"
      [(value)]="iv.avgReportingReturns"
      [settings]="{
        label: 'Avg Reporting - Fund Returns',
        labelTip:
          'The average number of days after the end of the current month by which the fund\'s final return is typically available.',
        tag: 'Avg Reporting - Fund Returns',
      }"
    >
    </number>
    <number
      class="c2s1"
      [(value)]="iv.avgReportingFundAUM"
      [settings]="{
        label: 'Avg Reporting - Fund AUM (days)',
        labelTip:
          'The average number of days after the end of the current month by which the feeder-fund level AUM is typically available.',
        tag: 'Avg Reporting - Fund AUM',
      }"
    >
    </number>
    <number
      class="c3s1"
      [(value)]="iv.avgReportingExposures"
      [settings]="{
        label: 'Avg Reporting - Exposures (days)',
        labelTip:
          'The average number of days after the end of the current month by which the exposures data is typically available.',
        tag: 'Avg Reporting - Exposures',
      }"
    >
    </number>
    <number
      class="c1s1"
      [(value)]="iv.avgReportingIntramonthReturns"
      [settings]="{
        label: 'Avg Reporting - Intra-month returns (days)',
        labelTip:
          'The average number of days after the end of the current week or mid-month by which the fund\'s weekly or mid-month return is typically available.',
        tag: 'Avg Reporting - Intra-month returns',
      }"
    >
    </number>
    <check
      class="c2s1"
      [(value)]="iv.firstReportingDelay"
      [settings]="{
        label: 'First Reporting Delay',
        labelTip:
          'Indicates whether there is a lag between the fund\'s inception date and the first reported performance data point.',
        tag: 'First Reporting Delay',
      }"
    ></check>

    @if (iv.firstReportingDelay) {
      <number
        class="c3s1"
        [(value)]="iv.firstReportingDelayTiming"
        [settings]="{
          label: 'First Reporting Delay Timing (months)',
          tag: 'First Reporting Delay Timing',
        }"
      >
      </number>
    }

    <dropdown
      class="c1s1"
      [(value)]="iv.fundReturnSource"
      [settings]="{
        label: 'Fund Return Source',
        labelTip:
          'The source from which the data team acquires the monthly fund return.',
        options: ENUMS.ReportingSourceEnum.toOptions,
        optionsFiltering: false,
        tag: 'Fund Return Source',
      }"
    ></dropdown>
    <dropdown
      class="c2s1"
      [(value)]="iv.fundAUMSource"
      [settings]="{
        label: 'Fund AUM Source',
        labelTip:
          'The source from which the data team acquires the monthly fund AUM.',
        options: ENUMS.ReportingSourceEnum.toOptions,
        optionsFiltering: false,
        tag: 'Fund AUM Source',
      }"
    ></dropdown>

    <text
      class="c3s1"
      [(value)]="iv.logSource"
      [settings]="{
        label: 'Log Source',
        labelTip: 'Indicates whether the fund is sourced from Manager Portal.',
        tag: 'Log Source',
      }"
    >
    </text>
    <text
      class="c1s3"
      [(value)]="iv.reportingNotes"
      [settings]="{
        label: 'Reporting Notes',
        labelTip:
          'Data team\'s notes relevant to the fund\'s performance data reporting.',
        multiline: true,
        tag: 'Reporting Notes',
      }"
    >
    </text>
  }
}
