import { ClosedEndDetails } from './closed-end-details';
import { Leverage, MasterFund } from '../entities/entities.model';
import { CoInvestmentDetails } from './co-investment-details';
import { SecondaryDetails } from './secondary-details';
import {
  LiquidityStructureEnum,
  VEntityTypeEnum,
  FundStructureEnum,
  SimpleAnswerEnum,
  CloseDateTypeEnum,
  CurrencyEnum,
  ProgramStatusEnum,
  AssetClassEnum,
  FundRaisingStatusEnum,
  HedgeFundStructureEnum,
  ReportingSourceEnum,
  ThirdPartyProviderEnum,
  DataSourceEnum,
  AssetTypeEnum,
} from '../../enums';
import { ITagDTO } from '../../interfaces/taxonomy/taxonomy.interfaces';
import { StoreModel, UTILS } from '@aksia/infrastructure';

export class InvestmentVehicle extends StoreModel {
  /**
   * Retrieves the start date of an investment vehicle based on its liquidity structure and various date properties.
   *
   * @param investmentVehicle - The investment vehicle object containing various date properties.
   * @returns The start date of the investment vehicle, or `undefined` if no valid date is found.
   *
   * The function determines the start date as follows:
   * - If the liquidity structure is `OpenEnd`, it returns the `commencementOfOperations` date.
   * - Otherwise, it considers multiple date properties (`firstMgmtFeeAccrualDate`, `firstDrawdownDueDate`, `firstInvestmentDate`, `commencementOfOperations`)
   *   and returns the earliest valid date among them.
   * - If no valid date is found, it falls back to other properties (`vintageYearManager`, `vintageYearThirdParty`, `firstCloseDate`, `finalCloseDate`, `dateOfFormation`)
   *   to determine the start date.
   */
  static readonly getStartDate = (
    investmentVehicle: InvestmentVehicle,
  ): Date | undefined => {
    let streamStartDate;
    if (
      investmentVehicle.liquidityStructure === LiquidityStructureEnum.OpenEnd
    ) {
      streamStartDate = UTILS.DATE.toDate(
        investmentVehicle.commencementOfOperations,
      );
    } else {
      const dates = [
        UTILS.DATE.toDate(investmentVehicle.firstMgmtFeeAccrualDate),
        UTILS.DATE.toDate(investmentVehicle.firstDrawdownDueDate),
        UTILS.DATE.toDate(investmentVehicle.firstInvestmentDate),
        UTILS.DATE.toDate(investmentVehicle.commencementOfOperations),
      ].filter((date) => date);

      if (dates.length > 0) {
        streamStartDate = dates.reduce(
          (a, b) => (a!.getTime() < b!.getTime() ? a : b),
          new Date(),
        );
      }

      if (UTILS.DATE.isDate(streamStartDate)) {
        return streamStartDate;
      }

      streamStartDate = investmentVehicle.vintageYearManager
        ? new Date(investmentVehicle.vintageYearManager!, 0, 1)
        : investmentVehicle.vintageYearThirdParty
          ? new Date(investmentVehicle.vintageYearThirdParty!, 0, 1)
          : investmentVehicle.firstCloseDate
            ? new Date(
                UTILS.DATE.toDate(
                  investmentVehicle.firstCloseDate,
                )!.getFullYear()!,
                0,
                1,
              )
            : investmentVehicle.finalCloseDate
              ? new Date(
                  UTILS.DATE.toDate(
                    investmentVehicle.finalCloseDate,
                  )!.getFullYear()!,
                  0,
                  1,
                )
              : investmentVehicle.dateOfFormation;
    }
    return streamStartDate;
  };

  fundId?: number = undefined;
  name?: string = undefined;
  managementCompanyId?: number = undefined;
  managementCompanyName?: string = undefined;
  investmentProgramId?: number = undefined;
  investmentProgramName?: string = undefined;
  type?: AssetTypeEnum = undefined;
  vintage?: number = undefined;

  //#region <<< Section - Historical Info >>>

  formerNames?: string = undefined;
  nameIsPlaceholder?: boolean = undefined;
  highConviction?: boolean = undefined;
  important?: boolean = undefined;

  //#endregion

  //#region <<< Section - Description >>>

  shortDescription?: string = undefined;

  //#endregion

  //#region <<< Section - Overview >>>

  fundOverview?: string = undefined;

  //#endregion

  //#region <<< Section - Aksia Perspective >>>

  aksiaPerspective?: string = undefined;
  aksiaPerspectiveAsOf?: Date = undefined;

  //#endregion

  //#region <<< Section - AUM >>>

  isAumFirstMonth?: boolean = undefined;
  isAumQuarterly?: boolean = undefined;

  //#endregion

  //#region <<< Section - Taxonomy >>>

  assetClassId?: AssetClassEnum = undefined;
  sectorId?: number = undefined;
  strategyId?: number = undefined;
  substrategyId?: number = undefined;
  primaryRegionId?: number = undefined;
  quantumTags?: Array<ITagDTO> = undefined;
  esgWomanMinorityDisabledOwnership?: number = undefined;
  womanOwnership?: number = undefined;
  minorityOwnership?: number = undefined;

  //#endregion

  //#region <<< Section - Basic Info >>>

  physicalFileName?: string = undefined;
  dataAsOfDate?: Date = undefined;
  dataSource?: DataSourceEnum = undefined;
  liquidityStructure?: LiquidityStructureEnum = undefined;
  liquidityType?: LiquidityStructureEnum = undefined;
  vEntityType?: VEntityTypeEnum = undefined;
  legalStructure?: FundStructureEnum = undefined;
  openEndStatus?: HedgeFundStructureEnum = undefined;
  masterFunds?: Array<MasterFund> = undefined;
  mfnProvision?: SimpleAnswerEnum = undefined;
  actsAsMasterFund?: boolean = undefined;
  isOpenEnded?: boolean = undefined;

  //#endregion

  //#region <<< Section - Date Info >>>

  dateOfFormation?: Date = undefined;
  commencementOfOperations?: Date = undefined;
  firstMgmtFeeAccrualDate?: Date = undefined;
  firstDrawdownDueDate?: Date = undefined;
  firstInvestmentDate?: Date = undefined;
  finalCloseDateType?: CloseDateTypeEnum = undefined;
  finalCloseDate?: Date = undefined;
  finalCloseDateNote?: string = undefined;
  firstCloseDateType?: CloseDateTypeEnum = undefined;
  firstCloseDate?: Date = undefined;
  firstCloseDateNote?: string = undefined;
  fundraisingStatus?: FundRaisingStatusEnum = undefined;
  fundraisingNotes?: string = undefined;

  //#endregion

  //#region <<< Reporting >>>

  avgReportingReturns?: number = undefined;
  avgReportingFundAUM?: number = undefined;
  avgReportingExposures?: number = undefined;
  avgReportingIntramonthReturns?: number = undefined;
  firstReportingDelay?: boolean = undefined;
  firstReportingDelayTiming?: number = undefined;
  fundReturnSource?: ReportingSourceEnum = undefined;
  fundAUMSource?: ReportingSourceEnum = undefined;
  logSource?: string = undefined;
  reportingNotes?: string = undefined;

  //#endregion

  //#region <<< Section Leverage >>>

  isLevered?: SimpleAnswerEnum = undefined;
  fundLevelLeverageLimit?: SimpleAnswerEnum = undefined;
  assetLevelLeverageLimit?: SimpleAnswerEnum = undefined;
  leverage?: Array<Leverage> = undefined;

  //#endregion

  //#region <<< Section - Pacing >>>

  cashInterest?: number = undefined;

  /* @Transform(
    ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value?.join(',');
      }
      return value;
    },
    {
      toClassOnly: true,
    }
  )
  @meta({
    alias: 'Contribution Rates',
  }) */
  contributionRates?: string;

  /* @Transform(
    ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value?.join(',');
      }
      return value;
    },
    {
      toClassOnly: true,
    }
  )
  @meta({
    alias: 'Distribution Rates',
  }) */
  distributionRates?: string;

  /* @Transform(
    ({ value }) => {
      if (Array.isArray(value) && value.length > 0) {
        return value?.join(',');
      }
      return value;
    },
    {
      toClassOnly: true,
    }
  )
  @meta({
    alias: 'Growth Rates',
  }) */
  growthRates?: string;

  //#endregion

  //#region <<< Section - Key Terms >>>

  vintageYearManager?: number = undefined;
  vintageYearThirdParty?: number = undefined;
  thirdPartyProvider?: ThirdPartyProviderEnum = undefined;

  //#endregion

  //#region <<< Section - Key Considerations >>>

  excludeForwardCalendar?: boolean = undefined;

  //#endregion

  //#region <<< Section - Identification >>>

  fundCRD?: number = undefined;
  fundCIK?: number = undefined;

  //#endregion

  commitmentCurrency?: CurrencyEnum = undefined;
  programStatus?: ProgramStatusEnum = undefined;
  closedEndDetails: ClosedEndDetails = new ClosedEndDetails();
  coInvestmentDetails: CoInvestmentDetails = new CoInvestmentDetails();
  secondaryDetails: SecondaryDetails = new SecondaryDetails();
}
