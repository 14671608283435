@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  @for (item of items(); track $index) {
    @if (!item.omitRender) {
      @if (item.control === 'multi-dropdown') {
        <multi-dropdown
          [class]="item?.class"
          [settings]="{
            label: item?.settings?.label,
            tag: item?.settings?.tag,
            optionValue: 'id',
            optionLabel: 'name',
            options: item?.settings?.options,
            optionsSorting: item?.settings?.optionsSorting,
            optionsFiltering: item?.settings?.optionsFiltering,
            disabled: item?.settings?.disabled,
            storeOnlyValue: true,
          }"
          [initialValue]="iv.quantumTags | tag: item?.settings?.tag : 'multi'"
          (OptionIsSelected)="
            ivStore.selectTag($event, item?.settings?.options)
          "
        ></multi-dropdown>
      } @else {
        <dropdown
          [class]="item?.class"
          [settings]="{
            label: item?.settings?.label,
            tag: item?.settings?.tag,
            optionValue: 'id',
            optionLabel: 'name',
            options: item?.settings?.options,
            optionsSorting: item?.settings?.optionsSorting,
            optionsFiltering: item?.settings?.optionsFiltering,
            disabled: item?.settings?.disabled,
          }"
          [initialValue]="iv.quantumTags | tag: item?.settings?.tag : 'single'"
          (OptionIsSelected)="
            ivStore.selectTag($event, item?.settings?.options)
          "
        ></dropdown>
      }
    }
  }
}
