@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <date
    [(value)]="iv.dateOfFormation"
    [settings]="{
      label: 'Date of Formation',
      placeholder: 'mm/dd/yyyy',
      labelTip: 'Insert the date the fund was established/incorporated.',
    }"
    [validators]="ivStore.dateOfFormation_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.dateOfFormation_validator_chain()"
  ></date>
  <date
    [(value)]="iv.commencementOfOperations"
    [settings]="{
      label: 'Commencement of Operations',
      labelTip:
        'Insert the date the fund started operations/investment activities.',
    }"
    [validators]="ivStore.commencementOfOperations_validators()"
    [validation.id]="iv.$uid"
  ></date>
  <date
    [(value)]="iv.firstMgmtFeeAccrualDate"
    [settings]="{
      label: 'Date of 1st Management Fee Accrual',
      placeholder: 'mm/dd/yyyy',
    }"
    [validators]="ivStore.firstMgmtFeeAccrualDate_validators()"
    [validation.id]="iv.$uid"
  ></date>
  <date
    [(value)]="iv.firstDrawdownDueDate"
    [settings]="{
      label: 'Date of 1st Drawdown Due Date',
      placeholder: 'mm/dd/yyyy',
      labelTip:
        'Insert the due date of the 1st drawdown at the Fund level, not the client level.',
    }"
    [validators]="ivStore.firstDrawdownDueDate_validators()"
    [validation.id]="iv.$uid"
  ></date>
  <date
    [(value)]="iv.firstInvestmentDate"
    [settings]="{
      label: '1st Investment Date',
      placeholder: 'mm/dd/yyyy',
      labelTip:
        'Insert the due date of the 1st drawdown at the Fund level, not the client level.',
    }"
    [validators]="ivStore.firstInvestmentDate_validators()"
    [validation.id]="iv.$uid"
  ></date>
  <dropdown
    class="c1s2"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.effectiveDateType"
    [settings]="{
      label: 'Effective Date',
      labelFocused: '',
      options: ENUMS.CloseDateTypeEnum.toOptions,
      optionsFiltering: false,
      tag: 'Effective Date Type',
    }"
  ></dropdown>
  <date
    class="c3s2"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.effectiveDate"
    [settings]="{
      label: 'mm/dd/yyyy',
      labelFocused: '',
      labelTip: 'Also known as \n\r Program Status as of',
      tag: 'Effective Date',
    }"
    [validators]="ivStore.effectiveDate_validators()"
    [validation.id]="iv.$uid"
  ></date>
  <text
    class="c5s1"
    [(value)]="iv.closedEndDetails.closedEndKeyTerm.effectiveDateDesc"
    [settings]="{
      label: '',
      labelFocused: '',
      placeholder: 'Description',
      placeholderPersists: true,
      tag: 'Effective Date Description',
    }"
  ></text>
  <number
    class="c1s1"
    [(value)]="iv.vintage"
    [settings]="{
      label: 'Vintage Year per Aksia',
      labelTip:
        'Vintage Year is calculated using available data  in the following order :\r\n1) Earlier of: Date of 1st Management Fee Accrual, Date of 1st Drawdown Due Date, 1st Investment Date, or Commencement of Operations; 2) Vintage year provided by the manager; 3) Vintage year provided by third party; 4) First close date; 5) Final close date; 6) Inception date; 7) Date of Formation',
      onlyWholeNumbers: true,
      thousandsSeparator: '',
    }"
  ></number>
  <number
    class="c2s1"
    [(value)]="iv.vintageYearManager"
    [settings]="{
      label: 'Vintage Year per Manager',
      onlyWholeNumbers: true,
      thousandsSeparator: '',
    }"
  ></number>
  <number
    class="c3s1"
    [(value)]="iv.vintageYearThirdParty"
    [settings]="{
      label: 'Vintage Year per third party',
      labelTip:
        'ONLY USE PUBLIC SOURCES. We cannot broadly use subscribed data from Pitchbook, Preqin or the like. Add note indicating URL or source / website used.',
      onlyWholeNumbers: true,
      thousandsSeparator: '',
    }"
  ></number>

  @if (iv.vintageYearThirdParty) {
    <dropdown
      class="c4s1"
      [(value)]="iv.thirdPartyProvider"
      [settings]="{
        label: 'Third Party Provider',
        options: ENUMS.ThirdPartyProviderEnum.toOptions,
        optionsFiltering: false,
        tag: 'Third Party Provider',
      }"
    ></dropdown>
  }

  <section-group
    class="c1s4"
    [settings]="{
      label: 'Contractual Investment Period Expiration',
    }"
  >
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndKeyTerm
          .contractualInvestmentPeriodExpirationYears
      "
      [settings]="{
        label: 'Contractual Investment Period Expiration',
        placeholder: 'years',
        suffix: ' years',
        labelTip: 'Also known as: \n\r Investment Period',
        tag: 'Contractual Investment Period Expiration Years',
      }"
      [validators]="
        ivStore.contractualInvestmentPeriodExpirationYears_validators()
      "
      [validation.id]="iv.$uid"
      [validation.chain]="
        ivStore.contractualInvestmentPeriodExpirationYears_validator_chain()
      "
      (valueChange)="
        ivStore.updateContractualInvestmentPeriodExpirationYears($event)
      "
    ></number>

    @if (
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationYears
    ) {
      <dropdown
        [class]="
          iv.closedEndDetails.closedEndKeyTerm
            .contractualInvestmentPeriodExpirationType ===
          ENUMS.PeriodTypeEnum.FollowingOtherDate
            ? 'c2s1'
            : 'c2s2'
        "
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm
            .contractualInvestmentPeriodExpirationType
        "
        [settings]="{
          label: '',
          labelTip: 'Also known as: \n\r Investment Period Base',
          options: ENUMS.PeriodTypeEnum.toOptions,
          optionsFiltering: false,
          tag: 'Contractual Investment Period Expiration Type',
        }"
        (valueChange)="
          ivStore.updateContractualInvestmentPeriodExpirationType($event);
          ivStore.updateInvestmentPeriodExtensionValidations()
        "
      ></dropdown>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationYears &&
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationType ===
        ENUMS.PeriodTypeEnum.FollowingOtherDate
    ) {
      <date
        class="c3s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm
            .contractualInvestmentPeriodExpirationDate
        "
        [settings]="{
          label: 'mm/dd/yyyy',
          labelFocused: '',
          tag: 'Contractual Investment Period Expiration Date',
        }"
      ></date>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationYears &&
      iv.closedEndDetails.closedEndKeyTerm
        .contractualInvestmentPeriodExpirationType ===
        ENUMS.PeriodTypeEnum.FollowingOtherDate
    ) {
      <text
        class="c4s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm
            .contractualInvestmentPeriodExpirationDesc
        "
        [settings]="{
          label: '',
          placeholder: 'Description',
          tag: 'Contractual Investment Period Expiration Description',
        }"
      ></text>
    }
    @for (
      extension of iv.closedEndDetails.contractualInvestmentPeriodExtensions;
      track extension.$uid;
      let i = $index, c = $count
    ) {
      <number
        class="c1s1"
        [(value)]="extension.duration"
        [settings]="{
          label: c > 1 ? 'Extension #' + (i + 1) : 'Extension',
          placeholder: 'years',
          suffix: ' years',
          tag: 'Contractual Investment Period Extension Duration #' + (i + 1),
        }"
        [validators]="
          ivStore.contractualInvestmentPeriodExtensionDuration_validators()
        "
        [validation.id]="{
          groupId: iv.$uid!,
          id: extension.$uid!,
        }"
      ></number>
      <dropdown
        class="c2s1"
        [(value)]="extension.type"
        [settings]="{
          label: ' ',
          options: ENUMS.ExtensionTypeEnum.toOptions,
          optionsFiltering: false,
          tag: 'Contractual Investment Period Extension Type #' + (i + 1),
        }"
      ></dropdown>

      @if (i === c - 1) {
        <check
          class="c3s1 justify-center"
          [(value)]="
            iv.closedEndDetails.closedEndKeyTerm
              .hasContractualInvestmentPeriodUnlimitedExtensions
          "
          [settings]="{
            label: 'Unlimited Extensions',
            tag: 'Contractual Investment Period Unlimited Extensions',
          }"
        ></check>
      }

      <div class="c4s1 flex">
        @if (i > 0) {
          <btn
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Contractual Investment Period Extension #' + (i + 1),
            }"
            (click)="
              ivStore.removeContractualInvestmentPeriodExtension(extension)
            "
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Contractual Investment Period Extension',
            }"
            (click)="ivStore.addContractualInvestmentPeriodExtension()"
          ></btn>
        }
      </div>
    }
  </section-group>
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.investmentPeriodExpirationDateType"
    [settings]="{
      label: 'Investment Period Expiration Date',
      options: ENUMS.CloseDateTypeEnum.toOptions,
      optionsFiltering: false,
      tag: 'Investment Period Expiration Date Type',
    }"
  ></dropdown>

  <date
    class="c2s1"
    [(value)]="iv.closedEndDetails.investmentPeriodExpirationDate"
    [settings]="{
      label: 'mm/dd/yyyy',
      labelFocused: '',
      tag: 'Investment Period Expiration Date',
    }"
  ></date>
  <section-group
    class="c1s4"
    [settings]="{
      label: 'Contractual Term Expiration',
    }"
  >
    <number
      class="c1s1"
      [(value)]="
        iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationYears
      "
      [settings]="{
        label: 'Contractual Term Expiration',
        placeholder: 'years',
        suffix: ' years',
        tag: 'Contractual Term Expiration Years',
      }"
      [validators]="ivStore.contractualTermExpirationYears_validators()"
      [validation.id]="iv.$uid"
      [validation.chain]="
        ivStore.contractualTermExpirationYears_validator_chain()
      "
      (valueChange)="ivStore.updateContractualTermExpirationYears($event)"
    ></number>

    @if (iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationYears) {
      <dropdown
        [class]="
          iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType ===
          ENUMS.PeriodTypeEnum.FollowingOtherDate
            ? 'c2s1'
            : 'c2s2'
        "
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType
        "
        [settings]="{
          label: '',
          options: ENUMS.PeriodTypeEnum.toOptions,
          optionsFiltering: false,
          tag: 'Contractual Term Expiration Type',
        }"
        (valueChange)="ivStore.updateContractualTermExpirationType($event)"
      ></dropdown>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationYears &&
      iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType ===
        ENUMS.PeriodTypeEnum.FollowingOtherDate
    ) {
      <date
        class="c3s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationDate
        "
        [settings]="{
          label: 'mm/dd/yyyy',
          labelFocused: '',
          tag: 'Contractual Term Expiration Date',
        }"
      ></date>
    }
    @if (
      iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationYears &&
      iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationType ===
        ENUMS.PeriodTypeEnum.FollowingOtherDate
    ) {
      <text
        class="c4s1"
        [(value)]="
          iv.closedEndDetails.closedEndKeyTerm.contractualTermExpirationDesc
        "
        [settings]="{
          label: '',
          placeholder: 'Description',
          tag: 'Contractual Term Expiration Description',
        }"
      ></text>
    }
    @for (
      extension of iv.closedEndDetails.contractualTermExtensions;
      track extension.$uid;
      let i = $index, c = $count
    ) {
      <number
        class="c1s1"
        [(value)]="extension.duration"
        [settings]="{
          label: c > 1 ? 'Extension #' + (i + 1) : 'Extension',
          placeholder: 'years',
          suffix: ' years',
          tag: 'Contractual Term Expiration Extension Duration #' + (i + 1),
        }"
        [validators]="
          ivStore.contractualTermExpirationExtensionDuration_validators()
        "
        [validation.id]="{
          groupId: iv.$uid!,
          id: extension.$uid!,
        }"
      ></number>
      <dropdown
        class="c2s1"
        [(value)]="extension.type"
        [settings]="{
          label: ' ',
          options: ENUMS.ExtensionTypeEnum.toOptions,
          optionsFiltering: false,
          tag: 'Contractual Term Expiration Extension Type #' + (i + 1),
        }"
      ></dropdown>

      @if (i === c - 1) {
        <check
          class="c3s1 justify-center"
          [(value)]="
            iv.closedEndDetails.closedEndKeyTerm
              .hasContractualTermUnlimitedExtensions
          "
          [settings]="{
            label: 'Unlimited Extensions',
            tag: 'Contractual Term Expiration Unlimited Extensions',
          }"
        ></check>
      }

      <div class="c4s1 flex place-self-stretch">
        @if (i > 0) {
          <btn
            [settings]="{
              icon: ICONS.remove,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Remove Contractual Term Expiration Extension #' + (i + 1),
            }"
            (click)="ivStore.removeContractualTermExtension(extension)"
          ></btn>
        }
        @if (i === c - 1) {
          <btn
            [settings]="{
              icon: ICONS.add,
              iconOnly: true,
              iconSize: 'normal',
              tag: 'Add Contractual Term Expiration Extension',
            }"
            (click)="ivStore.addContractualTermExtension()"
          ></btn>
        }
      </div>
    }
  </section-group>
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.termExpirationDateType"
    [settings]="{
      label: 'Term Expiration Date',
      options: ENUMS.CloseDateTypeEnum.toOptions,
      optionsFiltering: false,
      tag: 'Term Expiration Date Type',
    }"
  ></dropdown>

  <date
    class="c2s1"
    [(value)]="iv.closedEndDetails.termExpirationDate"
    [settings]="{
      label: 'mm/dd/yyyy',
      labelFocused: '',
      tag: 'Term Expiration Date',
    }"
  ></date>
  <dropdown
    class="c1s2"
    [(value)]="iv.closedEndDetails.liquidityTier"
    [settings]="{
      label: 'Liquidity Tier',
      labelTip: 'Primarily used for CAPIX',
      options: ENUMS.LiquidityTierEnum.toOptions,
    }"
  ></dropdown>
}
