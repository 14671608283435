@let selectedOpenClass = ivStore.selectedOpenClass()!;

@if (selectedOpenClass) {
  <number
    class="c1s1"
    [(value)]="selectedOpenClass.minimumInitialInvestment"
    [settings]="{
      label: 'Minimum Initial Investment',
      abbr: true,
    }"
    [validators]="ivStore.minimumInitialInvestment_validators()"
    [validation.id]="selectedOpenClass.$uid!"
    [validation.chain]="ivStore.minimumInitialInvestment_validator_chain()"
  ></number>
  <number
    class="c2s1"
    [(value)]="selectedOpenClass.minimumAdditionalInvestment"
    [settings]="{
      label: 'Minimum Additional Investment',
      abbr: true,
    }"
    [validators]="ivStore.minimumAdditionalInvestment_validators()"
    [validation.id]="selectedOpenClass.$uid!"
    [validation.chain]="ivStore.minimumAdditionalInvestment_validator_chain()"
  ></number>
  <dropdown
    class="c3s1"
    [(value)]="selectedOpenClass.minimumInvestmentCurrency"
    [settings]="{
      label: '',
      placeholder: 'Currency',
      options: ENUMS.CurrencyEnum.toOptions,
      tag: 'Minimum Investment Currency',
    }"
    [validators]="ivStore.minimumInvestmentCurrency_validators()"
    [validation.id]="selectedOpenClass.$uid!"
  ></dropdown>
  <dropdown
    class="c1s1"
    [(value)]="selectedOpenClass.hasSubscriptionFee"
    [settings]="{
      label: 'Subscription Fee',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      optionsFiltering: false,
      tag: 'Has Subscription Fee',
    }"
    [validators]="ivStore.hasSubscriptionFee_validators()"
    [validation.id]="selectedOpenClass.$uid!"
    (valueChange)="ivStore.updateHasSubscriptionFee($event)"
  ></dropdown>

  @if (selectedOpenClass.hasSubscriptionFee === ENUMS.SimpleAnswerEnum.Yes) {
    <number
      class="c2s1"
      [(value)]="selectedOpenClass.subscriptionFee"
      [settings]="{
        label: 'Fee',
        placeholder: '%',
        suffix: ' %',
        tag: 'Subscription Fee',
      }"
      [validators]="ivStore.subscriptionFee_validators()"
      [validation.id]="selectedOpenClass.$uid!"
    ></number>
  }
}
