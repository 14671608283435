@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <dropdown
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.instrument"
    [settings]="{
      label: 'Instrument',
      options: ENUMS.InstrumentEnum.toOptions,
    }"
  ></dropdown>
  <dropdown
    class="c2s1"
    [(value)]="iv.coInvestmentDetails.stapledDeal"
    [settings]="{
      label: 'Stapled Deal',
      labelTip: 'Opportunity only exists if primary fund commitment is made',
      options: ENUMS.SimpleAnswerEnum.toOptions,
      optionsFiltering: false,
    }"
  ></dropdown>
  <date
    class="c1s1"
    [(value)]="iv.dateOfFormation"
    [settings]="{
      label: 'Date of Formation',
      placeholder: 'mm/dd/yyyy',
      labelTip: 'Insert the date the fund was established/incorporated.',
    }"
    [validators]="ivStore.dateOfFormation_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.dateOfFormation_validator_chain()"
  ></date>
  <dropdown
    class="c2s1"
    [(value)]="iv.finalCloseDateType"
    [settings]="{
      label: 'Final Close Date',
      labelTip: 'Also known as: \n\r Est. Final Close Date',
      options: ENUMS.CloseDateTypeEnum.toOptions,
      optionsFiltering: false,
      tag: 'Final Close Date Type',
    }"
  ></dropdown>
  <date
    class="c3s1"
    [(value)]="iv.finalCloseDate"
    [settings]="{
      label: 'mm/dd/yyyy',
      labelFocused: ' ',
      tag: 'Final Close Date',
    }"
    [validators]="ivStore.finalCloseDate_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.finalCloseDate_validator_chain()"
  ></date>
  <date
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.hardCommitmentDate"
    [settings]="{
      label: 'Hard Commitment Date',
      placeholder: 'mm/dd/yyyy',
    }"
  ></date>
  <text
    class="c2s1"
    [(value)]="iv.coInvestmentDetails.otherDatesColor"
    [settings]="{
      label: 'Other Dates/Color',
      tag: 'Other Dates Color',
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.coInvestmentDetails.overview"
    [settings]="{
      label: 'Overview',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.coInvestmentDetails.transactionSummary"
    [settings]="{
      label: 'Transaction Summary',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.coInvestmentDetails.merits"
    [settings]="{
      label: 'Merits',
      multiline: true,
    }"
  ></text>
  <text
    class="c1s3 multi"
    [(value)]="iv.coInvestmentDetails.issues"
    [settings]="{
      label: 'Issues',
      multiline: true,
    }"
  ></text>
  <number
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.operatingExpenseCap"
    [settings]="{
      label: 'Operating Expense Cap',
      placeholder: '%',
      suffix: ' %',
    }"
  ></number>
  <dropdown
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.feeStructure"
    [settings]="{
      label: 'Fee Structure',
      options: ENUMS.CoInvFeeStructureEnum.toOptions,
      optionsFiltering: false,
    }"
  ></dropdown>
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.liquidityTier"
    [settings]="{
      label: 'Liquidity Tier',
      labelTip: 'Primarily used for CAPIX',
      options: ENUMS.LiquidityTierEnum.toOptions,
      optionsFiltering: false,
    }"
  ></dropdown>
  <number
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.interestMargin"
    [settings]="{
      label: 'Interest Margin',
      placeholder: '%',
      suffix: ' %',
    }"
  ></number>
  <number
    class="c2s1"
    [(value)]="iv.coInvestmentDetails.originalIssueDiscount"
    [settings]="{
      label: 'Original Issues Discount',
      placeholder: '%',
      suffix: ' %',
    }"
  ></number>
}
