@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <dropdown
    class="c1s1"
    [(value)]="iv.sectorId"
    [settings]="{
      label: 'Sector',
      options: ivStore.filteredSectors(),
    }"
    [validators]="ivStore.sectorId_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="
      ivStore.selectSector($event);
      strategyCtrl.reset();
      substrategyCtrl.reset()
    "
  ></dropdown>
  <dropdown
    #strategyCtrl
    class="c2s1"
    [(value)]="iv.strategyId"
    [settings]="{
      label: 'Strategy',
      options: ivStore.filteredStrategies(),
    }"
    [validators]="ivStore.strategyId_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.selectStrategy($event); substrategyCtrl.reset()"
  ></dropdown>
  <dropdown
    #substrategyCtrl
    class="c3s1"
    [(value)]="iv.substrategyId"
    [settings]="{
      label: 'Sub-strategy',
      options: ivStore.filteredSubstrategies(),
    }"
    [validators]="ivStore.substrategyId_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.selectSubstrategy($event)"
  ></dropdown>
  <dropdown
    class="c4s1"
    [(value)]="iv.primaryRegionId"
    [settings]="{
      label: 'Primary Region',
      options: ivStore.filteredPrimaryRegions(),
    }"
    [validators]="ivStore.primaryRegionId_validators()"
    [validation.id]="iv.$uid"
    (valueChange)="ivStore.selectSubstrategy($event)"
  ></dropdown>
}
