@if (permDir.uipermission() !== PERMISSIONS.NONE || permDir.editPermissions()) {
  <!-- Label -->
  @if (label()) {
    <label
      [attr.uilabelup]="labelUp()"
      [label]="labelFormatted()"
      [labelTip]="labelTip()"
      [state]="state()"
      (click)="focusin()"
    >
    </label>
  }

  <!-- Input -->
  <input
    #uiValueRef
    uivalue
    [class]="inputCssClass()"
    [value]="formattedValue()"
    [placeholder]="placeholder()"
    (keydown)="filterKeys($event, uiValueRef.value)"
    (keyup.enter)="uiValueRef.blur()"
    (focus)="focusin()"
    (blur)="focusout()"
  />

  <!-- Tools -->
  <div uitoolstrip>
    <ng-template #uiToolsCodeRef></ng-template>
    <ng-container #uiToolsDefaultRef>
      @if (!isNil(value())) {
        <icon
          uihovertool
          uicleartool
          [iconSize]="'normal'"
          [iconDefinition]="ICONS.faTimes"
          (click)="reset()"
        ></icon>
      }
    </ng-container>
    <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
  </div>

  <!-- State -->
  @switch (uiState) {
    @case ('error') {
      <span
        uifooter
        uistate="error"
        class="cursor-help"
        [title]="errors().join('\r\n')"
        >{{
          errors().length > 1
            ? errors().at(0) +
              ' (' +
              (errors().length - 1).toString() +
              ' more)'
            : errors().at(0)
        }}</span
      >
    }
    @case ('warning') {
      <span
        uifooter
        uistate="warning"
        class="cursor-help"
        [title]="warnings().join('\r\n')"
        >{{
          warnings().length > 1
            ? warnings().at(0) +
              ' (' +
              (warnings().length - 1).toString() +
              ' more)'
            : warnings().at(0)
        }}</span
      >
    }
  }
}
