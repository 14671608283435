@let iv = ivStore.investmentVehicle();
@let company = companyStore.company();
@let entity = iv ? iv! : company!;
@let aum = ivStore.aum();

@if (aum) {
  <dropdown
    class="c1s2"
    [(value)]="aum.selectedSource"
    [settings]="{
      label:
        aum.markedDataPoints?.length! > 1
          ? 'Apply Source to Selection'
          : 'Source',
      tag: 'AUM Source',
      options: ENUMS.DataSourceEnum.toOptions,
      optionValue: 'value',
      optionLabel: 'label',
    }"
    (valueChange)="ivStore.setSelectedSource(aum, $event)"
  ></dropdown>
  <dropdown
    class="c3s2"
    [(value)]="aum.selectedCurrency"
    uitool
    [(value)]="aum.selectedCurrency"
    [settings]="{
      label: 'Currency',
      tag: 'AUM Currency',
      options: ENUMS.CurrencyStringEnum.toOptions,
      optionValue: 'label',
      optionLabel: 'label',
    }"
    (valueChange)="ivStore.setSelectedCurrency(aum, $event)"
  ></dropdown>
  <div class="c5s2 flex place-content-evenly gap-4">
    <check
      [(value)]="entity.isAumFirstMonth"
      [settings]="{ label: 'First Month', tag: 'AUM First Month' }"
    ></check>
    <check
      [(value)]="entity.isAumQuarterly"
      [settings]="{ label: 'Quarterly', tag: 'AUM Quarterly' }"
    ></check>
  </div>

  @if (iv) {
    <dropdown
      class="c7s2"
      [value]="ivStore!.selectedAumLevel()"
      [settings]="{
        label: 'Entity Level',
        tag: 'AUM Entity Level',
        options: ivStore!.aumLevels(),
        optionValue: 'value',
        optionLabel: 'label',
        optionsFiltering: false,
        optionsSorting: false,
      }"
      (valueChange)="ivStore.setSelectedAumLevel($event)"
    ></dropdown>
  }

  <div class="c9s1 flex place-content-end gap-4">
    <btn
      class="md secondary"
      [settings]="{
        icon: ICONS.download,
        iconSize: 'large',
        tag: 'Download AUM',
      }"
    ></btn>
    <btn
      class="md secondary"
      [settings]="{
        icon: ICONS.upload,
        iconSize: 'large',
        tag: 'Upload AUM',
      }"
    ></btn>
  </div>

  <spreadsheet
    class="c1s9"
    [settings]="{
      label: 'AUM',
      idField: '$uid',
      valueField: 'value',
      stateField: '$state',
      deletedField: '$isDeleted',
      cellCssField: '$cellCssClass',
      currencyField: 'currency',
      allowedValues: 'numeric',
      numberFormattingType: 'currency',
      numberFormattingPrecision: '1.0',
      tagPrefix: 'aum',
      columnTitles: MONTHS,
      rowTitles: ivStore!.getStreamYears(ivStore!.selectedAumLevel())!,
    }"
    [data]="aum.datapoints!"
    [dataValidationGroupId]="aum.$uid!"
    [dataValidators]="ivStore!.aum_validators()"
    (cellSelected)="ivStore.setMarkedStreamPoints(aum, $event)"
    (valueChange)="ivStore.onStreamPointValueChange(aum, $event)"
  >
  </spreadsheet>
}
