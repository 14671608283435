@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <text
    class="c1s1"
    [(value)]="iv.coInvestmentDetails.sourcingAnalystName"
    [settings]="{
      label: 'Sourcing Analyst',
      labelTip: 'Select who sourced this co-investment opportunity',
      tag: 'Sourcing Analyst Name',
    }"
  ></text>
  <text
    class="c2s1"
    [(value)]="iv.coInvestmentDetails.intermediaryBroker"
    [settings]="{
      label: 'Intermediary/Broker',
    }"
  ></text>
  <dropdown
    class="c3s1"
    [(value)]="iv.coInvestmentDetails.gpAksiaCoverage"
    [settings]="{
      label: 'Aksia Coverage of GP',
      options: ENUMS.AksiaCoverageGPNewEnum.toOptions,
    }"
  ></dropdown>
}
