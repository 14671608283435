<modal
  #uiModalRef
  [settings]="{ hasBackdrop: true }"
>
  <div UiCustomModalHeader>
    <h2 >Co-Pilot {{ title() }} Suggestions
      <span class="ai-beta-label">BETA</span>
    </h2>
  </div>
      
  <div UiModalCustomContent class="rephrase-modal-content">
    <div class="rephrase-modal-container">

      <div class="rephrase-modal-original-text">
        <div class="rephrase-modal-original-text-header">
          <h3>Original text</h3>
          <select *ngIf="toneOptions().length > 0" 
            id="optionSelect" 
            [disabled]="isLoading()"
            [ngModel]="selectedTone()"
            (ngModelChange)="onToneChange($event)">
            <option *ngFor="let option of toneOptions()" 
              [value]="option.key"
              [title]="option.value"             
            > {{ option.key }}
            </option>
          </select>
        </div>
        <p id="original-text">
          {{ originalText() }}  
        <p>
      </div>
      <div class="rephrase-modal-suggestions-container">
        <div *ngIf="isLoading()" class="loader"></div>
        
        <div *ngIf="errorMessage()">{{ errorMessage() }}</div>
  
        <div *ngIf="suggestions().length > 0" class="rephrase-modal-suggestions">
          <div *ngFor="let suggestion of suggestions()" class="rephrase-suggestion">
            <p>{{ suggestion }}</p>
            <div class="rephrase-suggestion-actions">
              <button 
                (click)="copySuggestion(suggestion)"  
                class="copy-button"
              >
                Copy 📄</button>
              <button 
                (click)="replaceSuggestion(suggestion)"
                class="rephrase-button"
              >
                Replace ✔</button>
            </div>
          </div>
        </div>
  
        <div *ngIf="suggestions().length > 0" class="rephrase-modal-footer">
          Rate these suggestions
          <button 
            id="suggestion-dislike"
            class="rephrase-modal-button"
            [class.__dislike-button]="activeLike() === 'dislike'"
            (click)="rateSuggestion(false)"
          ><icon [iconDefinition]="faSolidThumbsDown" [iconSize]="'x-large'"></icon>
          </button>
          <button 
            id="suggestion-like"  
            class="rephrase-modal-button"
            [class.__like-button]="activeLike() === 'like'"
            (click)="rateSuggestion(true)"
          ><icon [iconDefinition]="faSolidThumbsUp" [iconSize]="'x-large'"></icon>
          </button>
        </div>
      </div>

    </div>
    
  </div>
</modal>