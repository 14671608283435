import {
  patchState,
  signalStoreFeature,
  type,
  withComputed,
  withMethods,
  withState,
  withHooks,
} from '@ngrx/signals';

import {
  withEntities,
  setAllEntities,
  addEntity,
  updateEntity,
  entityConfig,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  CarriedInterestTieredRate,
  ClosedShareClass,
  Discount,
  DOMAIN_API_REQUESTS,
  DOMAINS,
  Enums,
  Gate,
  IncFeeRate,
  InvestmentVehicle,
  Lockup,
  MgmtFeeAmount,
  MgmtFeePeriod,
  MgmtFeeRate,
  OpenShareClass,
  SidePocket,
} from '@aksia/models';
import { pipe, tap, switchMap } from 'rxjs';
import {
  EntityService,
  LoadingService,
  NavigationService,
} from '@aksia/services';
import { computed, inject, Signal } from '@angular/core';
import {
  IValidationRequest,
  IValidationValidateRequest,
  StoreModel,
} from '@aksia/infrastructure';
import { withShareClassValidators } from '../validators/shareclass.store.validators';

const openShareclassConfig = entityConfig({
  entity: type<
    OpenShareClass & { $isOpenEnd?: boolean; $isClosedEnd?: boolean }
  >(),
  collection: 'openShareClass',
  selectId: (shareclass) => shareclass?.$uid!,
});

const closedShareclassConfig = entityConfig({
  entity: type<
    ClosedShareClass & { $isOpenEnd?: boolean; $isClosedEnd?: boolean }
  >(),
  collection: 'closedShareClass',
  selectId: (shareclass) => shareclass?.$uid!,
});

export type ShareClassState = {
  selectedClassUId: number | undefined;
};

export function withShareClass<ShareclassFeature>() {
  return signalStoreFeature(
    {
      state: type<{
        entityId: number | undefined;
      }>(),
      computed: type<{
        investmentVehicle: Signal<InvestmentVehicle>;
      }>(),
      methods: type<{
        registerMethod(method: Function, priority?: number): void;
        finalizeMethod(priority: number): void;
        toStoreModelCollection<T, U = StoreModel>(
          dto: Array<T>,
          model: new (...args: any[]) => U,
          meta?: { tags?: Array<string>; attributes?: Map<string, unknown> },
        ): Array<U>;
        validate(
          request:
            | IValidationValidateRequest
            | Array<IValidationValidateRequest>,
          chain?:
            | IValidationValidateRequest
            | Array<IValidationValidateRequest>,
          isChain?: boolean,
        ): void;
        removeValidation(
          request: IValidationRequest | Array<IValidationRequest>,
        ): void;
        modelIsDirty(model: StoreModel): { dto: unknown; isDirty: unknown };
        getGroupErrors(groupId: number): Array<string>;
      }>(),
    },
    withState<ShareClassState>({
      selectedClassUId: undefined,
    }),
    withEntities(openShareclassConfig),
    withEntities(closedShareclassConfig),
    withComputed(
      ({
        investmentVehicle,
        openShareClassEntities,
        closedShareClassEntities,
        selectedClassUId,
      }) => {
        //#region Common Share Classes

        const shareClasses = computed(() =>
          investmentVehicle()?.liquidityStructure ===
          Enums.LiquidityStructureEnum.OpenEnd
            ? openShareClassEntities()
            : closedShareClassEntities(),
        );

        const selectedClass = computed(() =>
          shareClasses()?.find((sc) => sc.$uid === selectedClassUId()),
        );

        const selectedOpenClass = computed(() =>
          openShareClassEntities()?.find(
            (sc) => sc.$uid === selectedClassUId(),
          ),
        );

        const selectedClosedClass = computed(() =>
          closedShareClassEntities()?.find(
            (sc) => sc.$uid === selectedClassUId(),
          ),
        );

        //#endregion

        //#region Open Share Classes

        const filteredSidePockets = computed(() =>
          selectedOpenClass()?.sidePockets?.filter((sp) => !sp.$isDeleted),
        );

        const filteredLockups = computed(() =>
          selectedOpenClass()?.lockups?.filter((lockup) => !lockup.$isDeleted),
        );

        const hideRedemptionRest = computed(
          () =>
            !selectedOpenClass()?.redemptionTermsFrequencyAmount ||
            selectedOpenClass()?.redemptionTermsFrequency ===
              Enums.CalendarUnitEnum.Custom ||
            selectedOpenClass()?.redemptionTermsFrequency ===
              Enums.CalendarUnitEnum.Days,
        );

        const filteredGates = computed(() =>
          selectedOpenClass()?.gates?.filter((gate) => !gate.$isDeleted),
        );

        const filteredOpenMgmtFeeRates = computed(() =>
          selectedOpenClass()?.mgmtFeeRates?.filter((rate) => !rate.$isDeleted),
        );

        const filteredOpenMgmtFeeDiscounts = computed(() =>
          selectedOpenClass()?.managementFeeDiscounts?.filter(
            (discount) => !discount.$isDeleted,
          ),
        );

        const filteredIncFeeRates = computed(() =>
          selectedOpenClass()?.incFeeRates?.filter((rate) => !rate.$isDeleted),
        );

        const filteredIncFeeDiscounts = computed(() =>
          selectedOpenClass()?.incentiveFeeDiscounts?.filter(
            (discount) => !discount.$isDeleted,
          ),
        );

        //#endregion

        //#region Closed Share Classes

        const filteredMgmtFeePeriods = computed(() =>
          selectedClosedClass()?.mgmtFeePeriods?.filter(
            (period) => !period.$isDeleted,
          ),
        );

        const filteredMgmtFeeAmounts = computed(
          () => (period: MgmtFeePeriod) =>
            period.mgmtFeeAmounts?.filter((a) => !a.$isDeleted),
        );

        const filteredClosedMgmtFeeDiscounts = computed(() =>
          selectedClosedClass()?.mgmtFeeDiscounts?.filter(
            (discount) => !discount.$isDeleted,
          ),
        );

        const filteredCarriedInterestTieredRates = computed(() =>
          selectedClosedClass()?.carriedInterestTieredRates?.filter(
            (rate) => !rate.$isDeleted,
          ),
        );

        const filteredCarriedInterestDiscounts = computed(() =>
          selectedClosedClass()?.carriedInterestDiscounts?.filter(
            (discount) => !discount.$isDeleted,
          ),
        );

        //#endregion

        return {
          //Common Share Classes
          shareClasses,
          selectedClass,
          selectedOpenClass,
          selectedClosedClass,

          //Open Share Classes
          filteredSidePockets,
          filteredLockups,
          hideRedemptionRest,
          filteredGates,
          filteredOpenMgmtFeeRates,
          filteredOpenMgmtFeeDiscounts,
          filteredIncFeeRates,
          filteredIncFeeDiscounts,

          //Closed Share Classes
          filteredMgmtFeePeriods,
          filteredMgmtFeeAmounts,
          filteredClosedMgmtFeeDiscounts,
          filteredCarriedInterestTieredRates,
          filteredCarriedInterestDiscounts,
        };
      },
    ),
    withShareClassValidators(),
    withMethods(
      (
        store,
        loading = inject(LoadingService),
        entities = inject(EntityService),
      ) => {
        //#region Common Methods

        const loadShareClasses = () => {
          loadShareClassesRx(store.entityId()!);
        };

        const loadShareClassesRx = rxMethod<number>(
          pipe(
            tap(() => {
              loading.addLoadRequest(DOMAIN_API_REQUESTS.SHARE_CLASSES);
            }),
            switchMap((entityId) =>
              entities
                .getEntitiesByParentId<
                  Array<{
                    openEnd: OpenShareClass;
                    closedEnd: ClosedShareClass;
                  }>
                >(entityId!, DOMAINS.SHARECLASSES.URI)
                .pipe(
                  tap({
                    next: (shareClassBundles) => {
                      initEntities(shareClassBundles);
                      updateSelectedClassUId();
                    },
                    error: (error) => {
                      console.error(
                        `Error loading: ${DOMAIN_API_REQUESTS.SHARE_CLASSES}`,
                        error,
                      );
                    },
                    finalize: () => {
                      loading.setLoadResponse(
                        DOMAIN_API_REQUESTS.SHARE_CLASSES,
                      );
                      store.finalizeMethod(2);
                    },
                  }),
                ),
            ),
          ),
        );

        const saveShareClasses = () => {
          let allClasses = [
            ...store.openShareClassEntities(),
            ...store.closedShareClassEntities(),
          ];

          let { classErrors, dirtyClasses } = allClasses.reduce(
            (
              acc: {
                classErrors: Array<string>;
                dirtyClasses: Array<unknown>;
              },
              shareClass,
            ) => {
              let classErrors = store.getGroupErrors(shareClass.$uid!);

              if (classErrors?.length > 0) {
                acc.classErrors = [...acc.classErrors, ...classErrors];
              } else {
                let { isDirty, dto } = store.modelIsDirty(shareClass);
                if (isDirty) {
                  acc.dirtyClasses.push(dto);
                }
              }
              return acc;
            },
            { classErrors: [], dirtyClasses: [] },
          );

          if (classErrors.length > 0) {
            console.error(classErrors);
          }

          if (dirtyClasses.length > 0) {
            console.log('Saving Share Classes...');
            console.log(dirtyClasses);
          } else {
            console.log('Shareclasses have no changes');
          }
        };

        const initEntities = (
          shareClassBundles: Array<{
            openEnd: OpenShareClass;
            closedEnd: ClosedShareClass;
          }>,
        ) => {
          let openShareClasses = store.toStoreModelCollection<OpenShareClass>(
            shareClassBundles
              .filter((sc) => sc.openEnd)
              ?.map((sc) => sc.openEnd) ?? [{}],
            OpenShareClass,
            { tags: ['isOpenEnd'] },
          );

          let closedShareClasses =
            store.toStoreModelCollection<ClosedShareClass>(
              shareClassBundles
                .filter((sc) => sc.closedEnd)
                ?.map((sc) => sc.closedEnd) ?? [{}],
              ClosedShareClass,
              { tags: ['isClosedEnd'] },
            );

          patchState(
            store,
            setAllEntities(openShareClasses, openShareclassConfig),
          );

          patchState(
            store,
            setAllEntities(closedShareClasses, closedShareclassConfig),
          );
        };

        //#endregion

        //#region Common Share Classes

        const updateSelectedClassUId = (uid?: unknown) => {
          if (uid !== undefined) {
            patchState(store, { selectedClassUId: uid as number });
          } else if (
            store.openShareClassEntities()?.length > 0 ||
            store.closedShareClassEntities()?.length > 0
          ) {
            let shareClasses =
              store.investmentVehicle()?.liquidityStructure ===
              Enums.LiquidityStructureEnum.OpenEnd
                ? store.openShareClassEntities()
                : store.closedShareClassEntities();
            let selectedClass =
              shareClasses.find(
                (sc: OpenShareClass | ClosedShareClass) => sc.isDefault,
              ) ?? shareClasses?.at(0);
            patchState(store, { selectedClassUId: selectedClass?.$uid! });
          }
        };

        const updateShareClass = (
          partialShareClass: Partial<OpenShareClass | ClosedShareClass>,
        ) => {
          if (!partialShareClass) return;
          if (
            store.investmentVehicle()?.liquidityStructure ===
            Enums.LiquidityStructureEnum.OpenEnd
          ) {
            patchState(
              store,
              updateEntity(
                {
                  id: partialShareClass?.$uid
                    ? partialShareClass?.$uid
                    : store.selectedOpenClass()!.$uid!,
                  changes: { ...partialShareClass } as Partial<OpenShareClass>,
                },
                openShareclassConfig,
              ),
            );
          } else {
            patchState(
              store,
              updateEntity(
                {
                  id: partialShareClass?.$uid
                    ? partialShareClass?.$uid
                    : store.selectedClosedClass()!.$uid!,
                  changes: partialShareClass as Partial<ClosedShareClass>,
                },
                closedShareclassConfig,
              ),
            );
          }
        };

        const updateSelectedClassDefault = () => {
          if (!store.selectedClass()?.isDefault) {
            store
              .shareClasses()
              .forEach((sc: OpenShareClass | ClosedShareClass) => {
                sc.isDefault = sc.$uid === store.selectedClassUId();
                updateShareClass(sc);
              });
          }
        };

        const addShareClass = (name: string) => {
          let newClass, newEmptyClass, newUid;

          if (
            store.investmentVehicle()?.liquidityStructure ===
            Enums.LiquidityStructureEnum.OpenEnd
          ) {
            newEmptyClass = new OpenShareClass();
            newUid = newEmptyClass.$uid;
          } else {
            newEmptyClass = new ClosedShareClass();
            newUid = newEmptyClass.$uid;
          }

          if (store.selectedClass()) {
            newClass = Object.assign(newEmptyClass, store.selectedClass()!);
            newClass.isDefault = false;
          } else {
            newClass = newEmptyClass;
          }

          newClass.$uid = newUid;
          newClass.classId = -1;
          newClass.className = name;

          if (
            store.investmentVehicle()?.liquidityStructure ===
            Enums.LiquidityStructureEnum.OpenEnd
          ) {
            patchState(store, addEntity(newClass, openShareclassConfig));
          } else {
            patchState(store, addEntity(newClass, closedShareclassConfig));
          }

          updateSelectedClassUId(newClass.$uid);
        };

        const removeShareClass = () => {
          store.selectedClass()!.isDeleted = store.selectedClass()!.$isDeleted =
            true;
          updateShareClass(store.selectedClass()!);
          updateSelectedClassUId();
        };

        const renameShareClass = (name: string) => {
          store.selectedClass()!.className = name;
          updateShareClass(store.selectedClass()!);
        };

        //#endregion

        //#region Open Share Classes

        //#region Side Pockets

        const updateHasSidePocket = (value: unknown) => {
          if (value === Enums.SimpleAnswerEnum.Yes) {
            addSidePocket();
          } else {
            store
              .selectedOpenClass()
              ?.sidePockets?.forEach((sp: SidePocket) => removeSidePocket(sp));
          }
        };

        const addSidePocket = () => {
          let sidePocket = new SidePocket();

          store.selectedOpenClass()!.sidePockets = [
            ...(store.selectedOpenClass()!.sidePockets ?? []),
            sidePocket,
          ];

          updateShareClass({
            sidePockets: store.selectedOpenClass()!.sidePockets,
          });
        };

        const removeSidePocket = (sidePocket: SidePocket) => {
          store.removeValidation({ validationId: sidePocket.$uid! });
          sidePocket.$isDeleted = true;
          if (sidePocket.id! > 0) {
            sidePocket.markedForDeletion = true;
          } else {
            store.selectedOpenClass()!.sidePockets = store
              .selectedOpenClass()!
              .sidePockets?.filter((sp: SidePocket) => sp !== sidePocket);
          }

          updateShareClass({
            sidePockets: store.selectedOpenClass()!.sidePockets,
          });
        };

        //#endregion

        //#region Subscriptions

        const updateHasSubscriptionFee = (value: unknown) => {
          if (value !== Enums.SimpleAnswerEnum.Yes) {
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Subscription Fee',
            });
            store.selectedOpenClass()!.subscriptionFee = undefined;
          }
        };

        //#endregion

        //#region Redemptions

        const updateHasLockup = (value: unknown) => {
          if (value === Enums.SimpleAnswerEnum.Yes) {
            addLockup();
          } else {
            store
              .selectedOpenClass()
              ?.lockups?.forEach((lockup: Lockup) => removeLockup(lockup));
          }

          updateShareClass({ hasLockup: value as Enums.SimpleAnswerEnum });
        };

        const updateLockupType = (lockup: Lockup, value: unknown) => {
          if (value !== Enums.LockUpEnum.Soft) {
            let index = (store.filteredLockups()?.indexOf(lockup) ?? 0) + 1;

            store.removeValidation({
              validationId: lockup.$uid!,
              tag: `Lockup Fee #${index}`,
            });
            lockup.lockupFee = undefined;
          }
        };

        const addLockup = () => {
          let lastLockup = store.filteredLockups()?.at(-1);
          let lockup = new Lockup();
          lockup.lockupType = Enums.LockUpEnum.Soft;
          lockup.lockupDurationMinRange =
            (lastLockup?.lockupDurationMaxRange ?? 0) + 1;
          lockup.lockupDurationMaxRange =
            (lastLockup?.lockupDurationMaxRange ?? 0) + 2;

          store.selectedOpenClass()!.lockups = [
            ...(store.selectedOpenClass()!.lockups ?? []),
            lockup,
          ];

          updateShareClass({
            lockups: store.selectedOpenClass()!.lockups,
          });
        };

        const removeLockup = (lockup: Lockup) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedOpenClass()?.$uid!,
              id: lockup.$uid!,
            },
          });
          lockup.$isDeleted = true;
          if (lockup.id! > 0) {
            lockup.markedForDeletion = true;
          } else {
            store.selectedOpenClass()!.lockups = store
              .selectedOpenClass()!
              .lockups?.filter((lp: Lockup) => lp !== lockup);
          }

          updateShareClass({ lockups: store.selectedOpenClass()!.lockups });
        };

        const updateRedemptionTerms = (
          field: keyof OpenShareClass,
          value: unknown,
        ) => {
          if (field === 'redemptionTermsFrequencyAmount') {
            if (!value) {
              store.selectedOpenClass()!.redemptionTermsFrequencyDay =
                undefined;
              //TODO: selectedOpenClass().redemptionCustomDates.set(undefined);
              store.selectedOpenClass()!.redemptionTermsNotice = undefined;
              store.selectedOpenClass()!.redemptionTermsNoticePeriod =
                undefined;
              store.selectedOpenClass()!.redemptionFee = undefined;

              store.removeValidation([
                {
                  validationId: store.selectedClassUId()!,
                  tag: 'Redemption Terms Frequency Day',
                },
                {
                  validationId: store.selectedClassUId()!,
                  tag: 'Redemption Terms Notice',
                },
                {
                  validationId: store.selectedClassUId()!,
                  tag: 'Redemption Terms Notice Period',
                },
                {
                  validationId: store.selectedClassUId()!,
                  tag: 'Redemption Fee',
                },
              ]);
            }
          } else if (field === 'redemptionTermsFrequency') {
            if (
              value === Enums.CalendarUnitEnum.Custom ||
              value === Enums.CalendarUnitEnum.Days ||
              !value
            ) {
              store.selectedOpenClass()!.redemptionTermsFrequencyDay =
                undefined;
              store.removeValidation({
                validationId: store.selectedClassUId()!,
                tag: 'Redemption Terms Frequency Day',
              });
            }
          }

          updateShareClass({ [field]: value });
        };

        const updateHasGate = (value: unknown) => {
          if (value === Enums.SimpleAnswerEnum.Yes) {
            addGate();
          } else {
            store
              .selectedOpenClass()
              ?.gates?.forEach((gate) => removeGate(gate));
          }

          updateShareClass({ hasGate: value as Enums.SimpleAnswerEnum });
        };

        const updateGateLevel = (gate: Gate, value: unknown) => {
          if (!value) {
            gate.gateMasterFundPercentage = undefined;
            gate.gateFundPercentage = undefined;
            gate.gateInvestorPercentage = undefined;
            gate.gatePercentage = undefined;
          } else if (
            value === Enums.FundLevelsEnum.MasterFund ||
            value === Enums.FundLevelsEnum.Fund ||
            value === Enums.FundLevelsEnum.Class ||
            value === Enums.FundLevelsEnum.Investor
          ) {
            gate.gateMasterFundPercentage = undefined;
            gate.gateFundPercentage = undefined;
            gate.gateInvestorPercentage = undefined;
          } else {
            gate.gatePercentage = undefined;
          }

          updateShareClass({
            gates: store.selectedOpenClass()!.gates,
          });
        };

        const addGate = () => {
          let gate = new Gate();

          store.selectedOpenClass()!.gates = [
            ...(store.selectedOpenClass()!.gates ?? []),
            gate,
          ];

          updateShareClass({
            gates: store.selectedOpenClass()!.gates,
          });
        };

        const removeGate = (gate: Gate) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedOpenClass()?.$uid!,
              id: gate.$uid!,
            },
          });
          gate.$isDeleted = true;
          if (gate.id! > 0) {
            gate.markedForDeletion = true;
          } else {
            store.selectedOpenClass()!.gates = store
              .selectedOpenClass()!
              .gates?.filter((g: Gate) => g !== gate);
          }

          updateShareClass({ gates: store.selectedOpenClass()!.gates });
        };

        //#endregion

        //#region Fees

        const updateHasManagementFee = (value: unknown) => {
          if (value === Enums.SimpleAnswerPassEnum.Yes) {
            store.selectedOpenClass()!.mgmtFeeRateType =
              Enums.MgmtFeeRateTypeEnum.SingleRate;
            store.selectedOpenClass()!.managementFeePassThrough = undefined;
          } else if (value === Enums.SimpleAnswerPassEnum.PassThrough) {
            store.selectedOpenClass()!.mgmtFeeRateType = undefined;
            store.selectedOpenClass()!.payable = undefined;
            store.selectedOpenClass()!.payableDescription = undefined;
            store.selectedOpenClass()!.hasManagementFeeDiscounts = undefined;
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Payable',
            });
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Payable Description',
            });
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Has Open Management Fee Discounts',
            });
          } else {
            store.selectedOpenClass()!.managementFeePassThrough = undefined;
            store.selectedOpenClass()!.mgmtFeeRateType = undefined;
            store.selectedOpenClass()!.payable = undefined;
            store.selectedOpenClass()!.payableDescription = undefined;
            store.selectedOpenClass()!.hasManagementFeeDiscounts = undefined;
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Payable',
            });
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Payable Description',
            });
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Has Open Management Fee Discounts',
            });
          }

          updateMgmtFeeRateType(store.selectedOpenClass()!.mgmtFeeRateType);
          updateHasManagementFeeDiscounts(
            store.selectedOpenClass()!.hasManagementFeeDiscounts,
          );

          updateShareClass({
            hasManagementFee: value as Enums.SimpleAnswerPassEnum,
          });
        };

        const updateMgmtFeeRateType = (value: unknown) => {
          store
            .selectedOpenClass()
            ?.mgmtFeeRates?.forEach((rate: MgmtFeeRate) =>
              removeOpenMgmtFeeRate(rate),
            );
          if (value) {
            addOpenMgmtFeeRate();
          }

          updateShareClass({
            mgmtFeeRateType: value as Enums.MgmtFeeRateTypeEnum,
          });
        };

        const addOpenMgmtFeeRate = () => {
          let mgmtFeeRate = new MgmtFeeRate();

          store.selectedOpenClass()!.mgmtFeeRates = [
            ...(store.selectedOpenClass()!.mgmtFeeRates ?? []),
            mgmtFeeRate,
          ];

          updateShareClass({
            mgmtFeeRates: store.selectedOpenClass()!.mgmtFeeRates,
          });
        };

        const removeOpenMgmtFeeRate = (rate: MgmtFeeRate) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedOpenClass()?.$uid!,
              id: rate.$uid!,
            },
          });
          rate.$isDeleted = true;
          if (rate.id! > 0) {
            rate.markedForDeletion = true;
          } else {
            store.selectedOpenClass()!.mgmtFeeRates = store
              .selectedOpenClass()!
              .mgmtFeeRates?.filter((r: MgmtFeeRate) => r !== rate);
          }

          updateShareClass({
            mgmtFeeRates: store.selectedOpenClass()!.mgmtFeeRates,
          });
        };

        const updatePayable = (value: unknown) => {
          if (value !== Enums.MgmtFeeFrequencyEnum.Other) {
            store.selectedOpenClass()!.payableDescription = undefined;
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Payable Description',
            });
          }
        };

        const updateHasManagementFeeDiscounts = (value: unknown) => {
          if (value === Enums.SimpleAnswerEnum.Yes) {
            addOpenManagementFeeDiscount();
          } else {
            store
              .selectedOpenClass()
              ?.managementFeeDiscounts?.forEach((discount: Discount) =>
                removeOpenManagementFeeDiscount(discount),
              );
          }

          updateShareClass({
            hasManagementFeeDiscounts: value as Enums.SimpleAnswerEnum,
            managementFeeDiscounts:
              store.selectedOpenClass()!.managementFeeDiscounts,
          });
        };

        const addOpenManagementFeeDiscount = () => {
          let discount = new Discount();

          store.selectedOpenClass()!.managementFeeDiscounts = [
            ...(store.selectedOpenClass()!.managementFeeDiscounts ?? []),
            discount,
          ];

          updateShareClass({
            managementFeeDiscounts:
              store.selectedOpenClass()!.managementFeeDiscounts,
          });
        };

        const removeOpenManagementFeeDiscount = (discount: Discount) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedOpenClass()?.$uid!,
              id: discount.$uid!,
            },
          });

          discount.$isDeleted = true;
          if (discount.id! > 0) {
            discount.markedForDeletion = true;
          } else {
            store.selectedOpenClass()!.managementFeeDiscounts = store
              .selectedOpenClass()!
              .managementFeeDiscounts?.filter((d: Discount) => d !== discount);
          }

          updateShareClass({
            managementFeeDiscounts:
              store.selectedOpenClass()!.managementFeeDiscounts,
          });
        };

        const updateHasIncentiveFee = (value: unknown) => {
          if (value === Enums.SimpleAnswerEnum.Yes) {
            store.selectedOpenClass()!.incFeeRateType =
              Enums.IncFeeRateTypeEnum.SingleRate;
            updateIncFeeRateType(store.selectedOpenClass()!.incFeeRateType);
          } else {
            store.selectedOpenClass()!.incFeeRateType = undefined;
            updateIncFeeRateType(store.selectedOpenClass()!.incFeeRateType);
            store.removeValidation({
              validationId: store.selectedOpenClass()?.$uid!,
              tag: 'Incentive Fee Rate Type',
            });

            store.selectedOpenClass()!.hasIncentiveFeeDiscounts = undefined;
            store.removeValidation({
              validationId: store.selectedOpenClass()!.$uid!,
              tag: 'Has Open Incentive Fee Discounts',
            });
          }
        };

        const updateIncFeeRateType = (value: unknown) => {
          store
            .selectedOpenClass()
            ?.incFeeRates?.forEach((rate: IncFeeRate) =>
              removeIncFeeRate(rate),
            );
          if (value) {
            addIncFeeRate();
            if (value === Enums.IncFeeRateTypeEnum.TieredRate) {
              addIncFeeRate();
            } else if (value === Enums.IncFeeRateTypeEnum.SlidingScaleRate) {
              store.selectedOpenClass()!.incFeeRates!.at(0)!.hasHurdle =
                Enums.SimpleAnswerEnum.Yes;
            }
          } else {
            store.selectedOpenClass()!.hasIncentiveFeeDiscounts = undefined;
            store.selectedOpenClass()!.crystalization = undefined;
            store.selectedOpenClass()!.crystalizationPeriod = undefined;
            store.selectedOpenClass()!.highwaterMark = undefined;
            store.removeValidation([
              {
                validationId: store.selectedOpenClass()?.$uid!,
                tag: 'Crystalization',
              },
              {
                validationId: store.selectedOpenClass()?.$uid!,
                tag: 'Crystalization Period',
              },
              {
                validationId: store.selectedOpenClass()?.$uid!,
                tag: 'High watermark',
              },
              {
                validationId: store.selectedOpenClass()?.$uid!,
                tag: 'Has Incentive Fee Discounts',
              },
            ]);

            updateHasIncentiveFeeDiscounts(
              store.selectedOpenClass()?.hasIncentiveFeeDiscounts,
            );
          }

          updateShareClass({
            incFeeRateType: value as Enums.IncFeeRateTypeEnum,
          });
        };

        const addIncFeeRate = () => {
          let incFeeRate = new IncFeeRate();

          store.selectedOpenClass()!.incFeeRates = [
            ...(store.selectedOpenClass()!.incFeeRates ?? []),
            incFeeRate,
          ];

          updateShareClass({
            incFeeRates: store.selectedOpenClass()!.incFeeRates,
          });
        };

        const removeIncFeeRate = (rate: IncFeeRate) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedClass()?.$uid!,
              id: rate.$uid!,
            },
          });
          rate.$isDeleted = true;
          if (rate.id! > 0) {
            rate.markedForDeletion = true;
          } else {
            store.selectedOpenClass()!.incFeeRates = store
              .selectedOpenClass()!
              .incFeeRates?.filter((r: IncFeeRate) => r !== rate);
          }

          updateShareClass({
            mgmtFeeRates: store.selectedOpenClass()!.mgmtFeeRates,
          });
        };

        const updateHasHurdle = (feeRate: IncFeeRate, value: unknown) => {
          let i = store.selectedOpenClass()?.incFeeRates?.indexOf(feeRate)!;
          if (value !== Enums.SimpleAnswerEnum.Yes) {
            feeRate!.hurdleRateType = undefined;
            store.removeValidation({
              validationId: feeRate.$uid!,
              tag: `Hurdle Rate Type #${i + 1}`,
            });
            updateHurdleRateType(feeRate, undefined);
          }
        };

        const updateHurdleRateType = (feeRate: IncFeeRate, value: unknown) => {
          let i = store.selectedOpenClass()?.incFeeRates?.indexOf(feeRate)!;

          if (
            value !== Enums.RateIndexEnum.FixedRate &&
            value !== Enums.RateIndexEnum.IndexPlusFixedRate
          ) {
            feeRate!.hurdleRate = undefined;
            feeRate!.hurdleMinRatePercentage = undefined;
            feeRate!.hurdleMaxRatePercentage = undefined;

            store.removeValidation([
              { validationId: feeRate.$uid!, tag: `Hurdle Rate #${i + 1}` },
              {
                validationId: feeRate.$uid!,
                tag: `Hurdle Min Rate Percentage #${i + 1}`,
              },
              {
                validationId: feeRate.$uid!,
                tag: `Hurdle Max Rate Percentage #${i + 1}`,
              },
            ]);
          }

          if (
            value !== Enums.RateIndexEnum.Index &&
            value !== Enums.RateIndexEnum.IndexPlusFixedRate
          ) {
            feeRate!.hurdleIndex = undefined;
            feeRate!.hurdleIndexDesc = undefined;

            store.removeValidation([
              { validationId: feeRate.$uid!, tag: `Hurdle Index #${i + 1}` },
              {
                validationId: feeRate.$uid!,
                tag: `Hurdle Index Desc #${i + 1}`,
              },
            ]);
          }
        };

        const updateHasIncentiveFeeDiscounts = (value: unknown) => {
          if (value === Enums.SimpleAnswerEnum.Yes) {
            addIncFeeDiscount();
          } else {
            store
              .selectedOpenClass()
              ?.incentiveFeeDiscounts?.forEach((discount: Discount) =>
                removeIncFeeDiscount(discount),
              );
          }

          updateShareClass({
            hasIncentiveFeeDiscounts: value as Enums.SimpleAnswerEnum,
            incentiveFeeDiscounts:
              store.selectedOpenClass()!.incentiveFeeDiscounts,
          });
        };

        const addIncFeeDiscount = () => {
          let discount = new Discount();

          store.selectedOpenClass()!.incentiveFeeDiscounts = [
            ...(store.selectedOpenClass()!.incentiveFeeDiscounts ?? []),
            discount,
          ];

          updateShareClass({
            incentiveFeeDiscounts:
              store.selectedOpenClass()!.incentiveFeeDiscounts,
          });
        };

        const removeIncFeeDiscount = (discount: Discount) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedOpenClass()?.$uid!,
              id: discount.$uid!,
            },
          });
          discount.$isDeleted = true;
          if (discount.id! > 0) {
            discount.markedForDeletion = true;
          } else {
            store.selectedOpenClass()!.incentiveFeeDiscounts = store
              .selectedOpenClass()!
              .incentiveFeeDiscounts?.filter((d: Discount) => d !== discount);
          }

          updateShareClass({
            incentiveFeeDiscounts:
              store.selectedOpenClass()!.incentiveFeeDiscounts,
          });
        };

        //#endregion

        //#endregion

        //#region Closed Share Classes

        const updateMgmtFee = (value: unknown) => {
          if (value !== Enums.SimpleAnswerEnum.Yes) {
            store
              .selectedClosedClass()!
              .mgmtFeePeriods?.forEach((period) => removeMgmtFeePeriod(period));
            store.selectedClosedClass()!.paymentFrequency = undefined;
            store.selectedClosedClass()!.paymentFrequencyDescription =
              undefined;
            store.selectedClosedClass()!.mgmtFeeOffsetRate = undefined;
            store.selectedClosedClass()!.hasMgmtFeeDiscounts = undefined;
            updateHasMgmtFeeDiscounts(undefined);

            store.removeValidation([
              {
                validationId: store.selectedClosedClass()!.$uid!,
                tag: 'Payment Frequency',
              },
              {
                validationId: store.selectedClosedClass()!.$uid!,
                tag: 'Management Fee Offset Rate',
              },
              {
                validationId: store.selectedClosedClass()!.$uid!,
                tag: 'Has Mgmt Fee Discounts',
              },
            ]);
          } else {
            addMgmtFeePeriod();
          }
        };

        const updateMgmtFeeTimeFrom = (
          period: MgmtFeePeriod,
          value: unknown,
        ) => {
          if (!value) {
            period.mgmtFeeTimeFromDesc = undefined;
          }
        };

        const updateMgmtFeeTimeTo = (period: MgmtFeePeriod, value: unknown) => {
          if (!value) {
            period.mgmtFeeTimeToDesc = undefined;
          }
        };

        const addMgmtFeePeriod = () => {
          let mgmtFeePeriod = new MgmtFeePeriod();
          const lastPeriod = store.filteredMgmtFeePeriods()?.at(-1);
          if (lastPeriod) {
            const from = Enums.MgmtFeeFromEnum[
              Enums.MgmtFeeToEnum[
                lastPeriod.mgmtFeeTimeTo! as number
              ] as unknown as number
            ] as unknown as number;
            mgmtFeePeriod.mgmtFeeTimeFrom = from;
            if (lastPeriod.mgmtFeeTimeTo === Enums.MgmtFeeToEnum.Other) {
              mgmtFeePeriod.mgmtFeeTimeFromDesc = lastPeriod.mgmtFeeTimeToDesc;
            }
          }

          addMgmtFeeAmount(mgmtFeePeriod);

          store.selectedClosedClass()!.mgmtFeePeriods = [
            ...(store.selectedClosedClass()!.mgmtFeePeriods ?? []),
            mgmtFeePeriod,
          ];

          updateShareClass({
            mgmtFeePeriods: store.selectedClosedClass()!.mgmtFeePeriods,
          });
        };

        const removeMgmtFeePeriod = (period: MgmtFeePeriod) => {
          period.mgmtFeeAmounts?.forEach((amount) => {
            removeMgmtFeeAmount(period, amount);
          });

          let index = store
            .selectedClosedClass()
            ?.mgmtFeePeriods?.indexOf(period)!;
          store.removeValidation({
            validationId: {
              groupId: store.selectedClass()?.$uid!,
              id: period.$uid!,
            },
          });
          period.$isDeleted = true;
          if (period.id! > 0) {
            period.markedForDeletion = true;
          } else {
            store.selectedClosedClass()!.mgmtFeePeriods = store
              .selectedClosedClass()!
              .mgmtFeePeriods?.filter((p: MgmtFeePeriod) => p !== period);
          }

          updateShareClass({
            mgmtFeePeriods: store.selectedClosedClass()!.mgmtFeePeriods,
          });

          store.validate(
            [
              ...store.mgmtFeeTimeFrom_validator_chain()!,
              ...store.mgmtFeeTimeTo_validator_chain()!,
              ...store.mgmtFeeTimeFromDate_validator_chain()!,
              ...store.mgmtFeeTimeToDate_validator_chain()!,
            ],
            undefined,
            true,
          );
        };

        const updateMgmtFeeCalcOnType = (
          period: MgmtFeePeriod,
          amount: MgmtFeeAmount,
          value: unknown,
        ) => {
          if (value !== Enums.MgmtFeeCalcOnEnum.other_amount) {
            let i = store
              .selectedClosedClass()
              ?.mgmtFeePeriods?.indexOf(period)!;
            let j = period.mgmtFeeAmounts?.indexOf(amount)!;
            amount.mgmtFeeCalcOnDesc = undefined;
            store.removeValidation({
              validationId: {
                groupId: store.selectedClass()?.$uid!,
                id: amount.$uid!,
              },
              tag:
                'Management Fee Period #' +
                (i + 1) +
                ' - Calc On Desc #' +
                (j + 1),
            });
          }
          if (
            ![
              Enums.MgmtFeeCalcOnEnum.actively_invested_capital,
              Enums.MgmtFeeCalcOnEnum
                .actively_invested_capital_plus_unfunded_commitments,
              Enums.MgmtFeeCalcOnEnum.total_invested_capital,
              Enums.MgmtFeeCalcOnEnum
                .total_invested_capital_plus_unfunded_commitments,
              Enums.MgmtFeeCalcOnEnum.lesser_of_NAV_and_total_invested_capital,
              Enums.MgmtFeeCalcOnEnum
                .lesser_of_NAV_and_actively_invested_capital,
              Enums.MgmtFeeCalcOnEnum
                .lesser_of_actively_invested_capital_and_committed_capital,
              Enums.MgmtFeeCalcOnEnum
                .lesser_of_total_invested_capital_and_committed_capital,
            ].includes(value as Enums.MgmtFeeCalcOnEnum)
          ) {
            amount.useOfLeverage = undefined;
          }
        };

        const addMgmtFeeAmount = (period: MgmtFeePeriod) => {
          let mgmtFeeAmount = new MgmtFeeAmount();

          period.mgmtFeeAmounts = [
            ...(period.mgmtFeeAmounts ?? []),
            mgmtFeeAmount,
          ];

          updateShareClass({
            mgmtFeePeriods: store.selectedClosedClass()!.mgmtFeePeriods,
          });
        };

        const removeMgmtFeeAmount = (
          period: MgmtFeePeriod,
          amount: MgmtFeeAmount,
        ) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedClass()?.$uid!,
              id: amount.$uid!,
            },
          });

          amount.$isDeleted = true;
          if (amount.id! > 0) {
            amount.markedForDeletion = true;
          } else {
            period.mgmtFeeAmounts = period.mgmtFeeAmounts?.filter(
              (a: MgmtFeeAmount) => a !== amount,
            );
          }

          updateShareClass({
            mgmtFeePeriods: store.selectedClosedClass()!.mgmtFeePeriods,
          });

          store.validate(
            [
              ...store.mgmtFeeRate_validator_chain()!,
              ...store.mgmtFeeCalcOnType_validator_chain()!,
            ],
            undefined,
            true,
          );
        };

        const updatePaymentFrequency = (value: unknown) => {
          if (value !== Enums.MgmtFeeFrequencyEnum.Other) {
            store.selectedClosedClass()!.paymentFrequencyDescription =
              undefined;
          }
        };

        const updateHasMgmtFeeDiscounts = (value: unknown) => {
          if (value === Enums.SimpleAnswerEnum.Yes) {
            addClosedManagementFeeDiscount();
          } else {
            store
              .selectedClosedClass()
              ?.mgmtFeeDiscounts?.forEach((discount: Discount) =>
                removeClosedManagementFeeDiscount(discount),
              );
          }

          updateShareClass({
            hasMgmtFeeDiscounts: value as Enums.SimpleAnswerEnum,
            mgmtFeeDiscounts: store.selectedClosedClass()!.mgmtFeeDiscounts,
          });
        };

        const addClosedManagementFeeDiscount = () => {
          let discount = new Discount();

          store.selectedClosedClass()!.mgmtFeeDiscounts = [
            ...(store.selectedClosedClass()!.mgmtFeeDiscounts ?? []),
            discount,
          ];

          updateShareClass({
            mgmtFeeDiscounts: store.selectedClosedClass()!.mgmtFeeDiscounts,
          });
        };

        const removeClosedManagementFeeDiscount = (discount: Discount) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedClass()?.$uid!,
              id: discount.$uid!,
            },
          });
          discount.$isDeleted = true;
          if (discount.id! > 0) {
            discount.markedForDeletion = true;
          } else {
            store.selectedClosedClass()!.mgmtFeeDiscounts = store
              .selectedClosedClass()!
              .mgmtFeeDiscounts?.filter((d: Discount) => d !== discount);
          }

          updateShareClass({
            managementFeeDiscounts:
              store.selectedClosedClass()!.mgmtFeeDiscounts,
          });
        };

        const updateCarriedInterest = (value: unknown) => {
          if (value !== Enums.SimpleAnswerEnum.Yes) {
            store.selectedClosedClass()!.carriedInterestRateType = undefined;
            updateCarriedInterestRateType(undefined);

            store.selectedClosedClass()!.carriedInterestPrefReturnType =
              undefined;
            store.selectedClosedClass()!.carriedInterestRate = undefined;
            store.selectedClosedClass()!.carriedInterestPrefReturnRate =
              undefined;
            store.selectedClosedClass()!.carriedInterestCatchUpRate = undefined;
            store.selectedClosedClass()!.carriedInterestTieredBaseType =
              undefined;
            store.selectedClosedClass()!.carriedInterestWaterfall = undefined;
            store.selectedClosedClass()!.gpClawback = undefined;

            store.selectedClosedClass()!.hasCarriedInterestDiscounts =
              undefined;
          }
        };

        const updateCarriedInterestRateType = (value: unknown) => {
          if (value !== Enums.CarriedInterestRateTypeEnum.TieredRate) {
            store.selectedClosedClass()!.carriedInterestTieredBaseType =
              undefined;
            updateCarriedInterestTieredBaseType(undefined);
          }
          if (value !== Enums.CarriedInterestRateTypeEnum.SingleRate) {
            store.selectedClosedClass()!.carriedInterestRate = undefined;
            store.validate(
              store.carriedInterestTieredFeeRate_validator_chain(),
              undefined,
              true,
            );
          }
        };

        const updateCarriedInterestTieredBaseType = (value: unknown) => {
          store
            .selectedClosedClass()
            ?.carriedInterestTieredRates?.forEach(
              (interest: CarriedInterestTieredRate) => {
                removeCarriedInterestTieredRate(interest);
              },
            );
          if (value) {
            addCarriedInterestTieredRate();
            addCarriedInterestTieredRate();
          }
        };

        const addCarriedInterestTieredRate = () => {
          let interest = new CarriedInterestTieredRate();

          store.selectedClosedClass()!.carriedInterestTieredRates = [
            ...(store.selectedClosedClass()!.carriedInterestTieredRates ?? []),
            interest,
          ];

          updateShareClass({
            carriedInterestTieredRates:
              store.selectedClosedClass()!.carriedInterestTieredRates,
          });
        };

        const removeCarriedInterestTieredRate = (
          interest: CarriedInterestTieredRate,
        ) => {
          store.removeValidation({
            validationId: {
              groupId: store.selectedClosedClass()?.$uid!,
              id: interest.$uid!,
            },
          });
          interest.$isDeleted = true;
          if (interest.id! > 0) {
            interest.markedForDeletion = true;
          } else {
            store.selectedClosedClass()!.carriedInterestTieredRates = store
              .selectedClosedClass()!
              .carriedInterestTieredRates?.filter(
                (i: CarriedInterestTieredRate) => i !== interest,
              );
          }

          updateShareClass({
            carriedInterestTieredRates:
              store.selectedClosedClass()!.carriedInterestTieredRates,
          });

          store.validate(
            [
              ...store.carriedInterestTieredFeeRate_validator_chain()!,
              ...store.carriedInterestTieredPrefReturn_validator_chain()!,
              ...store.carriedInterestTieredMultiple_validator_chain()!,
            ],
            undefined,
            true,
          );
        };

        const updateCarriedInterestTieredCatchup = (
          interest: CarriedInterestTieredRate,
          value: unknown,
        ) => {
          if (value !== Enums.SimpleAnswerEnum.Yes) {
            let i = store
              .selectedClosedClass()
              ?.carriedInterestTieredRates?.indexOf(interest)!;

            interest.catchupRate = undefined;

            store.removeValidation({
              validationId: interest.$uid!,
              tag: 'Carried Interest Catch-up Rate #' + (i + 1),
            });
          }
        };

        const updateCarriedInterestPrefReturnType = (value: unknown) => {
          if (
            value !== Enums.PrefReturnEnum.YesWithCatch_Up &&
            value !== Enums.PrefReturnEnum.YesWithoutCatch_Up
          ) {
            store.selectedClosedClass()!.carriedInterestPrefReturnRate =
              undefined;
            store.selectedClosedClass()!.carriedInterestCatchUpRate = undefined;

            store.removeValidation([
              {
                validationId: store.selectedClosedClass()!.$uid!,
                tag: 'Carried Interest Pref Return Rate',
              },
              {
                validationId: store.selectedClosedClass()!.$uid!,
                tag: 'Carried Interest Catch-Up Rate',
              },
            ]);
          } else if (value === Enums.PrefReturnEnum.YesWithoutCatch_Up) {
            store.selectedClosedClass()!.carriedInterestCatchUpRate = undefined;

            store.removeValidation({
              validationId: store.selectedClosedClass()!.$uid!,
              tag: 'Carried Interest Catch-Up Rate',
            });
          }
        };

        const updateCarriedInterestWaterfall = (value: unknown) => {
          if (value !== Enums.WaterfallEnum.Other) {
            store.selectedClosedClass()!.carriedInterestWaterfallDesc =
              undefined;
            store.removeValidation({
              validationId: store.selectedClosedClass()!.$uid!,
              tag: 'Carried Interest Waterfall Desc',
            });
          }
          if (value !== Enums.WaterfallEnum.American___Fair_Value_Test__) {
            store.selectedClosedClass()!.carriedInterestWaterfallFairValueTest =
              undefined;
            store.removeValidation({
              validationId: store.selectedClosedClass()!.$uid!,
              tag: 'Carried Interest Waterfall Fair Value Test',
            });
          }
        };

        const updateGpClawback = (value: unknown) => {
          if (value !== Enums.SimpleAnswerEnum.Yes) {
            store.selectedClosedClass()!.interimCalculations = undefined;
            store.selectedClosedClass()!.escrowAccount = undefined;
            updateEscrowAccount(undefined);
            store.selectedClosedClass()!.clawbackGuaranteeType = undefined;

            store.removeValidation([
              {
                validationId: store.selectedClosedClass()!.$uid!,
                tag: 'Interim Calculations',
              },
              {
                validationId: store.selectedClosedClass()!.$uid!,
                tag: 'Escrow Account',
              },
              {
                validationId: store.selectedClosedClass()!.$uid!,
                tag: 'Clawback Guarantee Type',
              },
            ]);
          }
        };

        const updateEscrowAccount = (value: unknown) => {
          if (value !== Enums.SimpleAnswerEnum.Yes) {
            store.selectedClosedClass()!.escrowAccountRate = undefined;

            store.removeValidation({
              validationId: store.selectedClosedClass()!.$uid!,
              tag: 'Escrow Account Rate',
            });
          }
        };

        const updateHasCarriedInterestDiscounts = (value: unknown) => {
          if (value === Enums.SimpleAnswerEnum.Yes) {
            addCarriedInterestDiscount();
          } else {
            store
              .selectedClosedClass()
              ?.carriedInterestDiscounts?.forEach((discount: Discount) =>
                removeCarriedInterestDiscount(discount),
              );
          }

          updateShareClass({
            hasCarriedInterestDiscounts: value as Enums.SimpleAnswerEnum,
          });
        };

        const addCarriedInterestDiscount = () => {
          let discount = new Discount();

          store.selectedClosedClass()!.carriedInterestDiscounts = [
            ...(store.selectedClosedClass()!.carriedInterestDiscounts ?? []),
            discount,
          ];

          updateShareClass({
            carriedInterestDiscounts:
              store.selectedClosedClass()!.carriedInterestDiscounts,
          });
        };

        const removeCarriedInterestDiscount = (discount: Discount) => {
          store.removeValidation({ validationId: discount.$uid! });
          discount.$isDeleted = true;
          if (discount.id! > 0) {
            discount.markedForDeletion = true;
          } else {
            store.selectedClosedClass()!.carriedInterestDiscounts = store
              .selectedClosedClass()!
              .carriedInterestDiscounts?.filter(
                (d: Discount) => d !== discount,
              );
          }

          updateShareClass({
            carriedInterestDiscounts:
              store.selectedClosedClass()!.carriedInterestDiscounts,
          });
        };

        //#endregion

        return {
          loadShareClasses,
          saveShareClasses,

          //Common Share Classes
          updateSelectedClassUId,
          updateShareClass,
          addShareClass,
          removeShareClass,
          renameShareClass,
          updateSelectedClassDefault,

          //Open Share Classes
          updateHasSidePocket,
          addSidePocket,
          removeSidePocket,
          updateHasSubscriptionFee,
          updateHasLockup,
          updateLockupType,
          addLockup,
          removeLockup,
          updateRedemptionTerms,
          updateHasGate,
          updateGateLevel,
          addGate,
          removeGate,
          updateHasManagementFee,
          updateMgmtFeeRateType,
          addOpenMgmtFeeRate,
          removeOpenMgmtFeeRate,
          updatePayable,
          updateHasManagementFeeDiscounts,
          addOpenManagementFeeDiscount,
          removeOpenManagementFeeDiscount,
          updateHasIncentiveFee,
          updateIncFeeRateType,
          addIncFeeRate,
          removeIncFeeRate,
          updateHasHurdle,
          updateHurdleRateType,
          updateHasIncentiveFeeDiscounts,
          addIncFeeDiscount,
          removeIncFeeDiscount,

          //Closed Share Classes
          updateMgmtFee,
          updateMgmtFeeTimeFrom,
          updateMgmtFeeTimeTo,
          addMgmtFeePeriod,
          removeMgmtFeePeriod,
          updateMgmtFeeCalcOnType,
          addMgmtFeeAmount,
          removeMgmtFeeAmount,
          updatePaymentFrequency,
          updateHasMgmtFeeDiscounts,
          addClosedManagementFeeDiscount,
          removeClosedManagementFeeDiscount,
          updateCarriedInterest,
          updateCarriedInterestRateType,
          updateCarriedInterestTieredBaseType,
          addCarriedInterestTieredRate,
          removeCarriedInterestTieredRate,
          updateCarriedInterestTieredCatchup,
          updateCarriedInterestPrefReturnType,
          updateCarriedInterestWaterfall,
          updateGpClawback,
          updateEscrowAccount,
          updateHasCarriedInterestDiscounts,
          addCarriedInterestDiscount,
          removeCarriedInterestDiscount,
        };
      },
    ),
    withHooks({
      onInit(store) {
        store.registerMethod(store.loadShareClasses, 2);
        console.log(
          `%c ShareClassStore initialized`,
          'background: #222; color: orange;',
          store,
        );
      },
    }),
  );
}
