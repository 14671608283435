@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  @for (sectionGroup of sectionGroups(); track $index) {
    <section-group
      [contentCssClass]="sectionGroup.contentCssClass"
      [settings]="{
        label: sectionGroup.settings.label,
      }"
    >
      <!--#region Entity Fields-->
      @if (sectionGroup.settings.label === 'Structure') {
        <dropdown
          class="c1s1"
          [(value)]="iv.liquidityStructure"
          [settings]="{
            label: 'Liquidity Structure',
            labelTip: 'Reflects layout of MAX fund page',
            options: ENUMS.LiquidityStructureEnum.toOptions,
            optionsFiltering: false,
            showClearButton: false,
          }"
        ></dropdown>
        <dropdown
          class="c2s1"
          [(value)]="iv.vEntityType"
          [settings]="{
            label: 'Entity Type',
            options: ENUMS.VEntityTypeEnum.toOptions,
          }"
        ></dropdown>
        <dropdown
          class="c1s1"
          [(value)]="iv.liquidityType"
          [settings]="{
            label: 'Liquidity Type',
            options: ENUMS.LiquidityStructureEnum.toOptions,
            optionsFiltering: false,
            labelTip: 'Liquidity structure used in MAX tools',
          }"
        ></dropdown>
        @if (iv.liquidityStructure === ENUMS.LiquidityStructureEnum.OpenEnd) {
          <dropdown
            class="c2s1"
            [(value)]="iv.openEndStatus"
            [settings]="{
              label: 'Hedge Fund Status',
              options: ENUMS.HedgeFundStructureEnum.toOptions,
              optionsFiltering: false,
            }"
          ></dropdown>
        } @else if (
          iv.liquidityStructure === ENUMS.LiquidityStructureEnum.ClosedEnd &&
          layout.selectedView() === VIEWS.LEGAL_DOC
        ) {
          <dropdown
            class="c2s1"
            [(value)]="iv.mfnProvision"
            [settings]="{
              label: 'MFN Provision',
              labelTip:
                'Reflects the existence of a most favored nations provision.',
              options: ENUMS.SimpleAnswerEnum.toOptions,
              optionsFiltering: false,
            }"
          ></dropdown>
        }
        <dropdown
          class="c1s1"
          [(value)]="iv.legalStructure"
          [settings]="{
            label: 'Fund Structure',
            options: ENUMS.FundStructureEnum.toOptions,
            optionsFiltering: false,
          }"
          (valueChange)="ivStore.updateLegalStructure($event)"
        ></dropdown>

        @if (iv.legalStructure === ENUMS.FundStructureEnum.Stand_alone) {
          <check
            class="c2s1 check"
            [(value)]="iv.actsAsMasterFund"
            [settings]="{ label: 'Acts as Master Fund' }"
          ></check>
        }
        @if (
          iv.legalStructure === ENUMS.FundStructureEnum.Master_feeder ||
          iv.legalStructure === ENUMS.FundStructureEnum.Intermediate__Master ||
          iv.legalStructure === ENUMS.FundStructureEnum.UmbrellaMaster_feeder ||
          iv.legalStructure === ENUMS.FundStructureEnum.MultipleMasters
        ) {
          @for (
            masterFund of iv.masterFunds;
            track masterFund.$uid;
            let i = $index, c = $count
          ) {
            <text
              class="c2s2"
              [(value)]="masterFund.name"
              [settings]="{
                label:
                  iv.legalStructure === ENUMS.FundStructureEnum.MultipleMasters
                    ? 'Master Fund #' + (i + 1)
                    : 'Master Fund',
              }"
            >
            </text>
            <div class="flex place-self-stretch c4s1 gap-x-md">
              @if (i > 1) {
                <btn
                  class="place-self-stretch"
                  [settings]="{
                    icon: ICONS.remove,
                    iconOnly: true,
                    iconSize: 'normal',
                    tag: 'Remove Master Fund #' + (i + 1),
                  }"
                  (click)="ivStore.removeMasterFund(masterFund!)"
                ></btn>
              }
              @if (
                i === c - 1 &&
                iv.legalStructure === ENUMS.FundStructureEnum.MultipleMasters
              ) {
                <btn
                  class="place-self-stretch"
                  [settings]="{
                    icon: ICONS.add,
                    iconOnly: true,
                    iconSize: 'normal',
                    tag: 'Add Master Fund',
                  }"
                  (click)="ivStore.addMasterFund()"
                ></btn>
              }
            </div>
          }
        }
      } @else if (sectionGroup.settings.label === 'Responsible Investing') {
        <number
          class="c2s1 r2s1"
          [(value)]="iv.esgWomanMinorityDisabledOwnership"
          [settings]="{
            label: 'Woman/Minority Ownership %',
            placeholder: '%',
            suffix: ' %',
            permitZero: true,
          }"
          (ValueChanged)="ivStore.updateEsgOwnership($event)"
        ></number>
        <number
          class="c3s1 r2s1"
          [(value)]="iv.womanOwnership"
          [settings]="{
            label: 'Woman Ownership %',
            placeholder: '%',
            suffix: ' %',
            permitZero: true,
          }"
        ></number>
        <number
          class="c4s1 r2s1"
          [(value)]="iv.minorityOwnership"
          [settings]="{
            label: 'Minority Ownership %',
            placeholder: '%',
            suffix: ' %',
            permitZero: true,
          }"
        ></number>
      }
      <!--#endregion-->

      @for (item of sectionGroup.content; track $index) {
        @if (item.control === 'multi-dropdown') {
          <multi-dropdown
            [class]="item?.class"
            [settings]="{
              label: item?.settings?.label,
              optionValue: 'id',
              optionLabel: 'name',
              options: item?.settings?.options,
              storeOnlyValue: true,
            }"
            [initialValue]="iv.quantumTags | tag: item?.settings?.tag : 'multi'"
            (OptionIsSelected)="
              ivStore.selectTag($event, item?.settings?.options)
            "
          ></multi-dropdown>
        } @else {
          <dropdown
            [class]="item?.class"
            [settings]="{
              label: item?.settings?.label,
              optionValue: 'id',
              optionLabel: 'name',
              options: item?.settings?.options,
              optionsFiltering: item?.settings?.optionsFiltering,
              disabled: item?.settings?.disabled,
            }"
            [initialValue]="
              iv.quantumTags | tag: item?.settings?.tag : 'single'
            "
            (OptionIsSelected)="
              ivStore.selectTag($event, item?.settings?.options)
            "
          ></dropdown>
        }
      }
    </section-group>
  }
}
