import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../static-content.directive';
import {
  ButtonComponent,
  CheckComponent,
  DateComponent,
  DropdownComponent,
  IconComponent,
  LocationComponent,
  ModalComponent,
  NumberComponent,
  SectionGroupComponent,
  SpreadsheetComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Capital Call Procedure"]',
  imports: [NumberComponent, TextComponent, DropdownComponent, CheckComponent],
  templateUrl: './capital-call-procedure.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CapitalCallProcedureContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Fund Terms"]',
  imports: [
    NumberComponent,
    TextComponent,
    DropdownComponent,
    DateComponent,
    ButtonComponent,
    CheckComponent,
  ],
  templateUrl: './fund-terms.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundTermsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Fund Timing"]',
  imports: [
    SectionGroupComponent,
    ButtonComponent,
    CheckComponent,
    DropdownComponent,
    NumberComponent,
    TextComponent,
    DateComponent,
  ],
  templateUrl: './fund-timing.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundTimingContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Gp Led Details"]',
  imports: [DropdownComponent, CheckComponent, NumberComponent],
  templateUrl: './gp-led-details.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GpLedDetailsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Investment Statistics"]',
  imports: [DropdownComponent, NumberComponent],
  templateUrl: './investment-statistics.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvestmentStatisticsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Key Considerations"]',
  imports: [DropdownComponent, CheckComponent],
  templateUrl: './key-considerations.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyConsiderationsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Key Terms"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    DateComponent,
    ButtonComponent,
    CheckComponent,
    NumberComponent,
    TextComponent,
    CheckComponent,
  ],
  templateUrl: './key-terms.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyTermsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Lp Clawback"]',
  imports: [ButtonComponent, DropdownComponent, NumberComponent],
  templateUrl: './lp-clawback.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LpClawbackContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Organizational Expense Cap"]',
  imports: [NumberComponent, DropdownComponent],
  templateUrl: './organizational-expense-cap.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationalExpenseCapContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Recycling"]',
  imports: [ButtonComponent, TextComponent, DropdownComponent, NumberComponent],
  templateUrl: './recycling.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecyclingContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Removal Termination Rights"]',
  imports: [NumberComponent, DropdownComponent],
  templateUrl: './removal-termination-rights.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemovalTerminationRightsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Return Profile"]',
  imports: [
    DropdownComponent,
    TextComponent,
    SectionGroupComponent,
    SpreadsheetComponent,
    ModalComponent,
    IconComponent,
    ButtonComponent,
    NumberComponent,
    DateComponent,
  ],
  templateUrl: './return-profile.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReturnProfileContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Successor Fund Provision"]',
  imports: [NumberComponent, DropdownComponent],
  templateUrl: './successor-fund-provision.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessorFundProvisionContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Syndication Capacity Notes"]',
  imports: [TextComponent, NumberComponent, DropdownComponent],
  templateUrl: './syndication-capacity-notes.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyndicationCapacityNotesContent extends StaticContentDirective {}

export const InvestmentVehicleClosedEndContents = [
  CapitalCallProcedureContent,
  FundTermsContent,
  FundTimingContent,
  GpLedDetailsContent,
  InvestmentStatisticsContent,
  KeyConsiderationsContent,
  KeyTermsContent,
  LpClawbackContent,
  OrganizationalExpenseCapContent,
  RecyclingContent,
  RemovalTerminationRightsContent,
  ReturnProfileContent,
  SuccessorFundProvisionContent,
  SyndicationCapacityNotesContent,
];
