@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <number
    class="c1s1"
    [(value)]="
      iv.closedEndDetails.closedEndTargetReturnProfile!.gpTargetReturnsGross
    "
    [settings]="{
      label: 'Target Gross IRR',
      placeholder: '%',
      suffix: ' %',
      tag: 'GP Target Returns Gross',
    }"
    [validators]="ivStore.gpTargetReturnsGross_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.gpTargetReturnsGross_validator_chain()"
  >
  </number>
  <number
    class="c2s1"
    [(value)]="
      iv.closedEndDetails.closedEndTargetReturnProfile!.gpTargetReturnsNet
    "
    [settings]="{
      label: 'Target Net IRR',
      placeholder: '%',
      suffix: ' %',
      tag: 'GP Target Returns Net',
    }"
    [validators]="ivStore.gpTargetReturnsNet_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.gpTargetReturnsNet_validator_chain()"
  >
  </number>
  <number
    class="c3s1"
    [(value)]="
      iv.closedEndDetails.closedEndTargetReturnProfile!.gpTargetGrossMultiple
    "
    [settings]="{
      label: 'Target Gross TVPI',
      placeholder: 'x',
      suffix: 'x',
      tag: 'GP Target Gross Multiple',
    }"
    [validators]="ivStore.gpTargetGrossMultiple_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.gpTargetGrossMultiple_validator_chain()"
  >
  </number>
  <number
    class="c4s1"
    [(value)]="
      iv.closedEndDetails.closedEndTargetReturnProfile!.gpTargetNetMultiple
    "
    [settings]="{
      label: 'Target Net TVPI',
      placeholder: 'x',
      suffix: 'x',
      tag: 'GP Target Net Multiple',
    }"
    [validators]="ivStore.gpTargetNetMultiple_validators()"
    [validation.id]="iv.$uid"
    [validation.chain]="ivStore.gpTargetNetMultiple_validator_chain()"
  >
    >
  </number>
  @if (ivStore.streamStartDate()) {
    <section-group class="c1s4" [settings]="{ label: 'Historical' }">
      <date
        class="c3s1"
        [attr.title]="
          'Changing the As of, will discard all unsaved historical values for the previous As Of'
        "
        [settings]="{
          label: 'As of',
          selection: DATE_SELECTION.Month,
          labelTip:
            'Changing the As of, will discard all unsaved periodic values for the previous As Of',
          minDate: ivStore.streamStartDate(),
          maxDate: ivStore.streamEndDate(),
        }"
        (ValueChanged)="ivStore.loadStreamAsOf($event)"
      >
      </date>
      <dropdown
        class="c4s1"
        [attr.title]="
          'Changing the source, will update all unsaved periodic values for the selected As Of'
        "
        [settings]="{
          label: 'Source',
          options: ENUMS.StreamSourceEnum.toOptions,
          labelTip:
            'Changing the source, will update all unsaved periodic values for the selected As Of',
        }"
      >
      </dropdown>
      @for (
        stream of ivStore.streamEntities();
        track stream.$uid;
        let i = $index
      ) {
        <number
          [settings]="{
            label: stream?.streamType?.toString(),
            labelTip: !stream?.selectedDataPoint
              ? 'Please select an As of date first'
              : undefined,
          }"
          [(value)]="stream!.selectedDataPoint!.value"
        >
          <icon
            uitool
            [iconSize]="'normal'"
            [iconDefinition]="ICONS.SPREADSHEET"
            (click)="
              ivStore.loadStreamHistory(stream.streamType!);
              modal.open(stream?.streamType?.toString(), '', true, stream)
            "
          ></icon>
        </number>

        <modal
          #modal
          [contentCssClass]="
            'grid grid-cols-4 auto-rows-max w-[1000px] min-h-[500px] gap-x-xl gap-y-xl'
          "
        >
          <ng-container UiModalContent>
            @defer (when stream?.historyIsLoaded) {
              <div class="c1s3 flex justify-end gap-x-xl">
                <btn
                  class="justify-stretch"
                  [settings]="{ label: 'Export', tag: 'Export Stream' }"
                ></btn>
                <btn
                  class="justify-stretch"
                  [settings]="{ label: 'Import', tag: 'Import Stream' }"
                ></btn>
              </div>

              <dropdown
                class="c4s1"
                [attr.title]="
                  'Changing the source, will update all unsaved periodic values for the selected As Of'
                "
                [settings]="{
                  label: 'Source',
                  options: ENUMS.StreamSourceEnum.toOptions,
                  labelTip:
                    'Changing the source, will update all unsaved periodic values for the selected As Of',
                }"
              >
              </dropdown>
              <spreadsheet
                class="c1s4"
                [settings]="{
                  valueField: 'value',
                  deletedField: '$isDeleted',
                  cellCssField: '$cellCssClass',
                  inputMode: 'directly',
                  allowedValues: 'numeric',
                  columnTitles: MONTHS,
                  rowTitles: ivStore.getStreamYears(stream.streamType!),
                }"
                [data]="stream!.datapoints!"
              >
              </spreadsheet>
            }
          </ng-container>
        </modal>
      }

      <text
        class="c1s4 multi"
        [(value)]="iv.closedEndDetails.tvpiNotes"
        [settings]="{
          label: 'TVPI Notes',
          multiline: true,
          labelTip: 'Notes for labeling and/or circumstances of TVPI entry',
        }"
      >
      </text>
    </section-group>
  } @else {
    <div class="no-content">
      <span>Vintage is required in order to add/modify historical info!</span
      ><br /><span
        >Vintage for historical return streams on closed end vehicles is defined
        as following:</span
      ><br /><br /><span>1. Year of the earliest of:</span><br /><span>
        &nbsp;&nbsp; a. Date of 1st Management Fee Accrual</span
      ><br /><span> &nbsp;&nbsp; b. Date of 1st Drawdown Due Date</span
      ><br /><span> &nbsp;&nbsp; c. 1st Investment Date</span><br /><span>
        &nbsp;&nbsp; d. Commencement of Operations</span
      ><br /><span
        >2. Jan 1<sup>st</sup> of Vintage year provided by the manager</span
      ><br /><span
        >3. Jan 1<sup>st</sup> of Vintage year provided by a third party; if not
        populated</span
      ><br /><span
        >4. Jan 1<sup>st</sup> of First close date; if not populated</span
      ><br /><span
        >5. Jan 1<sup>st</sup> of Final close date; if not populated</span
      ><br /><span>6. Date of Formation</span>
    </div>
  }
  <dropdown
    class="c1s1"
    [(value)]="iv.closedEndDetails.medianQuartileRanking"
    [settings]="{
      label: 'Median Quartile Ranking',
      options: ENUMS.RankEnum.toOptions,
      optionsFiltering: false,
    }"
  >
  </dropdown>
}
