
@if(isReady()){
  <ckeditor
    [(ngModel)]="model"
    [config]="config"
    [editor]="Editor"
    (change)="isChanged = true"
    (focusout)="focusout()"
    ></ckeditor>
    
  <rephrase-modal 
    #rephraseModalRef
    (rephrase)="onRephrase($event)"
    >
  </rephrase-modal>
}