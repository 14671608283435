import { CommonModule } from '@angular/common';
import { Component, EventEmitter, model, Output, signal, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { fetchSuggestions, sendFeedback, summarizeSuggestions } from './rephrase-api';
import { IconComponent } from '../../../../presentation/icon/icon.component';
import { ICONS, UTILS } from '@aksia/infrastructure';
import { ModalComponent } from '../../../../layout/modal/modal.component';
import { RephraseModel } from './rephrase.model';

@Component({
  standalone: true,
  selector: 'rephrase-modal',
  imports: [ 
    FormsModule, 
    CommonModule, 
    IconComponent, 
    ModalComponent
  ],
  templateUrl: './rephrase-modal.component.html',
  styleUrl: './rephrase-modal.component.css'
})
export class RephraseModalComponent {
  
  @Output() rephrase = new EventEmitter<any>();
  
  rephraseObj = signal<RephraseModel | undefined>(undefined);
  title = signal('');
  showModal = signal(true);
  suggestions = signal<string[]>([]);
  feedbackKey = signal('');
  activeLike = signal<'like' | 'dislike' | ''>('');
  errorMessage = signal('');
  isLoading = signal(false);
  
  originalText = signal('');
  projectId = signal(-1);
  toneOptions = signal<any[]>([]);
  selectedTone = signal('');
  attributes = signal<any[]>([]);

  faSolidThumbsUp = ICONS.faSolidThumbsUp;
  faSolidThumbsDown = ICONS.faSolidThumbsDown;
  
  @ViewChild('uiModalRef') uiModal!: ModalComponent;
  
  open(rephraseObj: RephraseModel) {
    this.rephraseObj.set(rephraseObj);
    this.setupModal();
    this.uiModal?.open();
  }

  close() {
    this.originalText.set('');
    this.projectId.set(-1);
    this.toneOptions.set([]);
    this.selectedTone.set('');
    this.attributes.set([]);

    this.uiModal?.close();
  }

  setupModal() {
    const rephraseObj = this.rephraseObj();
    if(!rephraseObj) {
      return;
    }

    this.originalText.set(rephraseObj.originalText);
    this.projectId.set(rephraseObj.projectId);
    this.toneOptions.set(rephraseObj.toneOptions);
    this.selectedTone.set(rephraseObj.tone);
    this.attributes.set(rephraseObj.attributes);

    if(this.selectedTone() === 'Summarize'){
      this.title.set('Summarize');
      this.summarizeRequestByEvent();
    }else {
      this.title.set('Rephrase');
      this.rephraseRequestByEvent();
    }
  }

  onRephrase(suggestion: string) {
    this.rephrase.emit({ updatedText: suggestion, attributes: this.attributes() });
  }

  onToneChange(evt: any) {
    this.rephraseRequestByEvent();
  }

  private rephraseRequestByEvent() {
    this.reset();   
    fetchSuggestions(
      { 
        originalText: this.originalText(), 
        tone: this.selectedTone() 
      }, 
      this.projectId()
    )
      .then((result: any) => {
        if(result.success) {
          this.feedbackKey.set(result.feedbackKey);
          if(result.suggestions.length === 0) {
            this.errorMessage.set('No suggestions found');
          }else {
            this.suggestions.set(result.suggestions);
          }        
        } else {
          this.errorMessage.set('Failed to fetch suggestions');
        }
        this.isLoading.set(false);
      });
  }

  private summarizeRequestByEvent() {        
    this.reset();
    summarizeSuggestions(
      { 
        originalText: this.originalText()
      }, 
      this.projectId()
    ).then((result: any) => {
      if(result.success) {
        this.feedbackKey.set(result.feedbackKey);
        if(!result.summary) {
          this.errorMessage.set('No suggestions found');  
        }else {
          this.suggestions.set(result.summary);
        }        
      } else {
        this.errorMessage.set('Failed to fetch suggestions');
      }
      this.isLoading.set(false);
    });
  }

  private reset() {
    this.errorMessage.set('');
    this.suggestions.set([]);
    this.feedbackKey.set('');
    this.activeLike.set('');
    this.isLoading.set(true);
  }

  replaceSuggestion(suggestion: string) {
    this.onRephrase(suggestion);
  }

  copySuggestion(suggestion: string){    
    UTILS.EVENTS.copyToClipboard(suggestion);
    this.close();
  } 

  rateSuggestion(like: boolean = true) {    
    const feedbackRequest = {
      Key: this.feedbackKey,
      Feedback: like ? 'LIKE' : 'DISLIKE',
    };
    
    sendFeedback(feedbackRequest).then(() => {      
      this.activeLike.set(like ? 'like' : 'dislike');
    });
  }
}