<dialog
  #modal
  [style.width.px]="settings()?.width"
  [style.height.px]="settings()?.height"
>
  <div uimodalheader [class]="headerCssClass()">
    @if (label()) {
      <span uimodaltitle>{{ label() }}</span>
    }
    <ng-content select="[UiCustomModalHeader]"></ng-content>
    <div uitoolStrip>
      <ng-container>
        <icon
          uimodalclosebtn
          [iconDefinition]="ICONS.close"
          [iconSize]="'2x-large'"
          (click)="close()"
        ></icon>
      </ng-container>
    </div>
  </div>
  @if (component()) {
    <ng-template
      *ngComponentOutlet="component(); inputs: componentInput()"
    ></ng-template>
  } @else {
    <div uimodalcontent [class]="contentCssClass()">
      @if (text) {
        <p>{{ text }}</p>
      }
      <ng-content select="[UiModalContent]"></ng-content>
      <ng-container
        *ngTemplateOutlet="
          UiModalContentTemplateRef;
          context: { $implicit: bind }
        "
      ></ng-container>
    </div>
  }

  <div uimodalfooter [class]="footerCssClass()">
    <ng-content select="[UiModalFooter]"></ng-content>
    <ng-container
      *ngTemplateOutlet="UiModalFooterTemplateRef; context: { $implicit: bind }"
    ></ng-container>
    <ng-template #UiModalFooterCmps></ng-template>
  </div>
</dialog>
