import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StaticContentDirective } from '../../static-content.directive';
import {
  ButtonComponent,
  CheckComponent,
  DropdownComponent,
  NumberComponent,
  SectionGroupComponent,
  TextComponent,
} from '@aksia/ui';

@Component({
  selector: '[uisectioncontent="Fees"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    NumberComponent,
    TextComponent,
    ButtonComponent,
  ],
  templateUrl: './fees.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeesContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Redemptions"]',
  imports: [
    SectionGroupComponent,
    DropdownComponent,
    NumberComponent,
    CheckComponent,
    ButtonComponent,
  ],
  templateUrl: './redemptions.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RedemptionsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Side Pockets"]',
  imports: [DropdownComponent, NumberComponent, ButtonComponent],
  templateUrl: './side-pockets.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePocketsContent extends StaticContentDirective {}

@Component({
  selector: '[uisectioncontent="Subscriptions"]',
  imports: [DropdownComponent, NumberComponent],
  templateUrl: './subscriptions.content.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionsContent extends StaticContentDirective {}

export const ShareclassOpenEndContents = [
  FeesContent,
  RedemptionsContent,
  SidePocketsContent,
  SubscriptionsContent,
];
