@let iv = ivStore.investmentVehicle()!;

@if (iv) {
  <number
    class="c1s1"
    [(value)]="iv.cashInterest"
    [settings]="{ label: 'Cash Interest', placeholder: '%', suffix: ' %' }"
  ></number>

  <text
    class="c1s1"
    [(value)]="iv.contributionRates"
    [settings]="{
      label: 'Contribution Rates',
      placeholder: 'insert multiple figures separated by comma (ex.: 1.1,1.4)',
    }"
  >
  </text>

  <text
    class="c1s1"
    [(value)]="iv.distributionRates"
    [settings]="{
      label: 'Distribution Rates',
      placeholder: 'insert multiple figures separated by comma (ex.: 1.1,1.4)',
    }"
  >
  </text>

  <text
    class="c1s1"
    [(value)]="iv.growthRates"
    [settings]="{
      label: 'Growth Rates',
      placeholder: 'insert multiple figures separated by comma (ex.: 1.1,1.4)',
    }"
  >
  </text>
}
