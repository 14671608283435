@let returns = ivStore.returns();

@if (returns) {
  <dropdown
    class="c1s2"
    [(value)]="returns.selectedSource"
    [settings]="{
      label:
        returns.markedDataPoints?.length! > 1
          ? 'Apply Source to Selection'
          : 'Source',
      tag: 'Public Returns Source',
      options: ENUMS.DataSourceEnum.toOptions,
      optionValue: 'value',
      optionLabel: 'label',
    }"
    (valueChange)="ivStore.setSelectedSource(returns, $event)"
  ></dropdown>
  <dropdown
    class="c3s2"
    [(value)]="returns.selectedFeeType"
    [settings]="{
      label:
        returns.markedDataPoints?.length! > 1
          ? 'Apply Fee Type to Selection'
          : 'Fee Type',
      tag: 'Public Returns Fee Type',
      options: ENUMS.PublicReturnFeeEnum.toOptions,
      optionValue: 'value',
      optionLabel: 'label',
      optionsFiltering: false,
    }"
    (valueChange)="ivStore.setSelectedFeeType(returns, $event)"
  ></dropdown>
  <dropdown
    class="c5s2"
    [(value)]="returns.selectedClassification"
    [settings]="{
      label:
        returns.markedDataPoints?.length! > 1
          ? 'Apply Entity Type to Selection'
          : 'Entity Type',
      tag: 'Public Returns Entity Type',
      options: ENUMS.PublicReturnGeneralClassificationEnum.toOptions,
      optionValue: 'value',
      optionLabel: 'label',
      optionsFiltering: false,
    }"
    (valueChange)="ivStore.setSelectedClassification(returns, $event)"
  ></dropdown>
  <date
    class="c7s2"
    [(value)]="returns.selectedEstimationAsOf"
    [settings]="{
      label: 'Weekly As Of',
      labelTip: 'Fill in this date only for estimate/weekly as of',
      tag: 'Public Returns Weekly As Of',
      placeholder: 'mm/dd/yyyy',
      defaultDate: returns.selectedAsOf,
      minDate: returns.minAsOf,
      maxDate: returns.maxAsOf,
      disabled: returns.markedDataPoints?.length! > 1,
    }"
    (valueChange)="ivStore.setSelectedEstimationAsOf(returns, $event)"
  ></date>
  <div class="c9s1 flex place-content-end gap-4">
    <btn
      class="md secondary"
      [settings]="{
        icon: ICONS.download,
        iconSize: 'large',
        tag: 'Download Returns',
      }"
    ></btn>
    <btn
      class="md secondary"
      [settings]="{
        icon: ICONS.upload,
        iconSize: 'large',
        tag: 'Upload Returns',
      }"
    ></btn>
  </div>

  <spreadsheet
    class="c1s9"
    [settings]="{
      label: 'Returns',
      tag: 'Public Returns',
      idField: '$uid',
      valueField: 'value',
      stateField: '$state',
      deletedField: '$isDeleted',
      cellCssField: '$cellCssClass',
      allowedValues: 'numeric',
      numberFormattingType: 'percent',
      numberFormattingPrecision: '1.0-2',
      tagPrefix: 'publicReturns',
      columnTitles: MONTHS,
      rowTitles: ivStore!.getStreamYears('publicReturns')!,
      summaryTitle: 'Total',
    }"
    [data]="returns.datapoints!"
    [dataSummary]="returns.ytd!"
    [dataValidationGroupId]="returns.$uid!"
    [dataValidators]="ivStore!.publicReturn_validators()"
    (cellSelected)="ivStore.setMarkedStreamPoints(returns, $event)"
    (valueChange)="ivStore.onStreamPointValueChange(returns, $event)"
  >
  </spreadsheet>
}
