
let apiUrl = '';

export function setApiUrl(url: string) {
  apiUrl = url;
}

function getApiUrl() {
  return apiUrl;
}

async function apiRequest(endpoint: string, payload?: any) {
  const controller = 'TextAnalysis';
  const apiUrl = getApiUrl();
  
  try {
    const response = await fetch(`${apiUrl}/${controller}/${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Parse and return the response as JSON
    const data = await response.json();

    return data;
  } catch (error) {
    console.error(`Failed to fetch data from ${endpoint}:`, error);
    return []; // Return an empty array or handle the error as needed
  }
}

export async function fetchTones() {
  return apiRequest('GetTones');
}

export async function fetchSuggestions(request: any, projectId: number) {
  return apiRequest('Rephrase', { request, projectId });
}

export async function summarizeSuggestions(request: any, projectId: number) {
  return apiRequest('Summarize', { request, projectId });
}

export async function sendFeedback(feedbackRequest: any) {
  return apiRequest('Feedback', feedbackRequest);
}

