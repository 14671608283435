@if (permDir.uipermission() !== PERMISSIONS.NONE || permDir.editPermissions()) {
  <!-- Input -->
  <input
    #uiValueRef
    uivalue
    type="text"
    tabindex="0"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="off"
    [title]="formattedValue()"
    [value]="formattedValue()"
    [placeholder]="placeholder()"
    (keyup.enter)="$event.stopImmediatePropagation()"
    (keyup.escape)="focusout()"
    (focusin)="focusin()"
    (focusout)="focusout()"
  />

  <!-- Label -->
  @if (label()) {
    <label
      [attr.uilabelup]="labelUp()"
      [label]="labelFormatted()"
      [labelTip]="labelTip()"
      (click)="focusin()"
    >
    </label>
  }

  <!-- Tools -->
  <div uitoolstrip>
    <ng-content #uiToolsMarkupRef select="[uitool]"></ng-content>
    <ng-template #uiToolsCodeRef></ng-template>
    <ng-container #uiToolsDefaultRef>
      @if (!isNil(value())) {
        <icon
          uihovertool
          uicleartool
          [iconSize]="'normal'"
          [iconDefinition]="ICONS.close"
          (click)="updateValue(undefined)"
        ></icon>
      }
      <icon
        uipopuptrigger
        uidefaulttool
        [class.disabled]="!mapOptions()?.center?.lat"
        [title]="
          mapOptions()?.center?.lat
            ? 'Show on map'
            : 'Latitude and longitude are missing.'
        "
        [iconSize]="'normal'"
        [iconDefinition]="ICONS.location"
        (click)="
          mapOptions()?.center?.lat
            ? uiPopupDir.togglePopup(uiValueRef)
            : undefined
        "
      ></icon>
    </ng-container>
  </div>

  @if (permDir.uipermission() !== PERMISSIONS.VIEW) {
    <!-- Map -->
    <div
      #uiPopup
      [class]="popupCssClass()"
      [UiPopup]="popupSettings()"
      (UiPopupVisibilityChanged)="popupChanged($event)"
    >
      @if (this.mapIsShown && mapOptions()) {
        <google-map [options]="mapOptions()!">
          @if (mapOptions()!.center) {
            <map-marker [position]="mapOptions()!.center!"></map-marker>
          }
        </google-map>
      }
    </div>
  }

  <!-- State -->
  @switch (uiState) {
    @case ('error') {
      <span
        uifooter
        uistate="error"
        class="cursor-help"
        [title]="errors().join('\r\n')"
        >{{
          errors().length > 1
            ? errors().at(0) +
              ' (' +
              (errors().length - 1).toString() +
              ' more)'
            : errors().at(0)
        }}</span
      >
    }
    @case ('warning') {
      <span
        uifooter
        uistate="warning"
        class="cursor-help"
        [title]="warnings().join('\r\n')"
        >{{
          warnings().length > 1
            ? warnings().at(0) +
              ' (' +
              (warnings().length - 1).toString() +
              ' more)'
            : warnings().at(0)
        }}</span
      >
    }
  }
}
